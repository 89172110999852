import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KetFilemanagerComponent } from './filemanager.component';
import { KetVenoboxModule } from '@ketshopweb/ui/venobox';
import { KetResponsiveFileModule } from '@ketshopweb/ui/responsive-file/responsive-file.module';


@NgModule({
  declarations: [
    KetFilemanagerComponent
  ],
  imports: [
    CommonModule,
    KetResponsiveFileModule,
    KetVenoboxModule
  ],
  exports:[
    KetFilemanagerComponent
  ]
})
export class KetFilemanagerModule {
}