import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

let memory_cache: any = {
  fonts_json: null
}

@Injectable({
  providedIn: 'root'
})
export class KetSelectFontService {

  constructor(
    private _http:HttpClient
  ) { }

  cacheCall(_promise: Observable<any>, key1: string){
    return new Promise((resolve, reject)=>{
      if(memory_cache[key1]){
        return resolve(memory_cache[key1])
      }else{
        _promise.toPromise().then(res=>{
          memory_cache[key1] = res;
          resolve(res)
        }).catch(err=>{
          reject(err)
        })
      }
    })

  }

  getFonts() {
    return this.cacheCall(this._http.get('/assets/default/fonts.json'), 'fonts_json');
    // this._http.get("/assets/default/fonts.json").toPromise();
  }

}