import { Component, OnInit, Input } from '@angular/core';
 
@Component({
    selector: 'feature-landing',
    templateUrl: './feature-landing.component.html',
    styleUrls: ['./feature-landing.component.css']
})
export class FeatureLandingComponent implements OnInit {
    // @Input() type:any;
    @Input('type') type: string;
    constructor(){}

    ngOnInit() {
    //   alert(2);
    }
}