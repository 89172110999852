import { ModuleWithProviders, NgModule } from '@angular/core';

import { NzInputNumberModule } from './components/input-number';
import { NzModalModule } from './components/modal';
import { NzMessageModule } from './components/message';
import { NzNotificationModule } from './components/notification';
import { NzRadioModule } from './components/radio';
import { NzButtonModule } from './components/button';
import { NzTableModule } from './components/table';
import { NzTabsModule } from './components/tabs';
import { NzLayoutModule } from './components/layout';
import { NzPaginationModule } from './components/pagination';
import { NzInputModule } from './components/input';
import { NzSelectModule } from './components/select';
import { NzSwitchModule } from './components/switch';
import { NzSliderModule } from './components/slider';
import { NzFormModule } from './components/form';
import { NzListModule } from './components/list';
import { NzGridModule } from './components/grid';
import { NzPopoverModule } from './components/popover';
import { NzCheckboxModule } from './components/checkbox';
import { NzDropDownModule } from './components/dropdown';
import { NzI18nModule } from './components/i18n';
import { NzCardModule } from './components/card';
import { NzSkeletonModule } from './components/skeleton';
import { NzTagModule } from './components/tag';
import { NzDatePickerModule } from './components/date-picker';
import { NzTimePickerModule } from './components/time-picker';
import { NzPopconfirmModule } from './components/popconfirm';
import { NzDrawerModule } from './components/drawer';
import { NzDividerModule } from './components/divider';
import { NzToolTipModule } from './components/tooltip';
import { NzAvatarModule } from './components/avatar';
import { NzTreeSelectModule } from './components/tree-select';
import { NzMenuModule } from './components/menu';
import { NzAutocompleteModule } from './components/auto-complete';
import { NzEmptyModule } from './components/empty';
import { NzStepsModule } from './components/steps';
import { NzBadgeModule } from './components/badge';
import { NzProgressModule } from './components/progress';
import { NzBreadCrumbModule } from './components/breadcrumb';
import { NzAlertModule } from './components/alert';
import { NzIconModule } from './components/icon';
import { NzUploadModule } from './components/upload';
import { NzSpinModule } from 'ng-zorro-antd/spin';

/*
export * from './components/input-number';
export * from './components/modal';
export * from './components/message';
export * from './components/notification';
export * from './components/radio';
export * from './components/button';
export * from './components/table';
export * from './components/tabs';
export * from './components/layout';
export * from './components/pagination';
export * from './components/input';
export * from './components/select';
export * from './components/switch';
export * from './components/slider';
export * from './components/form';
export * from './components/list';
export * from './components/grid';
export * from './components/popover';
export * from './components/checkbox';
export * from './components/dropdown';
export * from './components/i18n';
export * from './components/card';
export * from './components/skeleton';
export * from './components/tag';
export * from './components/date-picker';
export * from './components/time-picker';
export * from './components/popconfirm';
export * from './components/drawer';
export * from './components/divider';
export * from './components/tooltip';
export * from './components/avatar';
export * from './components/tree-select';
export * from './components/menu';
export * from './components/auto-complete';
export * from './components/empty';
export * from './components/steps';
export * from './components/badge';
export * from './components/progress';
export * from './components/breadcrumb';
export * from './components/alert';
export * from './components/icon';


export * from './components/core/wave';
export * from './components/core/util';

*/

@NgModule({
  exports: [
    NzInputNumberModule,
    NzModalModule,
    NzMessageModule,
    NzNotificationModule,
    NzRadioModule,
    NzButtonModule,
    NzTableModule,
    NzTabsModule,
    NzLayoutModule,
    NzPaginationModule,
    NzInputModule,
    NzSelectModule,
    NzSwitchModule,
    NzSliderModule,
    NzFormModule,
    NzListModule,
    NzGridModule,
    NzPopoverModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzSpinModule,
    NzI18nModule,
    NzCardModule,
    NzSkeletonModule,
    NzTagModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzPopconfirmModule,
    NzDrawerModule,
    NzDividerModule,
    NzToolTipModule,
    NzAvatarModule,
    NzTreeSelectModule,
    NzMenuModule,
    NzAutocompleteModule,
    NzEmptyModule,
    NzStepsModule,
    NzBadgeModule,
    NzProgressModule,
    NzBreadCrumbModule,
    NzAlertModule,
    NzIconModule,
    NzUploadModule
  ]
})
export class NgZorroAntdModule {
  /**
   * @deprecated Use `NgZorroAntdModule` instead.
   */
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: NgZorroAntdModule
    };
  }
}
