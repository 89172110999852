import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { OrderService } from '@cms/services/order.service';
import { Router } from '@angular/router';
import { pathLangPipe } from '@cms/pipes/path-lang.pipe';

@Component({
  selector: 'app-modal-credit',
  templateUrl: './modal-credit.component.html',
  styleUrls: ['./modal-credit.component.css']
})
export class ModalCreditComponent implements OnInit {
  @Input() data: any;
  page_name:string = 'confirm-order';
  message_duration: number = 4000;
  pending_request = false;
  constructor(
    private message: NzMessageService,
    private modal: NzModalRef,
    private modalservice: NzModalService,
    private Service_Order: OrderService,
    private router: Router,
    private path_lang: pathLangPipe
  ) { }

  model:any = {
    name: "",
    number: "",
    // expirationMonth: "",
    // expirationYear: "",
    expire: "",
    securityCode: ""
  }

  ngOnInit() {
    
  }

  creditCardChange(val){
    // console.log(val);
    // this.model.number = this.model.number.toString();
    // this.model.number = this.model.number.replace(/\D+/g, "");
    // console.log(this.model.number);
    // .replace(/(.{4})/g, '$1 ');
  }

  expirationKeyUp(event){
    // console.log(event);
    var inputChar = String.fromCharCode(event.keyCode);
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    this.model.expire = this.model.expire.replace(
      /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
    ).replace(
      /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
    ).replace(
      /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
    ).replace(
      /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
    ).replace(
      /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
    ).replace(
      /\/\//g, '/' // Prevent entering more than 1 `/`
    );
    // console.log(this.model.expire);
  }

  ok(){
    // console.log(this.model);
    if (this.pending_request) { this.message.warning("On Processing"); return false;}
    this.model.number = this.model.number.replace(/\D+/g, "");

    let expire_arr = this.model.expire.split('/');
    // console.log(this.model);return;
    if(expire_arr.length != 2){
      this.message.error("Invalid Expiration")
      return false;
    }
    let obj = {
      model: this.data.model,
      card: {
        name: this.model.name,
        number: this.model.number,
        expirationMonth: expire_arr[0],
        expirationYear: expire_arr[1],
        securityCode: this.model.securityCode
      },
      redirect:{
        href: decodeURI(location.href),
        from: 'order'
      }

    }


    console.log(obj);
    $('#_order_preloader').fadeIn()
    this.pending_request = true;
    this.Service_Order.createOrderCredit(obj).then((res:any)=>{
      //console.log(res);
      if(res.redirect){
        $('#credit_redirect').html(res.html);

        // $(res.html).appendTo('#credit_redirect').submit()
        // const _modal = this.modalservice.create({
        //   nzTitle: "ยืนยันการชำระเงิน",
        //   nzContent: ModalCreditRedirectComponent,
        //   nzStyle: { top: '20px' },
        //   nzWidth: "90%",
        //   nzClassName : 'modal-small',
        //   nzZIndex: 9999999999999999999,
        //   nzKeyboard: false,
        //   nzMaskClosable: false,
        //   nzClosable: true,
        //   nzFooter: null,
        //   nzComponentParams: {
        //     data: {
        //       html: res.html,
        //       preload: '#_order_preloader'
        //     }
        //   }
        // });
        // _modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
        // _modal.afterClose.subscribe((result) => {
        //   console.log(result);
        //   this.pending_request = false;
        //   if (result) {

        //   }
        // });


        // var _checkTime = setInterval(()=>{
        //   this.Service_Order.check_order_credit(res.providerRefNo).then((resp:any) =>{

        //     if(resp.status){
        //       clearInterval(_checkTime);
        //       _modal.close();
        //       this.modal.close(resp);
        //     }

        //   }).catch((err)=>{
        //     $('#_order_preloader').fadeOut()
        //     this.pending_request = false;
        //     // this.message.error(err.error.message);
        //     this.modalservice.error({
        //       nzTitle: 'Error',
        //       nzContent: err.error.message
        //     });
        //     _modal.close();
        //     clearInterval(_checkTime)
        //   })
        // },3000)


      }else{
        //console.log('else', res)
        this.modal.close(res);
      }


    }).catch((data)=>{
      $('#_order_preloader').fadeOut()
      this.pending_request = false;
      this.message.error(data.error.message);
    });
    return false;
  }

}
