import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { cloneDeep } from 'lodash';
import { ExtensionService } from '@cms/services/extension.service';

declare var alasql: any

@Component({
    selector: 'app-modal-get-shopee-order',
    templateUrl: './modal-get-shopee-order.component.html',
    styleUrls: ['./modal-get-shopee-order.component.css']
})
export class ModalGetShopeeOrderComponent implements OnInit {
    @Input() data: any;

    constructor(
        private Service_Extension: ExtensionService,
        private modalService: NzModalService,
        private modalRef: NzModalRef
    ) { }

    validateOrderId: boolean = false

    model: any = {
        ordersn: ""
    }
    ngOnInit() {

    }

    ok() {

        console.log(this.model.ordersn);
        if (!this.model.ordersn) {
            this.validateOrderId = true
            return false;
        }
        $('.theme-loader').fadeIn();
        this.Service_Extension.syncShopeeOrderByOrdersn(this.model).then((res: any) => {
            $('.theme-loader').fadeOut();
            this.modalService.success({
                nzTitle: 'อยู่ระหว่างดำเนินการดึงข้อมูล จาก Shopee',
                nzContent: 'ขณะนี้ระบบกำลังดำเนินการดึงข้อมูล จาก Shopee ซึ่งจะทำการดึงข้อมูลสำเร็จภายใน 5 นาที',
                nzOkText: 'ตกลง',
                nzWrapClassName: 'vertical-center-modal',
            });
            this.modalRef.close(true);
        }).catch((data) => {
            $('.theme-loader').fadeOut();
            this.modalService.error({
                nzTitle: data.error.message
            });
        });



        return false;
    }

    close() {
        this.modalRef.close(true);
    }
}