import { 
  ElementRef, 
  Component, 
  OnInit, 
  ViewChild, 
  Input, 
  Output, 
  EventEmitter, 
  SimpleChanges, 
  OnChanges 
} from '@angular/core';

@Component({
  selector: 'filemanager',
  templateUrl: './filemanager.component.html',
  styleUrls: ['./filemanager.component.less']
})
export class KetFilemanagerComponent implements OnInit, OnChanges {
  
  @ViewChild('media') media: ElementRef;
  @Input() ID: any;
  @Input() _template: any;
  @Input() json: boolean = false;
  @Input() multiple:number = 0;
  @Input() checkAll:number = 0;
  @Output() image = new EventEmitter();
  _akey:any;
  constructor(private el: ElementRef) {
    // console.log('CRONSTRUCTOR mediamanager')

  }

  async ngOnInit() {
    var __akey = null;
    try {
      let _token = JSON.parse(localStorage.getItem('access_token'));
      __akey = _token.access_token;
    } catch (e) {

    }
    this._akey = __akey;
  }

  select_image(e: any) {
    console.log(e);
      if(this.json || this.multiple == 1){
          this.image.emit(e);
      }else{
          this.image.emit(e.path);
      }
  }


  ngOnChanges(changes: SimpleChanges){
    if(changes.ID){
      if(changes.ID.previousValue !== undefined){
        this.ID = changes.ID.currentValue;
      }
    }
  }

}
