export default {
  // Options.jsx
  items_per_page: '/ páginas',
  jump_to: 'Vá até',
  jump_to_confirm: 'confirme',
  page: '',

  // Pagination.jsx
  prev_page: 'Página anterior',
  next_page: 'Próxima página',
  prev_5: '5 páginas anteriores',
  next_5: '5 próximas páginas',
  prev_3: '3 páginas anteriores',
  next_3: '3 próximas páginas',
};
