import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class KetTranslateService {

  _subscribe: Subscription = null

  constructor(
    private _http:HttpClient,
    private store: Store<any>
  ) {
    if(!this._subscribe){
      this.store.pipe(select('app_store', 'LANG_FORMAT')).subscribe((res: any[]) => {
        // console.log(res);
           this.data = res;
      });
    }
  }

  data:any = [];

  translate(text,from,type = 'text'){
    // console.log('=============    translate ===================');
    // let lang = localStorage.getItem('lang') || 'lang1';
    let lang = this.getCurrentLang();
    let result = this.data.find(x=> `${x.default}`.trim() == `${text}`.trim() && x.from == from );
    //console.log(text, lang, result)
    if(result == "undefined"){
      result = undefined
    }
    if (!result){
        let obj = {
            type: type,
            from: from || 'other',
            text: text
        }
        this._http.post('/util/langFormat', obj).toPromise().then((resp:any)=>{

        }).catch((err:any)=>{

        });

        return text;
    }
   // console.log(result[lang])
    return result[lang];
  }

  isBackEnd(){
    let search = location.href.search(`${window.location.origin}/system`);
    // console.log("isbackend", search);
    if(search == -1){
        return false;
    }else{
        return true;
    }
  }

  getCurrentLang(){
    let backend = this.isBackEnd();
    let lang = "lang1";

    if(backend){
        lang = localStorage.getItem('lang_backend');
        if(!lang){
            localStorage.setItem('lang_backend','lang1');
        }
        lang = localStorage.getItem('lang_backend');
    }else{
        lang = localStorage.getItem('lang');
        if(!lang){
            lang = 'lang1';
        }
    }
    return lang;
  }

  

}