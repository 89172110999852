import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CustomerService } from '@cms/services/customer.service';
import { UtilService } from '@cms/services/util.service';
import { SettingService } from '@cms/services/setting.service';

@Component({
  selector: 'app-modal-add-edit-sending-address',
  templateUrl: './modal-add-edit-sending-address.component.html',
  styleUrls: ['./modal-add-edit-sending-address.component.css']
})
export class ModalAddEditSendingAddressComponent implements OnInit {
  @Input() data:any;
  page_name: string = "member-profile";
  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Customer: CustomerService,
    private Service_Util: UtilService,
    private settingService: SettingService
  ) { }

  model:any = {
    name: "",
    address1: "",
    subdistrict: "",
    district: "",
    province: "",
    zipcode: "",
    tel: ""
  }
  province:any[] = [];
  tambon_list:any[] = [];
  amphur_list:any[] = [];
  message_duration:number = 4000;
  address_layout = 1

  ngOnInit() {
    this.settingService.searchOption({
      pop_key: ['address_layout']
    }).then((res: any[]) => {
      for(let obj of res){
        if(obj.pop_key == 'address_layout') {
          if(!obj.pop_value) obj.pop_value = '1'
          this.address_layout = parseInt(obj.pop_value)
        }
      }
    }).catch(err => console.error(err))
    this.Service_Util.getProvince().then((res: any[]) => {
      this.province = res
    });
    if(this.data.state == 'edit'){
      this.model.name = this.data.model.name;
      this.model.address1 = this.data.model.address1;
      this.model.subdistrict = this.data.model.subdistrict;
      this.model.district = this.data.model.district;
      this.model.province = this.data.model.province;
      this.model.zipcode = this.data.model.zipcode;
      this.model.tel = this.data.model.tel;
      this.model.id = this.data.model.id;
    }
    if (this.data.model && this.data.model.user_id) {
      this.model.user_id = this.data.model.user_id;
    }
    this.searchTambon(this.model.subdistrict);
    this.searchAmphur(this.model.district);
  }
  searchTambon(event) {
    event = event || "";
    let obj = {
      "name": event
    }
    this.Service_Util.getSubDistrict(obj).then((res: any) => {
      this.tambon_list = res;
      // console.log(this.tambon_list);
    }).catch(data => {
      console.log(data.error.message);
    });
  }
  searchAmphur(event) {
    event = event || "";
    let obj = {
      "name": event
    }
    this.Service_Util.getDistrict(obj).then((res: any) => {
      this.amphur_list = res;
      // console.log(this.amphur_list);
    }).catch(data => {
      console.log(data.error.message);
    });
  }


  phoneNumber(phone: string) {
    var phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    var digits = phone.replace(/\D/g, "");
    if (phoneRe.test(digits)) {
      return digits;
    } else {
      return false;
    }
  }

  
  async ok(){

    if (this.model.name == null || this.model.name == undefined || this.model.name == "") {
      this.message.error("โปรดกรอกชื่อ-นามสกุล", { nzDuration: this.message_duration });
      return false;
    }

    if(!this.phoneNumber(this.model.tel)){
      this.message.error("โปรดกรอกเบอร์โทรศัพท์", { nzDuration: this.message_duration });
      return false;
    }
    
    this.model.tel = this.phoneNumber(this.model.tel)

    if (this.model.address1 == null || this.model.address1 == undefined || this.model.address1 == "") {
      this.message.error("โปรดกรอกที่อยู่", { nzDuration: this.message_duration });
      return false;
    }
    
    if (this.model.subdistrict == null || this.model.subdistrict == undefined || this.model.subdistrict == "") {
      this.message.error("โปรดกรอกตำบล/แขวง", { nzDuration: this.message_duration });
      return false;
    }

    if (this.model.district == null || this.model.district == undefined || this.model.district == "") {
      this.message.error("โปรดกรอกอำเภอ/เขต", { nzDuration: this.message_duration });
      return false;
    }

    if (this.model.province == null || this.model.province == undefined || this.model.province == "") {
      this.message.error("โปรดกรอกจังหวัด", { nzDuration: this.message_duration });
      return false;
    }
    
    if (this.model.zipcode == null || this.model.zipcode == undefined || this.model.zipcode == "") {
      this.message.error("โปรดกรอกรหัสไปรษณีย์", { nzDuration: this.message_duration });
      return false;
    }

    if(this.data.state == 'create'){
      try{
        await this.Service_Customer.createSendingAddress(this.model);
        try{
          if(this.data.customer._newaddress){
            var _model = {...this.model}
            var full_name = _model.name

            var last_name = `${full_name}`.trim().split(' ')
            if(last_name.length > 2){
              for (var i = last_name.length - 1; i >= 0; i--) {
                if(last_name[i] == ""){
                  last_name.splice(Number(i),1)
                }
              }
            }

            _model['name'] = last_name[0]
            _model['lastname'] = last_name[1] || "-"

            await this.Service_Customer.updateProfile(_model);
          }
        }catch(err){}
        this.modal.close(true);
      }catch(e){
        this.message.error(e.error.message, { nzDuration: this.message_duration });
      }
    }else {
      try {
        await this.Service_Customer.updateSendingAddress(this.model);
        this.modal.close(true);
      } catch (e) {
        this.message.error(e.error.message, { nzDuration: this.message_duration });
      }
    }
  }
  close(){
    this.modal.close(true);
  }
  addressChange(data: any) {

    let subdistrict = (data) ? data.district : "";
    let district = (data) ? data.city : "";
    let zipcode = (data) ? data.zipcode : "";
    let province = (data) ? data.province : "";

    this.model.province = province;
    this.model.district = district;
    this.model.subdistrict = subdistrict;
    this.model.zipcode = zipcode;

    this.searchAmphur(district)
    this.searchTambon(subdistrict)    
  }
}
