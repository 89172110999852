import { Component, OnInit, Input, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { OrderService } from '@cms/services/order.service';
import { Etc } from '@cms/util/etc';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UtilService } from '@cms/services/util.service';
import { ModalExportOrderComponent } from '@cms/system/modal/modal-export-order/modal-export-order.component';
import { PermissionDirective } from '@cms/dirctives/permission.directive';
import { FeatureService } from '@cms/services/feature.service';
import { ExtensionService } from '@cms/services/extension.service';
import { SendToPeakComponent } from '../send-to-peak/send-to-peak.component';
import { PeakComponent } from '@cms/system/extension/peak/peak.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { apiError } from '@cms/interface/interface';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { OpenOrderLinkComponent } from '../open-order-link/open-order-link.component';
import * as moment from 'moment';

declare var navigator: any

@Component({
  selector: 'app-order-history-messenger',
  templateUrl: './order-history-messenger.component.html',
  styleUrls: ['./order-history-messenger.component.css']
})
export class OrderHistoryMessengerComponent implements OnInit, AfterViewInit {

  @Input() user?:any
  @Input() user_id?:any = ""
  @Input() peak_account: boolean = false
  @Input() from?:any = ""

  _permission: PermissionDirective = new PermissionDirective()
  currentdatetime:any;
  rangePicker: any = [];
  allcreateby: any = [];
  loading:boolean = true;
  shippopimg:any = {};
  order:any = [];

  listYear: any[] = []
  listMonth: any[] = [
    {
      value: "01",
      name: "มกราคม"
    },
    {
      value: "02",
      name: "กุมภาพันธ์"
    },
    {
      value: "03",
      name: "มีนาคม"
    },
    {
      value: "04",
      name: "เมษายน"
    },
    {
      value: "05",
      name: "พฤษภาคม"
    },
    {
      value: "06",
      name: "มิถุนายน"
    },
    {
      value: "07",
      name: "กรกฎาคม"
    },
    {
      value: "08",
      name: "สิงหาคม"
    },
    {
      value: "09",
      name: "กันยายน"
    },
    {
      value: "10",
      name: "ตุลาคม"
    },
    {
      value: "11",
      name: "พฤศจิกายน"
    },
    {
      value: "12",
      name: "ธันวาคม"
    }    
  ]

  total:any = 0;
  statusimg:any;
  paymentimg:any;
  usernameSearch: string = '';
  modelsearch:any = {
    startdate: "",
    enddate: "",
    status: "",
    ordercode: "",
    email: "",
    trackcode: "",
    search: "",
    paymenttype: "",
    created_by: "",
    "page": 1,
    "perpage": 10,
    "facebook_id": "",
    "user_id": ""
  }
  modelsearch_report: any = {
    startdate: "",
    enddate: "",
    ordercode: ""
  }
  currenttab:any = 0;
  order_report:any = {}

  heightTable: number = 550

  search_by_user_state: boolean = true
  feature:boolean = false;
  _peak:any = {}


  // Order Link
  social_list:any = [];
  order_link:any=[];
  order_link_total:any;
  order_link_modelsearch:any = {
    startdate: "",
    enddate: "",
    status: "",
    ordercode: "",
    trackcode: "",
    email: "",
    search: "",
    paymenttype: "",
    created_by: "",
    page: 1,
    perpage: 10,
    facebook_id: ""
  }
  social:any = {}
  feature2:boolean = false;
  loading_orderlink:boolean = true;

  // PEAK_Account
  select_year = moment().endOf("month").format("YYYY")
  select_month = moment().endOf("month").format("MM")

  constructor(
    private drawerRef: NzDrawerRef<string>,
    private drawerService: NzDrawerService,
    private Service_Util: UtilService,
    private Service_Order: OrderService,
    private modal: NzModalService,
    private _etc: Etc,
    private chRef: ChangeDetectorRef,
    private Service_Feature: FeatureService,
    private _extenion: ExtensionService,
    private message: NzMessageService,
    private _noti: NzNotificationService,
  ) {

  }

  async ngOnInit() {
    this.listYear = [
      {
        year: moment().endOf("month").format("YYYY")
      },
      {
        year: moment(moment().add(-1,'years').format('YYYY')).endOf("month").format("YYYY")
      },
      {
        year: moment(moment().add(-2,'years').format('YYYY')).endOf("month").format("YYYY")
      },
      {
        year: moment(moment().add(-3,'years').format('YYYY')).endOf("month").format("YYYY")
      },
      {
        year: moment(moment().add(-4,'years').format('YYYY')).endOf("month").format("YYYY")
      }
    ]

    this.statusimg = this._etc.orderstatus_img();
    this.paymentimg = this._etc.payment_img();
    //console.log(this.user)
    this.feature = await this.Service_Feature.getFeature("PRODUCT_PAGE");
    if (!this.feature) {
      return;
    }

    try{
      if(this.user.info.id){
        this.search_by_user_state = false
        this.modelsearch.facebook_id = this.user.info.id
      }
    }catch(err){
      this.search_by_user_state = true
    }

    try{
      if(this.user_id){
        this.search_by_user_state = false
        this.modelsearch.user_id = this.user_id
      }
    }catch(err){
      this.search_by_user_state = true
    }

    this.currentdatetime = await this.Service_Util.getDateTime();
    this.allcreateby = await this.Service_Order.getcreatedby();
    this.shippopimg = this.Service_Util.getShippopImage();

    try{
      if(this.peak_account){
        this.peak_account = true
        this._peak = await this._extenion.search({ name: 'Peak' });
        if(this._peak.connected == 0){


          const drawerRef = this.drawerService.create<PeakComponent, any>({
            nzTitle: 'ตั้งค่า PEAK Account',
            nzWidth: '70%',
            nzContent: PeakComponent,
            nzContentParams: {

            }
          }).afterClose.subscribe( async (data) => {
            drawerRef.unsubscribe()
            console.log('Drawer data', data);
            this._peak = await this._extenion.search({ name: 'Peak' });
            if(this._peak.connected == 0){
              this.close()
            }else{
              this.ngOnInit();
            }
          });

          return;
        }
        console.log(this._peak)
        var type = "quotations"
        if(this._peak.option == "2"){
          type = "receipts"
        }

        this.modelsearch = {
          startdate: "",
          enddate: "",
          status: "",
          ordercode: "",
          email: "",
          trackcode: "",
          search: "",
          paymenttype: "",
          created_by: "",
          page: 1,
          perpage: 10,
          facebook_id: "",
          user_id: "",
          peak: true,
          peak_status: "5",
          peak_type: type
        }


        this.checkPeakStatus()

      }
    }catch(err){
      console.log(err)
    }


    if(this.peak_account){
      this.modelsearch.startdate = `${this.select_year}-${this.select_month}-01 00:00:00`
      let last_day = moment(`${this.select_year}-${this.select_month}-01`).endOf("month").format("DD")
      this.modelsearch.enddate = `${this.select_year}-${this.select_month}-${last_day} 23:59:59`
    }

    this.search()

    window['_refreshordersearch'] = ()=>{
      //console.log('_refreshordersearch')
      this.search();
    }

    if(this.from == "chat"){
      this.initOrderlink()
    }

  }

  onChangeYearMonth(){
    this.modelsearch.startdate = `${this.select_year}-${this.select_month}-01 00:00:00`
    let last_day = moment(`${this.select_year}-${this.select_month}-01`).endOf("month").format("DD")
    this.modelsearch.enddate = `${this.select_year}-${this.select_month}-${last_day} 23:59:59`
    this.search()
  }

  async initOrderlink(){
    this.feature2 = await this.Service_Feature.getFeature("ORDERLINK");
    if(!this.feature2){
      return
    }
    this.social_list = await this.Service_Order.orderLinkSocial();
    this.searchOrderLink();

    this.social[''] = ''

    for(let obj of this.social_list){
        //console.log(obj)
        this.social[obj.value] = obj.img
    }

  }

  editOderLink(obj:any) {
    const drawerRef = this.drawerService.create<OpenOrderLinkComponent, any>({
      // nzTitle: 'แก้ไข Order Link',
      nzWidth: '96%',
      nzClosable: false,
      nzContent: OpenOrderLinkComponent,
      nzContentParams: {
       button: 'ยืนยัน การแก้ไข',
       state: 'edit',
       value:{
        id: obj.id
       }
      }
    });

    // drawerRef.afterOpen.subscribe(() => {
    //   console.log('Drawer(Component) open');
    // });

    var _s = drawerRef.afterClose.subscribe(data => {
      this.search();
      _s.unsubscribe()
    });

  }

  searchOrderLink(){
    this.loading_orderlink = true;
    if(this.from = "chat"){
      this.order_link_modelsearch.facebook_id = this.user.info.id
    }
    this.Service_Order.searchOrderLink(this.order_link_modelsearch).then((res: any) => {
      //console.log(res);
      this.order_link = res.data;
      this.order_link_total = res.count;
    }).catch((data) => {
      //console.log(data.error.message);
    }).finally(() => {
        this.loading_orderlink = false;
    });
  }

  pagechageLink(page) {
    this.order_link_modelsearch.page = page;
    this.searchOrderLink();
  }


  deleteOrderLink(obj:any){

    this.modal.confirm({
      nzTitle: `Are you sure to delete ${obj.permalink} ?`,
      nzContent: '',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.Service_Order.deleteOrderLink(obj).then((res:any)=>{
          this._noti.create('success',  'delete success','');
          this.searchOrderLink();
        }).catch((err:apiError)=>{

        })
      },
      nzCancelText: 'No',
      nzOnCancel: () => { }
    });

  }


  copy(t:any) {
    try {
        var text = `${window.location.origin}/orderlink/${t}`
        navigator.clipboard.writeText(text).then((res: any) => {
            this.message.success("copy success")
        });
    } catch (err) {}
  }


  @HostListener('window:resize', ['$event'])
  onWindowResize($event:any) {
      this.heightTable = this.tableHeight($event.target.innerHeight)
  }

  ngAfterViewInit(){
     setTimeout(()=>{
          this.heightTable = this.tableHeight(window.innerHeight)

     },150)
  }

  tableHeight(height:number){
      if(this.peak_account){
        return (height-210)
      }else{
        height = (height+80)
        return (height-346)
      }
  }

  close(){
    this.drawerRef.close();
  }


  checkPeakStatus(){
    var _update = false
    var _setInterval = setInterval(()=>{
      this._extenion.checkPendingPeak().then((res:any) =>{
        console.log(res)
        if(res.update){
          _update = true
          if(res.reload){
            this.search()
          }
        }else{
          clearInterval(_setInterval)
          if(_update){
            this.search()
          }
        }
      }).catch(err =>{
        console.log(err)
        clearInterval(_setInterval)
        this.search()
      })
    },5000)
  }


  async checkInuseActivity(obj){
    // console.log(obj);
    let rs = await this.Service_Util.inuseActivity({ type: "order", param_id: obj.id });
    return rs;
  }

  async viewOrder(obj:any){
    console.log(obj)
    if (!this._permission.checkPermission(['administrator','webmaster','staff','sale','store'])){
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `Permission denied`
      });
      return;
    }
    let inuse: any = await this.checkInuseActivity(obj);
    if (!inuse.available) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `${inuse.user} กำลังทำการแก้ไขรายการนี้อยู่`
      });
      return;
    }

    let url = `${window.location.origin}/system/e-commerce/order-detail/${obj.ordercode}`;
    window.open(url, '_blank', "menubar=no,toolbar=no,location=no,width=1280,height=720");

  }

  async edit(obj){

    if(!this._permission.checkPermission(['administrator','webmaster','staff','sale','store'])){
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `Permission denied`
      });
      return;
    }
    let inuse: any = await this.checkInuseActivity(obj);
    if (!inuse.available) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `${inuse.user} กำลังทำการแก้ไขรายการนี้อยู่`
      });
      return;
    }

    let url =  '/system/e-commerce/order-detail/' + obj.ordercode;
    window.open(url, '_blank', "menubar=no,toolbar=no,location=no,width=1280,height=720");

  }

  pagechage(page){
    this.modelsearch.page = page;
    this.search();
  }

  searchreport(){
    this.Service_Order.orderreportstatus(this.modelsearch_report).then((res:any)=>{
      this.order_report = res
      console.log(res);
    }).catch((data) => {
      console.log(data.error.message);
    });
  }

  search(){
    this.loading = true;
    this.Service_Order.search(this.modelsearch).then((res:any)=>{
      console.log(res);
      this.order = res.data;
      this.total = res.count;
      this.chRef.detectChanges();
    }).catch((data)=>{
      console.log(data.error.message);
    }).finally(()=>{
      this.loading = false;
      this.chRef.detectChanges();
    });
    // console.log(this.rangePicker);
  }

  tapPeakChange(e:any){
   // console.log(e)

    this.modelsearch.peak_status = "5"

    if(e.index == 0){
      this.modelsearch.peak_type = "quotations"
    }
    if(e.index == 1){
      this.modelsearch.peak_type = "invoices"
    }
    if(e.index == 2){
      this.modelsearch.peak_type = "receipts"
    }


    this.search()

  }

  resendToPeak(orderid:any){
    if(orderid){
      this.loading = true


      if(this.modelsearch.peak_type == "quotations"){
        this._extenion.createQuotationByOrders({
          ids: [orderid]
        }).then(res =>{
          this.search()
        }).catch(err =>{
          console.log(err)
          this.search()
        })
      }


      if(this.modelsearch.peak_type == "invoices"){
        this._extenion.createReceiptsByOrder({
          ids: [orderid]
        }).then(res =>{
          this.search()
        }).catch(err =>{
          console.log(err)
          this.search()
        })
      }

      if(this.modelsearch.peak_type == "receipts"){
        this._extenion.createReceiptsByOrder({
          ids: [orderid]
        }).then(res =>{
          this.search()
        }).catch(err =>{
          console.log(err)
          this.search()
        })
      }

    }
  }


  createReFromOrder() {

    const drawerRef = this.drawerService.create<SendToPeakComponent, any>({
      // nzTitle: `สร้างใบเสร็จ จาก ออเดอร์`,
      nzWidth: '90%',
      nzClosable: false,
      nzContent: SendToPeakComponent,
      nzContentParams: {
        peak_account: true
      }
    }).afterClose.subscribe(data => {
      drawerRef.unsubscribe()
      console.log('Drawer data', data);
      this.search()
      this.checkPeakStatus()
    });

  }

  selectCustomer(obj: any){
    console.log(obj)

    this.usernameSearch = `${ obj.name} ${obj.lastname}`
    this.modelsearch.search = `${obj.username}`
    this.search();
  }

  onSearchChange(e: any){
    this.usernameSearch = `${e}`

    if(`${e}` == ""){
        this.modelsearch.search = ""
        this.search();
    }
  }

  export() {
    const modal = this.modal.create({
      nzTitle: 'Export',
      nzContent: ModalExportOrderComponent,
      nzStyle: { top: '20px' },
      // nzWidth: "750px",
      nzKeyboard: false,
      nzMaskClosable: false,
      nzOnOk: (componentInstance) => {
        return componentInstance.ok();
      },
      nzOnCancel: (componentInstance) => {

      },
      nzComponentParams: {
        data: {

        }
      }
    });
  }


}
