import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingThemeRoutingModule } from './setting-theme-routing.module';
import { SettingThemeComponent } from './setting-theme.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';

@NgModule({
  declarations: [
    SettingThemeComponent
  ],
  imports: [
    CommonModule,
    SettingThemeRoutingModule,
    NzGridModule,
    NzButtonModule,
    NzModalModule,
    NzMessageModule
  ]
})
export class SettingThemeModule { }
