import { Component, OnInit,Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { CustomerService } from '@cms/services/customer.service';
@Component({
  selector: 'app-modal-edit-profile',
  templateUrl: './modal-edit-profile.component.html',
  styleUrls: ['./modal-edit-profile.component.css']
})
export class ModalEditProfileComponent implements OnInit {
  @Input() data:any;

  page_name: string = "member-profile";
  constructor(
    private message: NzMessageService,
    private modal: NzModalRef,
    private Service_Customer: CustomerService
  ) { }

  message_duration:number = 4000;
  model:any={
    name: "",
    lastname: "",
    tel: "",
    password: "",
    confirmpassword: ""
  }
  ngOnInit() {
    this.model.name = this.data.dataProfile.name;
    this.model.lastname = this.data.dataProfile.lastname;
    this.model.tel = this.data.dataProfile.tel;
  }

  async ok(){
    // console.log(this.model);
    if(this.model.name.trim() == ""){
      this.message.error("โปรดกรอกชื่อ", { nzDuration: this.message_duration });
      return false;
    }
    if(this.model.password.trim() != ""){
      if (`${this.model.password}`.length < 4) {
        this.message.error("รหัสผ่านต้องมีจำนวนไม่ต่ำกว่า 4 ตัวอักษร และต้องเป็นตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น", { nzDuration: this.message_duration });
        return false;
      } else if (this.model.password != this.model.confirmpassword) {
        this.message.error("Password does not match.", { nzDuration: this.message_duration });
        return false;
      }
    }
    try{
      await this.Service_Customer.updateProfile(this.model);
      this.modal.close(true);
    }catch(e){
      this.message.error(e.error.message, { nzDuration: this.message_duration });
      return false;
    }
  }

  close(){
      this.modal.close(true);
  }

}
