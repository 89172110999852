import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-view-slip',
  templateUrl: './modal-view-slip.component.html',
  styleUrls: ['./modal-view-slip.component.css']
})
export class ModalViewSlipComponent implements OnInit {
  @Input() data: any;
  constructor(
    private modal: NzModalRef
  ) { }

  ngOnInit() {
  }
  cancel(){
    this.modal.close();
  }
  ok() { 

  }
}
