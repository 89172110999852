import { Component, Input, OnInit } from '@angular/core';
import { KetFeatureLandingService } from './feature-landing.service';
@Component({
  selector: 'ket-feature-landing',
  templateUrl: './feature-landing.component.html',
  styleUrls: ['./feature-landing.component.less']
})
export class KetFeatureLandingComponent implements OnInit {

  constructor(
    private ketFeatureLandingService: KetFeatureLandingService
  ) { }

  @Input('ketType') type: string;
  @Input('ketSrc') src: string = null;
  @Input('ketKey') key: string | string[] = null;
  
  feature: boolean = true;


  async ngOnInit(){
    if(Array.isArray(this.key)){
      for(let key of this.key){
        let feature = await this.ketFeatureLandingService.getFeature(key)
        console.log('key',key,feature);
          if(!feature){
            this.feature = feature
            break
          }else{
            this.feature = feature
          }
      }
    }else{
      this.feature = await this.ketFeatureLandingService.getFeature(this.key);
    }
    if (!this.feature) {
      return;
    }
  }
}