import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '@cms/services/util.service';
import { DashboardService } from '@cms/services/dashboard.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { TeamService } from '@cms/services/team.service';
import { ProductsService } from '@cms/services/products.service';
import { OrderService } from '@cms/services/order.service';
import { Etc } from '@cms/util/etc';
import { environment } from 'src/environments/environment';
import { AuthenService } from '@cms/services/authen.service';
import { ModalExportOrderComponent } from '../../modal/modal-export-order/modal-export-order.component';


@Component({
    selector: 'app-report-team-sale',
    templateUrl: './report-team-sale.component.html',
    styleUrls: ['./report-team-sale.component.css']
})
export class ReportTeamSaleComponent implements OnInit {
    @Input() id: any;
    model_order: any = {
        status: "",
        ordercode: "",
        trackcode: "",
        search: "",
        paymenttype: "",
        created_by: "",
        "page": 1,
        "perpage": 10
    }
    model_report:any = {
        startdate: "",
        enddate: ""
    }
    rangePicker: any = [];
    currentdatetime: any;

    order: any = [];
    total: number = 0;
    statusimg: any;
    paymentimg: any;
    shippopimg: any;
    api_path: string;
    access_token: string;
    loading: boolean = false;
    reportSale:any;
    saleProvince:any = [];
    team_data:any = [];
    viewChart = 'l';
    pieChartSale: any = {
        chartType: 'PieChart',
        dataTable: [
            ['Name', 'Value']
        ],
        options: {
            title: '',
            height: 280,
            legend: { position: 'right', alignment: 'center' },
            colors: ['#e2431e', '#1c91c0', '#FFC96D', '#79C87D','#CA71C9'],
            tooltip: { isHtml: true },
            chartArea: { 'height': '225','bottom': 15, 'top': 50 },
        }
    }

    pieChartSaleProvince: any = {
        chartType: 'PieChart',
        dataTable: [
            ['Name', 'Value']
        ],
        options: {
            title: '',
            height: 280,
            legend: { position: 'right', alignment: 'center' },
            colors: ['#8EAAE6', '#EE927A', '#FFC96D', '#79C87D','#CA71C9'],
            tooltip: { isHtml: true },
            chartArea: { 'height': '250', 'bottom': 15, 'top': 15 },
        }
    }

    lineChartSale: any = {
        chartType: 'AreaChart',
        dataTable: [

        ],
        options: {
            title: '',
            height: 280,
            legend: { position: 'top', alignment: 'center' },
            colors: ['#e2431e', '#1c91c0', '#6a8cc7', '#da6272'],
            tooltip: { isHtml: true },
            chartArea: { 'width': '75%'},
        }
    }

    constructor(
        private router: Router,
        private ServiceTeam: TeamService,
        private Service_Util: UtilService,
        private Service_Product: ProductsService,
        private Service_Order: OrderService,
        private _etc: Etc,
        private modal: NzModalService,
        private _authen: AuthenService,
        private Service_Dashboard: DashboardService
    ) {

    }

    async ngOnInit() {
        this.api_path = environment.api_path;
        this.access_token = this._authen.getToken().access_token;
        this.statusimg = this._etc.orderstatus_img();
        this.paymentimg = this._etc.payment_img();
        this.shippopimg = this.Service_Util.getShippopImage();
        let team: any = await this.ServiceTeam.getTeamById(this.id);
        // this.model_log.created_by = team.usr_name;
        this.model_order.created_by = team.usr_name;
        this.model_report.user_id = team.id;
        this.currentdatetime = await this.Service_Util.getDateTime();
        this.rangePicker = [moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD'), moment(this.currentdatetime.date).format('YYYY-MM-DD')];
        this.dateRangeChange();

        this.team_data = await this.ServiceTeam.getTeamById(this.id);
        // console.log('test' + this.team_data.name);
    }

    dateRangeChange() {
        try {
            this.model_report.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD');
            this.model_report.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD');
           

            let _maxrange = moment(this.model_report.startdate).add(2, 'month').format('x');
            let _enddate = moment(this.model_report.enddate).format('x');
            if (_enddate > _maxrange) {
                this.model_report.enddate = moment(this.model_report.startdate).add(2, 'month').format('YYYY-MM-DD');
                // this.rangePicker[1] = this.model_report.enddate;
                this.rangePicker = [this.model_report.startdate, this.model_report.enddate];
                this.modal.warning({
                    nzTitle: '',
                    nzContent: `ขออภัย เนื่องจากท่านได้ทำการเลือกช่วงเวลาเกิน 60 วัน
ดังนั้นข้อมูลที่แสดงจะเป็นข้อมูลนับจาก วันเริ่มต้นไป 60 วันเท่านั้น`
                });
            }

            this.model_order.startdate = this.model_report.startdate;
            this.model_order.enddate = this.model_report.enddate;

            

        } catch (e) {
            console.log(e);
        }
        this.searchReportSale();
        this.searchOrder();
    }
    searchReportSale(){
        this.pieChartSale.dataTable = [];
        this.lineChartSale.dataTable = [];
        this.pieChartSaleProvince.dataTable = [["จังหวัด","จำนวน"]];
        this.Service_Dashboard.saleReport(this.model_report).then((res:any)=>{
            console.log(res);
            this.reportSale = res;
            this.pieChartSale.dataTable.push(['ชื่อ', 'จำนวน']);

            this.pieChartSale.dataTable.push(["ออเดอร์ค้างชำระ", res.open_order.qty]);
            this.pieChartSale.dataTable.push(["ออเดอร์ชำระแล้ว", res.close_order.qty]);

            this.lineChartSale.dataTable.push(['Date', 'ออเดอร์ค้างชำระ', 'ออเดอร์ชำระแล้ว']);
            for(let i=0;i<res.data.length;i++){
                let current = res.data[i];
                this.lineChartSale.dataTable.push([current.date,current.open_order,current.close_order]);
            }



            try {
                this.lineChartSale.component.draw();
                this.pieChartSale.component.draw();
                
            } catch (e) { }
        }).catch((data) => {
        });
        this.Service_Dashboard.saleReportProvince(this.model_report).then((res: any) => {
            console.log(res);
            this.saleProvince = res;

            for(let i=0;i<this.saleProvince.length;i++){
                let current = this.saleProvince[i];
                this.pieChartSaleProvince.dataTable.push([current.bill_province,current.qty]);
            }

            try{
                this.pieChartSaleProvince.component.draw();
            }catch(e){}

        }).catch((data) => {
        });

    }
    searchOrder() {
        this.loading = true;
        this.Service_Order.search(this.model_order).then((res: any) => {
            console.log(res);
            this.loading = false;
            this.order = res.data;
            this.total = res.count;
        }).catch((data) => {
            this.loading = false;
        });
    }

    pagechage(page) {
        this.model_order.page = page;
        this.searchOrder();
    }

    export(){
        const modal = this.modal.create({
            nzTitle: 'Export',
            nzContent: ModalExportOrderComponent,
            nzStyle: { top: '20px' },
            // nzWidth: "750px",
            nzKeyboard: false,
            nzMaskClosable: false,
            nzOnOk: (componentInstance) => {
                return componentInstance.ok();
            },
            nzOnCancel: (componentInstance) => {

            },
            nzComponentParams: {
                data: {
                    created_by: this.model_order.created_by
                }
            }
        });
    }

    async checkInuseActivity(obj) {
        let rs = await this.Service_Util.inuseActivity({ type: "order", param_id: obj.id });
        console.log(rs);
        return rs;
    }

    async edit(obj) {
        let inuse: any = await this.checkInuseActivity(obj);
        if (!inuse.available) {
            this.modal.error({
                nzTitle: 'Error',
                nzContent: `${inuse.user} กำลังทำการแก้ไขรายการนี้อยู่`
            });
            return;
        }

        let url = '/system/e-commerce/order-detail/' + obj.ordercode;
        window.open(url, '_blank', "menubar=no,toolbar=no,location=no,width=1280,height=720");

    }

    backto_teams(){
        this.router.navigate(['/system/users/teams']);
    }
}