import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TeamService {

    constructor(
        private _http: HttpClient
    ) { }

    getTeams(obj: any) {
        return this._http.post('/team/search', obj).toPromise();
    }

    getTeamById(id: number) {
        return this._http.get(`/team/${id}`).toPromise();
    }

    updateProfile(obj: any) {
        return this._http.put(`/team/${obj.id}`, obj).toPromise();
    }

    listRole() {
        return this._http.get('/team/role').toPromise();
    }

    changeRole(obj: any){
        return this._http.put(`/team/changerole/${obj.id}`, obj).toPromise();
    }

    changePasswd(obj: any){
        return this._http.put(`/team/changepassword/${obj.id}`, obj).toPromise();
    }

    create(obj: any){
      return this._http.post(`/team/create`, obj).toPromise();
    }


    delete(obj: any){
      return this._http.delete(`/team/${obj.id}`).toPromise();
    }


}
