import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuRoutingModule } from './menu-routing.module';
import { MenuComponent } from './menu.component';
import { FormsModule } from '@angular/forms';
import { KetPermissionModule } from '@ketshopweb/ui/permission';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NestableModule } from '@ketshopweb/ngx-nestable';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzUploadModule } from 'ng-zorro-antd/upload';
// import { KetFilemanagerModule } from '@ketshopweb/ui/filemanager';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [
    MenuComponent
  ],
  imports: [
    CommonModule,
    MenuRoutingModule,
    FormsModule,
    KetPermissionModule,
    NzDropDownModule,
    NzMenuModule,
    NzAlertModule,
    NzModalModule,
    NestableModule,
    NzSwitchModule,
    NzButtonModule,
    NzUploadModule,
    // KetFilemanagerModule,
    NzIconModule,
    NzToolTipModule 
  ]
})
export class MenuModule { }
