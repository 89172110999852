
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Etc{
    isBackEnd(){
        let search = location.href.search(`${window.location.origin}/system`);
        // console.log("isbackend", search);
        if(search == -1){
            return false;
        }else{
            return true;
        }
      }
    
      getCurrentLang(){
        let backend = this.isBackEnd();
        let lang = "lang1";
    
        if(backend){
            lang = localStorage.getItem('lang_backend');
            if(!lang){
                localStorage.setItem('lang_backend','lang1');
            }
            lang = localStorage.getItem('lang_backend');
        }else{
            lang = localStorage.getItem('lang');
            if(!lang){
                lang = 'lang1';
            }
        }
        return lang;
      }
}
