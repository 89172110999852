import { Component, OnInit } from '@angular/core';
import { SettingService } from '@cms/services/setting.service';
import { apiError } from '@cms/interface/interface';
import { Router } from '@angular/router';
import { FeatureService } from '@cms/services/feature.service';
@Component({
  selector: 'app-blog-display',
  templateUrl: './blog-display.component.html',
  styleUrls: ['./blog-display.component.css']
})
export class BlogDisplayComponent implements OnInit {

	displayList = [
		{
			id: 1,
			desc: "การแสดงผลแบบปกติ",
			img: "/system/core-imgs/Product_Blog/blog-tmp-01.jpg"
		},
		{
			id: 2,
			desc: "การแสดงผลแบบ Grid",
			img: "/system/core-imgs/Product_Blog/blog-tmp-02.jpg"
		},
		{
			id: 3,
			desc: "การแสดงผลแบบเน้นจุดเด่น",
			img: "/system/core-imgs/Product_Blog/blog-tmp-03.jpg"
    },
    {
			id: 4,
			desc: "การแสดงผลแบบมินิมอล",
			img: "/system/core-imgs/Product_Blog/blog-tmp-04.jpg"
		}
  ];

	displayDetailList = [
		{
			id: 1,
			desc: "การแสดงผลแบบมีแถบด้านข้าง",
			img: "/system/core-imgs/Product_Blog/blogdetail-tmp-01.jpg"
		},
		{
			id: 2,
			desc: "การแสดงผลแบบเต็มหน้าจอ",
			img: "/system/core-imgs/Product_Blog/blogdetail-tmp-02.jpg"
		}
  ];

  displayListModel: number = 1
  displayDetailListModel: number = 1

  modal_edit:boolean = false
  modal_edit_detail:boolean = false

  blog_template_setting:number = 1
  blog_detail_template:number = 1

  options:any[] = []
  default_no_image:string = '/assets/images/core-imgs/ex_imgBg_df.png'
  displayListPreview:any
  displayDetailListPreview:any

  feature: boolean = false
  isFreeTrial: boolean = false;
  freeTrialDate: string = '';
  select_font_render:boolean = false
  blog_list_font_style:string = ''
  blog_list_font_size:number = 14

  blog_setting:any = {
    load_more: "0",
    prepage: 12,
    heaad_color: "#000",
    date_color: "#919aa3"
  }

  constructor(
    private _setting: SettingService,
    private router: Router,
    private Service_Feature: FeatureService
  ) { }

  async ngOnInit() {
    this.feature = await this.Service_Feature.getFeature("BLOG_PAGE");
    if(!this.feature) return;
    this.freeTrialDate = await this.Service_Feature.getFormatDate("BLOG_PAGE");
    if(this.freeTrialDate) this.isFreeTrial = true;
    this.default_no_image = localStorage.getItem('default_no_image')
    this._setting.searchOption({
      pop_key: [
        'blog_template_setting',
        'blog_detail_template',
        'blog_list_font_style',
        'blog_list_font_size',
        'blog_setting'
      ]
    }).then((res:any[])=>{
      //console.log(res)
      this.options = res
      for(let obj of res){
        //console.log(obj)
        switch (obj.pop_key) {
          case 'blog_template_setting':
            this.blog_template_setting = Number(obj.pop_value)
            this.displayListPreview = this.displayList.find(x => x.id == this.blog_template_setting)
            break;
          case 'blog_detail_template':
            this.blog_detail_template = Number(obj.pop_value)
            this.displayDetailListPreview = this.displayDetailList.find(x => x.id == this.blog_detail_template)
            break;
          case 'blog_list_font_style':
            this.blog_list_font_style = obj.pop_value
            this.select_font_render = true
            break;
          case 'blog_list_font_size':
              this.blog_list_font_size = Number(obj.pop_value)
              break;
          case 'blog_setting':

              //console.log(obj.pop_value)
              var _data = obj.pop_value
              try{
                _data = JSON.parse(_data)
              }catch(err){

              }
              //console.log(typeof _data)
              if(typeof _data == 'object'){
                //console.log(obj.pop_value)
                if(_data){
                 this.blog_setting = _data
                }
              }
            break;
          default:
            break;
        }

      }

    }).catch((e: apiError)=>{
      console.log(e.error.message)
    })

  }

  openModal(){
    this.displayListModel = this.blog_template_setting
    this.modal_edit = true

  }

  handleOk(){
    this.blog_template_setting = this.displayListModel
    this.displayListPreview = this.displayList.find(x => x.id == this.blog_template_setting)
    this.modal_edit = false
  }

  handleCancel(){
    this.modal_edit = false
  }


  openModalDetail(){
    this.displayDetailListModel = this.blog_detail_template
    this.modal_edit_detail = true

  }

  handleOkDetail(){
    this.blog_detail_template = this.displayDetailListModel
    this.displayDetailListPreview = this.displayDetailList.find(x => x.id == this.blog_detail_template)
    this.modal_edit_detail = false
  }

  handleCancelDetail(){
    this.modal_edit_detail = false
  }


  save(){

    for(let obj of this.options){
      switch (obj.pop_key) {
        case 'blog_template_setting':
          obj.pop_value = this.blog_template_setting
          break;
        case 'blog_detail_template':
          obj.pop_value = this.blog_detail_template
          break;
        case 'blog_list_font_size':
          obj.pop_value = this.blog_list_font_size
          break;
        case 'blog_list_font_style':
          obj.pop_value = this.blog_list_font_style
          break;
        case 'blog_setting':
          obj.pop_value = JSON.stringify(this.blog_setting)
          break;
        default:
          break;
      }

    }

    this._setting.saveOption(this.options).then((res)=>{

      var url = decodeURI(this.router.url);
      this.router.navigated = false;
      this.router.navigate(['/system']).then(()=>this.router.navigate([url]));

    }).catch((e:apiError)=>{
      console.log(e)
    })

  }

}

