import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ConfirmPaymentService } from '@cms/services/confirmpayment.service';

@Component({
  selector: 'app-modal-edit-confirmpayment',
  templateUrl: './modal-edit-confirmpayment.component.html',
  styleUrls: ['./modal-edit-confirmpayment.component.css']
})
export class ModalEditConfirmpaymentComponent implements OnInit {
  @Input() data: any;
  model:any = {
    status: ""
  }
  allstatus:any = [];
  old_status:any;
  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_ConfirmPayment: ConfirmPaymentService
  ) { }

  async ngOnInit() {
    this.old_status = this.data.confirm.status;
    this.allstatus = await this.Service_ConfirmPayment.confirmstatus();
    this.Service_ConfirmPayment.byID(this.data.confirm.id).then((res:any)=>{
      this.model = res;
      console.log(this.model);
    }).catch((data:any)=>{
      console.log(data.error.message);
    });
  }

  ok(){
    console.log(this.model);
    this.Service_ConfirmPayment.updateStatus(this.model).then((res:any)=>{
      this.modal.close({ data: this.model });
    }).catch((data: any) => {
      
      this.message.error(`${data.error.message}`, { nzDuration: 6000 });
      return false;
    });
    return false;
  }

}
