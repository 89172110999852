import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NzInputNumberComponent } from './nz-input-number.component';
import { NzIconModule } from '../icon';

@NgModule({
  imports     : [ CommonModule, FormsModule, NzIconModule ],
  declarations: [ NzInputNumberComponent ],
  exports     : [ NzInputNumberComponent ]
})
export class NzInputNumberModule {
}