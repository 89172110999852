import { Component, OnInit } from '@angular/core';
import { FeatureService } from '@cms/services/feature.service';
import { ExtensionService } from '@cms/services/extension.service';
import { NzModalService } from 'ng-zorro-antd/modal';
// import { ModalCreateShopeeProductComponent } from '../modal/modal-create-shopee-product/modal-shopee-product';
// import { ModalEditShopeeProductComponent } from '../modal/modal-edit-shopee-product/modal-edit-shopee-product.component';
import { PermissionDirective } from '@cms/dirctives/permission.directive';
import { ModalEditLazadaProductComponent } from '../modal/modal-edit-lazada-product/modal-edit-lazada-product.component';
import { ModalGetLazadaOrderComponent } from '../modal/modal-get-lazada-order/modal-get-lazada-order.component';
// import { ModalCreateLazadaProductComponent } from '../modal/modal-create-lazada-product/modal-create-lazada-product.component';
// import { ModalEditLazadaProductComponent } from '../modal/modal-edit-lazada-product/modal-edit-lazada-product.component';

@Component({
  selector: 'app-lazada',
  templateUrl: './lazada.component.html',
  styleUrls: ['./lazada.component.css']
})
export class LazadaComponent implements OnInit {
  _permission: PermissionDirective = new PermissionDirective()
  feature: boolean = false;
  model_search_product:any = {
    startdate: "",
    enddate: "",
    search: "",
    "page": 1,
    "perpage": 10,
  }
  product:any = [];
  total_product:number = 0;


  model_search_order: any = {
    page: 1,
    perpage: 10,
    ordercode: "",
    order_number: "",
    startdate: "",
    enddate: ""
  }
  order: any = []
  total_order = 0;

  constructor(
    private Service_Feature: FeatureService,
    private Service_Extension: ExtensionService,
    private modal: NzModalService
  ){}

  async ngOnInit() {
    this.feature = await this.Service_Feature.getFeature("LAZADA");
    if (!this.feature) {
      return;
    }

    this.searchProduct();
    this.searchOrderLazada();
  }


  syncProduct() {
    $('.theme-loader').fadeIn();
    this.Service_Extension.syncProductLazada().then((res) => {
      $('.theme-loader').hide();
      this.modal.success({
        nzTitle: 'ดำเนินการดึงสินค้า จาก Lazada',
        nzContent: `ขณะนี้ระบบกำลังดำเนินการดึงสินค้า จาก Lazada
        เมื่อดำเนินการเสร็จสิ้น ระบบจะแจ้งผลการเชื่อมต่อสินค้า ไปยังอีเมล
        เจ้าของเว็บไซต์ (Admin)`        
      });
    }).catch((data) => {
      $('.theme-loader').hide();
      console.log(data);
    });
  }

  async viewOrder(obj: any) {
    console.log(obj)
    if (!this._permission.checkPermission(['administrator', 'webmaster', 'staff', 'sale', 'store'])) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `Permission denied`
      });
      return;
    }

    let url = `${window.location.origin}/system/e-commerce/order-detail/${obj.ordercode}`;
    window.open(url, '_blank', "menubar=no,toolbar=no,location=no,width=1280,height=720");

  }

  searchProduct() {
    this.Service_Extension.searchLazadaProduct(this.model_search_product).then((res: any) => {
      console.log(res);
      this.product = res.data;
      this.total_product = res.count;
    }).catch((data) => {
      console.log(data);
    })
  }

  pagechageProduct(page) {
    this.model_search_product.page = page;
    this.searchProduct();
  }

  getByOrderNumber(){
    const modal = this.modal.create({
      nzTitle: null,
      nzContent: ModalGetLazadaOrderComponent,
      nzBodyStyle: {
        // 'padding': '0px',
        // 'background': '#f6f6f6'
      },
      nzWidth: "500px",
      nzWrapClassName: 'vertical-center-modal',
      nzClassName: 'modal-medium',
      // nzKeyboard: false,
      // nzMaskClosable: false,
      nzFooter: null,
      nzOnOk: (componentInstance) => {
        return componentInstance.ok();
      },
      nzOnCancel: (componentInstance) => {

      },
      nzComponentParams: {
        data: {
         
        }
      }
    }).afterClose.subscribe((result) => {
      modal.unsubscribe()
      this.searchProduct();
    });
  }

  editProduct(obj){


    const modal = this.modal.create({
      nzTitle: "แก้ไขสินค้า Lazada",
      nzContent: ModalEditLazadaProductComponent,
      nzBodyStyle: {
        // 'padding': '0px',
        // 'background': '#f6f6f6'
      },
      nzWidth: "50%",
      nzWrapClassName: 'vertical-center-modal',
      nzClassName: 'modal-medium',
      // nzKeyboard: false,
      // nzMaskClosable: false,
      nzFooter: null,
      nzOnOk: (componentInstance) => {
        return componentInstance.ok();
      },
      nzOnCancel: (componentInstance) => {

      },
      nzComponentParams: {
        data: {
          item: obj
        }
      }
    }).afterClose.subscribe((result) => {
      modal.unsubscribe()
      this.searchProduct();
    });

  }


  syncOrderLazada(){

    $('.theme-loader').show();
    this.Service_Extension.syncOrderLazada().then((res) => {
      console.log(res);
      this.modal.success({
        nzTitle: '',
        nzContent: `ขณะนี้ระบบกำลังดำเนินการดึงข้อมูล จาก Lazada
  ซึ่งจะทำการดึงข้อมูลสำเร็จภายใน 5 นาที`
      });
      $('.theme-loader').hide();
      this.searchOrderLazada();
    }).catch((data) => {
      $('.theme-loader').hide();
      console.log(data.error.message);
    })

  }

  searchOrderLazada() {
    this.Service_Extension.searchOrderLazada(this.model_search_order).then((res: any) => {
      this.order = res.data;
      console.log(res.data);
      this.total_order = res.count;
    }).catch((data) => {
      console.log(data.error.message);
    });
  }

  pagechageOrder(page) {
    this.model_search_order.page = page;
    this.searchOrderLazada();
  }

}