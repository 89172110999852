import { Component, OnInit } from '@angular/core';
import { FeatureService } from '@cms/services/feature.service';
import { ExtensionService } from '@cms/services/extension.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalCreateShopeeProductComponent } from '../modal/modal-create-shopee-product/modal-shopee-product';
import { ModalEditShopeeProductComponent } from '../modal/modal-edit-shopee-product/modal-edit-shopee-product.component';
import { PermissionDirective } from '@cms/dirctives/permission.directive';

@Component({
    selector: 'app-shopee',
    templateUrl: './shopee.component.html',
    styleUrls: ['./shopee.component.css']
})
export class ShopeeComponent implements OnInit{
    _permission: PermissionDirective = new PermissionDirective()
    feature: boolean = false;
    product_complete:any = [];
    total_product_complete:number = 0;
    select_tap = 1;
    loading:boolean = false;
    model_search_product_complete = {
        startdate: "",
        enddate: "",
        status: null,
        search: "",
        complete: 1,
        "page": 1,
        "perpage": 10,
        orderby: "id",
        sort: "desc"
    }

    model_search_order:any = {
        page: 1,
        perpage: 10,
        startdate: "",
        enddate: ""
    }
    order:any = []
    total_order = 0;
    statusname:any = {
        'NORMAL': 'ปกติ',
        'UNLIST': 'ไม่แสดง' ,
        'BANNED': 'ถูกระงับ'
    }

    constructor(
        private Service_Feature:FeatureService,
        private Service_Extension:ExtensionService,
        private modal: NzModalService
    ){}
    
    async ngOnInit() {
        this.feature = await this.Service_Feature.getFeature("SHOPEE");
        if (!this.feature) {
            return;
        }

        this.Service_Extension.getShopeeShopInfo().then((data)=>{
            console.log(data);
        }).catch((data)=>{
            console.log(data);
        })

        // this.drawerRef.nzTitle = "kkkkk";
        // console.log();
        this.searchProductCompelte();
        this.searchOrderShopee();
    }

    changeStatus(item,unlist){
        let obj = {
            item_id: item.item_id,
            unlist: unlist
        }
        $('.theme-loader').fadeIn();
        this.Service_Extension.unListItem(obj).then((data:any)=>{
            $('.theme-loader').hide();
            this.searchProductCompelte();
        }).catch((data)=>{
            $('.theme-loader').hide();
            this.modal.error(data.error.message);
        });
    }

    sort(orderby){
        
        this.model_search_product_complete.orderby = orderby;
        if (this.model_search_product_complete.sort == 'desc') {
            this.model_search_product_complete.sort = 'asc';
        } else {
            this.model_search_product_complete.sort = 'desc';
        }
        this.searchProductCompelte();
    
    }

    syncProductShopee(){
        $('.theme-loader').fadeIn();
        this.Service_Extension.syncProductShopee().then((res)=>{
            $('.theme-loader').hide();
            this.modal.success({
                nzTitle: 'ดำเนินการดึงสินค้า จาก Shopee',
                nzContent: `ขณะนี้ระบบกำลังดำเนินการดึงสินค้า จาก Shopee
                เมื่อดำเนินการเสร็จสิ้น ระบบจะแจ้งผลการเชื่อมต่อสินค้า ไปยังอีเมล
                เจ้าของเว็บไซต์ (Admin)`
            });
        }).catch((data)=>{  
            $('.theme-loader').hide();
            console.log(data);
        })
    }

    async viewOrder(obj: any) {
        console.log(obj)
        if (!this._permission.checkPermission(['administrator', 'webmaster', 'staff', 'sale', 'store'])) {
            this.modal.error({
                nzTitle: 'Error',
                nzContent: `Permission denied`
            });
            return;
        }

        let url = `${window.location.origin}/system/e-commerce/order-detail/${obj.ordercode}`;
        window.open(url, '_blank', "menubar=no,toolbar=no,location=no,width=1280,height=720");

    }
    openModalAddProduct(){
        const modal = this.modal.create({
            nzTitle: null,
            nzContent: ModalCreateShopeeProductComponent,
            // nzStyle: { top: '20px' },
            nzBodyStyle:{ 
                'padding':'0px',
                'background':'#f6f6f6'
            },
            nzClassName: 'modal-medium',
            nzWrapClassName: 'vertical-center-modal',
            nzWidth: "90%",
            nzKeyboard: false,
            nzMaskClosable: false,
            nzFooter: null,
            nzOnOk: (componentInstance) => {
                return componentInstance.ok();
            },
            nzOnCancel: (componentInstance) => {

            },
            nzComponentParams: {
                data: {

                }
            }
        }).afterClose.subscribe((result) => {
            modal.unsubscribe()
            console.log(result);
            if(result){
                this.modal.success({
                    nzTitle: 'Complete',
                    nzContent: ''
                });
                this.searchProductCompelte();
            }
        });
    }

    searchProductCompelte(clearpage = false){
        if(clearpage){
            this.model_search_product_complete.page = 1;
        }
        this.loading = true;
        this.Service_Extension.searchShopeeProduct(this.model_search_product_complete).then((res:any)=>{
            this.product_complete = res.data;
            this.total_product_complete = res.count;

            // this.product_complete.forEach(obj => {
            //     let sum = 0;
            //     obj.variation.forEach(obj2 => {
            //         sum += obj2.stock;
            //     });
            //     obj.stock = sum;
            // });

        })
        .catch((data)=>{
            console.log(data.error.message);
        })
        .finally(() => {
            this.loading = false;
        });
    }

    pagechageProductComplete(page) {
        this.model_search_product_complete.page = page;
        this.searchProductCompelte();
    }

    editProduct(obj){
        console.log("open edit");
        const modal = this.modal.create({
            nzTitle: null,
            nzContent: ModalEditShopeeProductComponent,
            nzBodyStyle:{ 
                'padding':'0px',
                'background':'#f6f6f6'
            },
            nzWidth: "90%",
            nzWrapClassName: 'vertical-center-modal',
            nzClassName: 'modal-medium',
            nzKeyboard: false,
            nzMaskClosable: false,
            nzFooter: null,
            nzOnOk: (componentInstance) => {
                return componentInstance.ok();
            },
            nzOnCancel: (componentInstance) => {

            },
            nzComponentParams: {
                data: {
                    item: obj
                }
            }
        }).afterClose.subscribe((result) => {
            modal.unsubscribe()
            this.searchProductCompelte();
        });
    }
    deleteProduct(item) {
        // console.log(item);
        this.modal.confirm({
            nzTitle: `Are you sure to delete item ${item.name} ?`,
            nzContent: '',
            nzOkText: 'Yes',
            nzOkType: 'danger',
            nzOnOk: () => {
                $('.theme-loader').fadeIn();
                this.Service_Extension.deleteProductShopee(item).then((res) => {
                    console.log(res);
                    $('.theme-loader').hide();
                    this.modal.success({
                        nzTitle: 'Delete item success',
                        nzContent: ''
                    });

                    this.searchProductCompelte();
                }).catch((data) => {
                    $('.theme-loader').hide();
                    console.log(data.error.message);
                    this.modal.error({
                        nzTitle: 'Error',
                        nzContent: data.error.message,
                        nzOnOk: () => {
                            
                        }
                    });
                });
            },
            nzCancelText: 'No',
            nzOnCancel: () => { }
        });
       
    }

    ////// ORDER 
    
    searchOrderShopee(){
        this.Service_Extension.searchOrderShopee(this.model_search_order).then((res:any)=>{
            this.order = res.data;
            this.total_order = res.count;
        }).catch((data) => {
            console.log(data.error.message);
        });
    }

    pagechageOrder(page) {
        this.model_search_order.page = page;
        this.searchOrderShopee();
    }

    syncOrderShopee(){
        $('.theme-loader').show();
        this.Service_Extension.syncOrderShopee().then((res)=>{
            console.log(res);
            $('.theme-loader').hide();
            this.searchOrderShopee();
        }).catch((data)=>{
            $('.theme-loader').hide();
            console.log(data.error.message);
        })
    }

    viewOrderShopee(){

    }
}
