import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EmailService } from '@cms/services/email.service';

@Component({
    selector: 'app-modal-change-email-password',
    templateUrl: './modal-change-email-password.component.html',
    styleUrls: ['./modal-change-email-password.component.css']
})
export class ModalChangeEmailPasswordComponent implements OnInit {
    @Input() data: any;
    message_duration: number = 4000;
    domain: string = '@' + location.hostname;
    model: any = {
        email: "",
        password: "",
        confirmpassword: ""
    }
    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private Service_Email: EmailService
    ) { }
 
    ngOnInit() {
        this.model.email = this.data.email.MAIL;
    }

    ok(){
        if (this.model.password == "") {
            this.message.error("โปรดกรอกรหัสผ่าน", { nzDuration: this.message_duration });
            return false;
        } else if (this.model.password != this.model.confirmpassword) {
            this.message.error("รหัสผ่านไม่ตรงกัน", { nzDuration: this.message_duration });
            return false;
        } else if (!/^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{9,}$/.test(this.model.password)) {
            this.message.error("รหัสผ่านต้องมากกว่า 8 ตัวอักษร โดยรหัสต้องเป็นตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น และต้องมีตัวเลขอย่างน้อย 1 ตัว", { nzDuration: this.message_duration });
            return false;
        }

        $('.theme-loader').show();
        this.Service_Email.changePassword(this.model).then((res: any) => {
            this.modal.close(true);
        }).catch((data: any) => {
            this.message.error(data.error.message, { nzDuration: this.message_duration });
            return false;
        }).finally(() => {
            $('.theme-loader').hide();
        })

        console.log(this.model);
        return false;
    }
}