import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ProductsService } from '@cms/services/products.service';
import { ExtensionService } from '@cms/services/extension.service';
import { SettingService } from '@cms/services/setting.service';
import { cloneDeep } from 'lodash';

declare var alasql:any
 
@Component({
    selector: 'app-modal-shopee-product',
    templateUrl: './modal-shopee-product.component.html',
    styleUrls: ['./modal-shopee-product.component.css']
})
export class ModalCreateShopeeProductComponent implements OnInit {
    @Input() data: any;

    constructor(
        private modal: NzModalRef,
        private modalService: NzModalService,
        private Service_Product: ProductsService,
        private Service_Extension: ExtensionService,
        private Service_Setting: SettingService
    ) { }
    message_duration: any = 3000;
    modelsearch: any = {
        page: 1,
        perpage: 10,
        search: "",
        lang: "lang1"
    }
    product: any = [];
    total = 0;

    category: any = [];
    logistic: any = [];
    attribute: any = [];
    category_level1: any = [];
    category_level2: any = [];
    category_level3: any = [];
    category_level4: any = [];
    category_level1_selected: any = [];
    category_level2_selected: any = [];
    category_level3_selected: any = [];
    category_level4_selected: any = [];
    require_attribute = [];
    logistic_selected: any = [];
    step: number = 0;
    prop_enable = 0;
    available_stock = 0;

    ket_product_id = null;

    /* SHOPEE MODEL */
    variation = {
        tier_variation: [],
        variation: []
    }

    _images:any = [
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" }
    ]

    model: any = {
        "category_id": "",
        "name": "", // >= 20 character
        "description": "", // > 25 character
        "price": 0, // number
        "stock": 0, // number
        "original_price": 0,
        "item_sku": "",
        "vat": 0,
        "images": [

        ], // Max size: 2MB
        "attributes": [],
        "logistics": [],
        "weight": 0, // kg
        "package_length": 0, // cm
        "package_width": 0, // cm
        "package_height": 0, // cm
        "days_to_ship": 2,
        "size_chart": "",
        "condition": "NEW/USED",
        "status": "NORMAL",
        "tier_variation_name": null,
        "tier_variation_name2": null
    }

    domain = location.origin;
    option:any = {}
    allcategory:any = [];

    async ngOnInit() {
        let pop_key = ['vat_setting'];
        let _option: any = await this.Service_Setting.searchOption({ pop_key: pop_key });
        
        _option.forEach(obj => {
            this.option[obj.pop_key] = obj.pop_value;
        });

        this.allcategory = await this.Service_Product.productMainCategory();
        this.allcategory.unshift({ id: "-1", lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory" });
        this.allcategory.unshift({ id: "", lang1: "All", lang2: "All", lang3: "All", lang4: "All" });

       this.search();
    }



    /* SHOPEE LOGIC */
    selectCategory(obj, level) {
        if (!obj.has_children) {
            this.model.category_id = obj.category_id;
        } else {
            this.model.category_id = "";
        }
        switch (level) {
            case 1:
                this.category_level1_selected = obj;
                this.category_level2_selected = null;
                this.category_level3_selected = null;

                if (obj.has_children) {
                    this.category_level2 = alasql('SELECT * FROM ? WHERE parent_id = ?', [this.category, obj.category_id]);
                }
                this.category_level3 = [];
                break;
            case 2:
                this.category_level2_selected = obj;
                this.category_level3_selected = null;
                if (obj.has_children) {
                    this.category_level3 = alasql('SELECT * FROM ? WHERE parent_id = ?', [this.category, obj.category_id]);
                }

                break;
            case 3:
                this.category_level3_selected = obj;
                this.category_level4_selected = null;
                if (obj.has_children) {
                    this.category_level4 = alasql('SELECT * FROM ? WHERE parent_id = ?', [this.category, obj.category_id]);
                }
                break;
            case 4:
                this.category_level4_selected = obj;
                break;
            default:
                break;
        }
    }
    trackByIdx(index: number, obj: any): any {
        return index;
    }

    back(){
        this.step--;
    }

    next() {
        this.step++;
        this.model.attributes = [];
        this.Service_Extension.getShopeeItemAttribute({ category_id: this.model.category_id }).then((data: any) => {
            this.attribute = data.attributes;
            this.require_attribute = this.attribute;
            this.require_attribute.forEach(obj => {
                let model_attr:any = {
                    attributes_id: obj.attribute_id,
                    value: ""
                }
                try{
                    if (obj.format_type == 'QUANTITATIVE'){
                        model_attr.value_tmp = "";
                        model_attr.value_unit = obj.unit_list[0];
                    }
                }catch(e){}
                this.model.attributes.push(model_attr);
            });
            console.log('=====================');
            console.log(this.require_attribute);
            console.log(this.model.attributes);
            console.log('=====================');
        }).catch((data) => {
            console.log(data);
        });
    }

    addProperty2() {
        this.variation.tier_variation[1].options.push("");
        let lastindex = this.variation.tier_variation[1].options.length - 1;
        for (let i = 0; i < this.variation.tier_variation[0].options.length; i++) {
            this.variation.variation.push({
                tier_index: [i, lastindex],
                stock: 0,
                price: 0,
                variation_sku: ""
            });
            this.variation.variation = alasql("SELECT * FROM ? ORDER BY tier_index", [this.variation.variation]);
        }
    }

    addProperty1() {

        this.variation.tier_variation[0].options.push("");
        if (this.prop_enable == 1) {
            this.variation.variation.push({
                tier_index: [this.variation.tier_variation[0].options.length - 1],
                stock: 0,
                price: 0,
                variation_sku: ""
            });
        } else if (this.prop_enable == 2) {
            let lastindex = this.variation.tier_variation[0].options.length - 1;

            for (let i = 0; i < this.variation.tier_variation[1].options.length; i++) {
                this.variation.variation.push({
                    tier_index: [lastindex, i],
                    stock: 0,
                    price: 0,
                    variation_sku: ""
                });
            }

        }

    }

    calculateSalePrice(){
        if (this.prop_enable == 0) {
            let vatval  = this.model.original_price * (+this.option.vat_setting * 0.01);
            this.model.vat = Math.round((+vatval) * 100) / 100;
            this.model.price = Math.round( this.model.original_price + this.model.vat );
        } else {
            for (var i = 0; i < this.variation.variation.length;i++){
                this.variation.variation[i].original_price = +this.variation.variation[i].original_price;
                let vatval = (+this.variation.variation[i].original_price) * (+this.option.vat_setting * 0.01);
                this.variation.variation[i].vat = Math.round((+vatval) * 100) / 100;
                this.variation.variation[i].price = Math.round(this.variation.variation[i].original_price + this.variation.variation[i].vat);
            }
        }
    }

    async initModelShopee(item) {
        console.log(item);
        this.prop_enable = item.prop_enable;
        this.model.name = item.title_lang1;
        // this.model.description = item.short_desc_lang1;
        let v = item.short_desc_lang1;
        if (v) {
            v = v.replace(/<\s*br\/*>/gi, "\n");
            v = v.replace(/<\s*a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 (Link->$1) ");
            v = v.replace(/<\s*\/*.+?>/ig, "\n");
            v = v.replace(/ {2,}/gi, " ");
            v = v.replace(/\n+\s*/gi, "\n\n");
            v = v.replace(/&nbsp;/, "");
            v = v.replace(/(\r\n|\n|\r)/gm, "");
        }
        this.model.description = v;
        this.model.item_sku = item.sku;

        let _img = item.feature_img;
        if (/^http/g.test(item.feature_img)) {
            _img = item.feature_img;
        } else {
            _img = `${location.origin}${item.feature_img}`;
        }

        this._images[0].url = _img;
        this.model.weight = (+item.weight) * 0.0001;
        this.model.weight = +Number(this.model.weight).toFixed(2);
        this.model.original_price = item.price;

        if (this.prop_enable == 0) {
            this.variation.tier_variation = [];
            this.variation.variation = [];
           
        } else if (this.prop_enable == 1) {
            this.variation.tier_variation = [];
            this.variation.variation = [];
            this.model.tier_variation_name = item.properties_desc;
            this.variation.tier_variation.push({ name: item.properties_desc, options: [] });
            item.properties.forEach(_obj => {
                this.variation.tier_variation[0].options.push(_obj.property_info);
            });

            let ket_product_id = null;

            for (let i = 0; i < item.properties.length; i++) {
                let _img = item.properties[i].feature_img;
                if (/^http/g.test(item.properties[i].feature_img)) {
                    _img = item.properties[i].feature_img;
                } else {
                    _img = `${location.origin}${item.properties[i].feature_img}`;
                }

                this.variation.variation.push({
                    tier_index: [i],
                    stock: 0,
                    available_stock: item.properties[i].instock,
                    original_price: item.properties[i].price,
                    price: item.properties[i].price,
                    variation_sku: item.properties[i].sku,
                    in_ketshop: true,
                    ket_product_id: item.properties[i].id,
                    image: _img
                });

            }
            console.log(this.variation);
            

        } else if (this.prop_enable == 2) {
            // console.log("awefaewf");
            this.variation.tier_variation = [];
            this.variation.variation = [];
            // properties_desc
            this.model.tier_variation_name = item.properties_desc;
            this.model.tier_variation_name2 = item.properties_desc2;

            this.variation.tier_variation.push({ name: item.properties_desc, options: [] });

            let property_info = alasql("SELECT property_info FROM ? GROUP BY property_info ORDER BY properties_sort", [item.properties]);

            property_info.forEach(_obj => {
                this.variation.tier_variation[0].options.push(_obj.property_info);
            });



            this.variation.tier_variation.push({ name: item.properties_desc2, options: [] });
            let property_info2 = alasql("SELECT property_info2 FROM ? GROUP BY property_info2 ORDER BY properties_sort2", [item.properties]);
            property_info2.forEach(_obj => {
                this.variation.tier_variation[1].options.push(_obj.property_info2);
            });

            for (let i = 0; i < this.variation.tier_variation[0].options.length; i++) {
                for (let j = 0; j < this.variation.tier_variation[1].options.length; j++) {

                    let _stock = 0;
                    let _availablestock = 0;
                    let _price = 0;
                    let _sku = "-";
                    let _in_ketshop = false;
                    let ket_product_id = null;
                    let ket_product_image = null;
                    // if (this.variation.tier_variation[0].options[i])
                    for (let k = 0; k < item.properties.length; k++) {
                        let _img = item.properties[k].feature_img;
                        if (/^http/g.test(item.properties[k].feature_img)) {
                            _img = item.properties[k].feature_img;
                        } else {
                            _img = `${location.origin}${item.properties[k].feature_img}`;
                        }
                        if (item.properties[k].property_info == this.variation.tier_variation[0].options[i] &&
                            item.properties[k].property_info2 == this.variation.tier_variation[1].options[j]) {
                            _stock = 0;
                            _price = item.properties[k].price;
                            _availablestock = item.properties[k].instock;
                            _sku = item.properties[k].sku;
                            _in_ketshop = true;
                            ket_product_image = _img;
                            ket_product_id = item.properties[k].id
                        } else {
                            if (_price == 0) {
                                _price = item.properties[k].price;
                            }
                        }
                    }

                    this.variation.variation.push({
                        tier_index: [i, j],
                        stock: _stock,
                        available_stock: _availablestock,
                        original_price: _price,
                        price: _price,
                        variation_sku: _sku,
                        in_ketshop: _in_ketshop,
                        ket_product_id: ket_product_id,
                        image: ket_product_image
                    });
                }
            }
            this.variation.variation = alasql("SELECT * FROM ? ORDER BY tier_index", [this.variation.variation]);
        }


        this.calculateSalePrice();


        this.category = await this.Service_Extension.getShopeeItemCategory();
        this.category = this.category.categories;
        let _logistic: any = await this.Service_Extension.getShopeeLogistic();
        this.logistic = [];
        _logistic.logistics.forEach(obj => {
            obj.state = "normal";
            obj.tmp_fee = 0;
            if (obj.enabled) {
                obj.selected = false;
                obj.shipping_fee = 0;
                this.logistic.push(obj);
            }
        });
        window['alasql'] = alasql;
        this.category_level1 = alasql('SELECT * FROM ? WHERE parent_id = 0', [this.category]);
    }

    /* END SHOPEE LOGIC */

    ok() {
        this.model.images = [];
        this._images.forEach(_img => {
            if(_img.url){
                this.model.images.push({url: _img.url});
            }
        });

        this.model.prop_enable = this.prop_enable;
        this.model.attributes.forEach(obj => {
            try {
                if ( obj.hasOwnProperty("value_unit")) {
                    if (obj.value_tmp){
                        obj.value = `${obj.value_tmp}${obj.value_unit}`;
                    }else{
                        obj.value = `0${obj.value_unit}`;
                    }
                }
            } catch (e) { }
        });
        // this.model.attributes = this.model.attributes.filter(x=>x.value !== "");
        this.model.logistics = [];
        this.logistic.forEach(obj => {
            if (obj.selected) {
                this.model.logistics.push({
                    "logistic_id": obj.logistic_id,
                    "enabled": obj.enabled,
                    "shipping_fee": obj.shipping_fee
                })
            }
        });
      
        let obj = {
            product_id: this.ket_product_id,
            item: cloneDeep(this.model),
            variation: this.variation
        }
        
        obj.item.attributes = obj.item.attributes.filter(x => x.value !== "");

        obj.variation.variation.forEach(data => {
            data.stock = +data.stock;
            data.price = +data.price;
        });
        $('.theme-loader').fadeIn();
        this.Service_Extension.createProductShopee(obj).then((data: any) => {
            $('.theme-loader').hide();
            console.log(data);
            this.modal.close(true);
        }).catch((data) => {
            $('.theme-loader').hide();
            this.modalService.error({
                nzTitle: 'Error',
                nzContent: data.error.message,
                nzOnOk: () => {

                }
            });
            console.log(data);
        });

    }



    search(clearpage = false) {
        if(clearpage){
            this.modelsearch.page = 1;
        }
        this.Service_Extension.searchKetshopProductToShopee(this.modelsearch).then((data: any) => {
            console.log(data);
            this.product = data.data;
            this.total = data.count;
        }).catch((data) => {
            console.log(data);
        });
    }

    selected(item) {
        this.Service_Product.getProductByID( item.id ).then((data: any) => {
            // console.log(data);
            this.ket_product_id = data.id;
            this.available_stock = data.instock;
            this.initModelShopee(data);
            this.step++;
        }).catch((data) => {
            console.log(data);
        });
    }

    pagechage(page) {
        this.modelsearch.page = page;
        this.search();
    }




}
