import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UtilService } from '@cms/services/util.service';
import { ProductsService } from '@cms/services/products.service';
@Component({
  selector: 'app-modal-create-product-category',
  templateUrl: './modal-create-product-category.component.html',
  styleUrls: ['./modal-create-product-category.component.css']
})
export class ModalCreateProductCategoryComponent implements OnInit {
  @Input() data: any;

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Util: UtilService,
    private Service_Product: ProductsService
  ) { }
  lang:any = [];
  message_duration:number = 7000;
  model:any = {
    parent: 0,
    lang1: "",
    lang2: "",
    lang3: "",
    lang4: ""
  }
  tmp_filter: any;
  categoryAttributeList: any = [];
  selected_category_attribute: any = [];

  async ngOnInit() {
    console.log(this.data);
    this.lang = await this.Service_Util.getLangs();
    this.model.parent = this.data.parent_id;
    this.Service_Product.getCategoryAttributeByCateID(this.data.parent_id).then((res: any) => {
      console.log(res);
      for (let o of res.data.category_attribute) {
        let i = 1;
        let sample_value = [];
        for (let dt of o.details) {
          if (i <= 3) {
            sample_value.push(dt.attr_value);
          }
          if (i == 4) {
            sample_value.push('...');
          }
          i++;
        }
        o.sample = `${sample_value.join(",")}`;
      }
      this.selected_category_attribute = res.data.category_attribute;
    }).catch((data: any) => {
      console.log(data);
    });
    this.searchCategoryAttribute("");
    console.log(this.lang);
  }


  searchCategoryAttribute(event) {
    let obj = {
      search: event,
      page: 1,
      perpage: 20
    }
    this.Service_Product.searchCategoryAttribute(obj).then((res: any) => {
      for (let o of res.data) {
        let i = 1;
        let sample_value = [];
        for (let dt of o.details) {
          if (i <= 3) {
            sample_value.push(dt.attr_value);
          }
          if (i == 4) {
            sample_value.push('...');
          }
          i++;
        }
        o.sample = `${sample_value.join(",")}`;
      }
      this.categoryAttributeList = res.data;
    }).catch(data => {
      console.log(data.error.message);
    });
  }
  attributeSelect(event: any) {
    // console.log(event);
    if (!event) { return; }
    this.tmp_filter = null;
    let chk = this.selected_category_attribute.find(x => x.id == event.id);
    if (!chk) {
      this.selected_category_attribute.push(event);
    }

  }

  afterAttributeDelete(i) {
    this.selected_category_attribute.splice(i, 1);
  }


  async ok(){
 
    for (let i = 0; i < this.lang.length; i++) {
      console.log(this.model[this.lang[i].ref]);
      if (this.model[this.lang[i].ref] == null || this.model[this.lang[i].ref].trim() == "") {
        this.message.error(`Please fill tag name ( ${this.lang[i].name} )`, { nzDuration: this.message_duration });
        return false;
      }
    }
    this.model.category_attribute_id = this.selected_category_attribute.map(x => x.id);
    try{
      await this.Service_Product.createProductCategory(this.model);
      this.modal.close({ data: true });
    }catch(e){
      console.log(e);
      this.message.error(e.error.message, { nzDuration: this.message_duration });
      return false;
    } 

  }
}
