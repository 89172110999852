import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KetHasDataDomPipe } from './has-data-dom.pipe';

@NgModule({
  declarations: [
    KetHasDataDomPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    KetHasDataDomPipe
  ]
})
export class KetHasDataDomModule { }