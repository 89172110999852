import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { cloneDeep } from 'lodash';
import { ProductsService } from '@cms/services/products.service';
import { ExtensionService } from '@cms/services/extension.service';
import { SettingService } from '@cms/services/setting.service';
import { ModalDeleteShopeeProductComponent } from '../modal-delete-shopee-product/modal-delete-shopee-product.component';

declare var alasql: any

@Component({
    selector: 'app-modal-edit-shopee-product',
    templateUrl: './modal-edit-shopee-product.component.html',
    styleUrls: ['./modal-edit-shopee-product.component.css']
})
export class ModalEditShopeeProductComponent implements OnInit {
    @Input() data: any;
    constructor(
        private modal: NzModalRef,
        private modalService: NzModalService,
        private message: NzMessageService,
        private Service_Product: ProductsService,
        private Service_Extension: ExtensionService,
        private Service_Setting: SettingService
    ) { }
    message_duration: any = 3000;
    product: any = [];
    total = 0;

    category: any = [];
    logistic: any = [];
    attribute: any = [];
    category_level1: any = [];
    category_level2: any = [];
    category_level3: any = [];
    category_level1_selected: any = [];
    category_level2_selected: any = [];
    category_level3_selected: any = [];
    require_attribute: any = [];
    logistic_selected: any = [];
    step: number = 2;
    prop_enable = 0;
    available_stock: number = 0;
    statusname: any = {
        'NORMAL': 'ปกติ',
        'UNLIST': 'ไม่แสดง',
        'BANNED': 'ถูกระงับ'
    }

    ket_product_id = null;
    /* SHOPEE MODEL */
    variation = {
        tier_variation: [],
        variation: []
    }

    _images: any = [
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" },
        { url: "" }
    ]

    model: any = {
        "category_id": "",
        "name": "", // >= 20 character
        "description": "", // > 25 character
        "price": 0, // number
        "stock": 0, // number
        "item_sku": "",
        "images": [

        ], // Max size: 2MB
        "attributes": [],
        "logistics": [],
        "weight": 0, // kg
        "package_length": 0, // cm
        "package_width": 0, // cm
        "package_height": 0, // cm
        "days_to_ship": 3,
        "size_chart": "",
        "condition": "NEW/USED",
        "status": "NORMAL"
    }
    status_pd = ""
    domain = location.origin;
    option: any = {

    }

    async ngOnInit() {
        $('.theme-loader').fadeIn();
        // this.search();
        // console.log(this.data.item);
        try {
            let pop_key = ['vat_setting'];
            let _option: any = await this.Service_Setting.searchOption({ pop_key: pop_key });

            _option.forEach(obj => {
                this.option[obj.pop_key] = obj.pop_value;
            });
            let _model = await this.Service_Extension.getProductShopeeByID(this.data.item.id);
            $('.theme-loader').fadeOut();
            this.initModel(_model);
        } catch (e) {
            this.modalService.error({
                nzTitle: 'Error',
                nzContent: e.error.message,
                nzOnOk: () => {
                }
            });
            $('.theme-loader').fadeOut();
        }


        // this.Service_Extension.getShopeeItemAttribute({ category_id: this.model.category_id }).then((data: any) => {
        //     this.attribute = data.attributes;
        //     this.require_attribute = this.attribute;
        //     this.require_attribute.forEach(obj => {
        //         this.model.attributes.push({
        //             attributes_id: obj.attribute_id,
        //             value: ""
        //         });
        //     });
        // }).catch((data) => {
        //     console.log(data);
        // });
    }

    delete() {
        console.log(this.model);
        const modal = this.modalService.create({
            nzTitle: 'ลบสินค้า Shopee',
            nzContent: ModalDeleteShopeeProductComponent,
            nzStyle: { top: '20px' },
            nzWidth: "30%",
            nzClassName: 'modal-medium',
            nzKeyboard: false,
            nzMaskClosable: false,
            nzFooter: null,
            nzOnOk: (componentInstance) => {
                return componentInstance.ok();
            },
            nzOnCancel: (componentInstance) => {

            },
            nzComponentParams: {
                data: {
                    item: this.model
                }
            }
        }).afterClose.subscribe((result) => {
            modal.unsubscribe()
            if (result) {
                this.modal.close();
            }
        });
        // this.Service_Extension.deleteProductShopee(item).then((res) => {
        // });
    }

    trackByIdx(index: number, obj: any): any {
        return index;
    }

    calculateSalePrice() {
        if (this.prop_enable == 0) {
            let vatval = this.model.original_price * (+this.option.vat_setting * 0.01);
            this.model.vat = Math.round((+vatval) * 100) / 100;
            this.model.price = Math.round(this.model.original_price + this.model.vat);
        } else {
            for (var i = 0; i < this.variation.variation.length; i++) {
                this.variation.variation[i].original_price = +this.variation.variation[i].original_price;
                let vatval = (+this.variation.variation[i].original_price) * (+this.option.vat_setting * 0.01);
                this.variation.variation[i].vat = Math.round((+vatval) * 100) / 100;
                this.variation.variation[i].price = Math.round(this.variation.variation[i].original_price + this.variation.variation[i].vat);
            }
        }
    }


    async initModel(data) {

        let _ketshopdata = data.ketshop;
        this.model.item_id = _ketshopdata.item_id;
        this.model.category_id = _ketshopdata.category_id;
        this.model.name = _ketshopdata.name;
        this.model.description = _ketshopdata.description;
        this.model.prop_enable = _ketshopdata.prop_enable;
        this.model.weight = _ketshopdata.weight;
        this.prop_enable = this.model.prop_enable;
        this.model.logistics = _ketshopdata.logistics;
        this.model.attributes = _ketshopdata.attributes;
        this.model.package_height = _ketshopdata.package_height;
        this.model.package_length = _ketshopdata.package_length;
        this.model.package_width = _ketshopdata.package_width;
        this.model.days_to_ship = _ketshopdata.days_to_ship;

        this.status_pd = _ketshopdata.status;

        for (var i = 0; i < _ketshopdata.images.length; i++) {
            this._images[i].url = _ketshopdata.images[i].url;
        }
        if (this.model.prop_enable == 0) {

            this.model.item_sku = _ketshopdata.variation[0].sku;
            this.model.original_price = _ketshopdata.variation[0].original_price;
            this.model.vat = _ketshopdata.variation[0].vat;
            this.model.vat_rate = _ketshopdata.variation[0].vat_rate;
            this.model.price = _ketshopdata.variation[0].price;
            this.model.stock = _ketshopdata.variation[0].stock;

            this.available_stock = _ketshopdata.variation[0].product.instock + this.model.stock;

        } else if (this.model.prop_enable == 1) {

            this.variation.tier_variation.push({ name: _ketshopdata.tier_variation_name, options: [] });
            _ketshopdata.variation.forEach(_obj => {
                this.variation.tier_variation[0].options.push(_obj.variation_name);
            });

            for (let i = 0; i < _ketshopdata.variation.length; i++) {
                let _current = _ketshopdata.variation[i];
                this.variation.variation.push({
                    tier_index: _current.tier_index,
                    stock: _current.stock,
                    available_stock: _current.product.instock + _current.stock,
                    price: _current.price,
                    original_price: _current.original_price,
                    vat: _current.vat,
                    vat_rate: _current.vat_rate,
                    variation_sku: _current.sku,
                    in_ketshop: true,
                    ket_product_id: _current.product_id,
                    image: _current.image
                });

            }

        } else if (this.model.prop_enable == 2) {
            this.variation.tier_variation.push({ name: _ketshopdata.tier_variation_name, options: [] });
            let _option1 = alasql("SELECT variation_name FROM ? GROUP BY variation_name ORDER BY tier_index", [_ketshopdata.variation]);
            console.log(_option1);
            _option1.forEach(_obj => {
                this.variation.tier_variation[0].options.push(_obj.variation_name);
            });
            this.variation.tier_variation.push({ name: _ketshopdata.tier_variation_name2, options: [] });
            let _option2 = alasql("SELECT variation_name2 FROM ? GROUP BY variation_name2 ORDER BY tier_index", [_ketshopdata.variation]);
            _option2.forEach(_obj => {
                this.variation.tier_variation[1].options.push(_obj.variation_name2);
            });

            for (let i = 0; i < _ketshopdata.variation.length; i++) {
                let _current = _ketshopdata.variation[i];
                let inketshop = (!_current.product_id) ? false : true;
                this.variation.variation.push({
                    tier_index: _current.tier_index,
                    stock: _current.stock,
                    available_stock: (inketshop) ? _current.product.instock + _current.stock : 0,
                    price: _current.price,
                    variation_sku: _current.sku,
                    original_price: _current.original_price,
                    vat: _current.vat,
                    vat_rate: _current.vat_rate,
                    in_ketshop: inketshop,
                    ket_product_id: _current.product_id,
                    image: _current.image
                });

            }
            console.log(this.variation);
        }

        let _attr: any = await this.Service_Extension.getShopeeItemAttribute({ category_id: this.model.category_id });
        this.require_attribute = _attr.attributes;
        let _model_attr = [];
        this.require_attribute.forEach(attr => {
            let chk_attr = this.model.attributes.find(x => x.attributes_id == attr.attribute_id);
            if (!chk_attr) {
                let model_attr:any = {
                    attributes_id: attr.attribute_id,
                    value: ""
                }
                try {
                    if (attr.format_type == 'QUANTITATIVE') {
                        model_attr.value_tmp = "";
                        model_attr.value_unit = attr.unit_list[0];
                    }
                } catch (e) { }

                _model_attr.push(model_attr);
            } else {
                let model_attr: any = {
                    attributes_id: chk_attr.attributes_id,
                    value: chk_attr.value
                }
                try {
                    if (attr.format_type == 'QUANTITATIVE') {
                        var unit = chk_attr.value.replace(/(\d+)/g, '');
                        var select_unit = attr.unit_list.find(x=>x== unit);
                        if (select_unit){
                            model_attr.value_unit = select_unit;
                        }else{
                            model_attr.value_unit = attr.unit_list[0];
                        }
                        let reg = new RegExp(unit);
                        var val = chk_attr.value.replace(reg, '');
                        model_attr.value_tmp = val
                    }
                } catch (e) { }
                _model_attr.push(model_attr);
            }
        });
        this.model.attributes = _model_attr;
        console.log(_model_attr);
        let _logistic: any = await this.Service_Extension.getShopeeLogistic();
        this.logistic = [];
        _logistic.logistics.forEach(obj => {
            obj.state = "normal";
            obj.tmp_fee = 0;
            if (obj.enabled) {
                obj.selected = false;
                obj.shipping_fee = 0;
                this.model.logistics.forEach(_o => {
                    if (_o.logistic_id == obj.logistic_id) {
                        if (_o.enabled) {
                            obj.selected = true
                        }
                        obj.shipping_fee = _o.shipping_fee;
                    }
                });

                this.logistic.push(obj);
            }
        });
        // if (data.has_variation){
        //     // have property

        // }else{

        // }


    }

    selectCategory(obj, level) {
        if (!obj.has_children) {
            this.model.category_id = obj.category_id;
        } else {
            this.model.category_id = "";
        }
        switch (level) {
            case 1:
                this.category_level1_selected = obj;
                this.category_level2_selected = null;
                this.category_level3_selected = null;

                if (obj.has_children) {
                    this.category_level2 = alasql('SELECT * FROM ? WHERE parent_id = ?', [this.category, obj.category_id]);
                }
                this.category_level3 = [];
                break;
            case 2:
                this.category_level2_selected = obj;
                this.category_level3_selected = null;
                if (obj.has_children) {
                    this.category_level3 = alasql('SELECT * FROM ? WHERE parent_id = ?', [this.category, obj.category_id]);
                }

                break;
            case 3:
                this.category_level3_selected = obj;
                break;

            default:
                break;
        }
    }

    ok() {

        this.model.logistics = [];
        this.model.images = [];
        this._images.forEach(_img => {
            if (_img.url) {
                this.model.images.push({ url: _img.url });
            }
        });
        this.model.attributes.forEach(obj => {
            try {
                if (obj.hasOwnProperty("value_unit")) {
                    if (obj.value_tmp) {
                        obj.value = `${obj.value_tmp}${obj.value_unit}`;
                    } else {
                        obj.value = `0${obj.value_unit}`;
                    }
                }
            } catch (e) { }
        });
     
        this.logistic.forEach(obj => {

            if (obj.selected) {
                obj.enable = true;
            } else {
                obj.enable = false;
            }
            this.model.logistics.push({
                "logistic_id": obj.logistic_id,
                "enabled": obj.enable,
                "shipping_fee": obj.shipping_fee
            })
        });
        let obj = {
            item_id: this.model.item_id,
            item: cloneDeep(this.model),
            variation: this.variation
        }
        obj.item.attributes = obj.item.attributes.filter(x=>x.value !== "");
        
        $('.theme-loader').fadeIn();
        this.Service_Extension.updateProductShopee(obj).then((res) => {
            $('.theme-loader').fadeOut();
            this.modalService.success({
                nzTitle: 'Update item success',
                nzContent: ''
            });
            this.modal.close();
        }).catch((data) => {
            this.modalService.error({
                nzTitle: 'Error',
                nzContent: data.error.message,
                nzOnOk: () => {
                }
            });
            $('.theme-loader').fadeOut();
        });

    }


}