export const RotationType_WHD = 0;
export const RotationType_HWD = 1;
export const RotationType_HDW = 2;
export const RotationType_DHW = 3;
export const RotationType_DWH = 4;
export const RotationType_WDH = 5;

export const WidthAxis = 0;
export const HeightAxis = 1;
export const DepthAxis = 2;

export const StartPosition = [0, 0, 0];

export const RotationTypeStrings = {
  [RotationType_WHD]: 'RotationType_WHD (w,h,d)',
  [RotationType_HWD]: 'RotationType_HWD (h,w,d)',
  [RotationType_HDW]: 'RotationType_HDW (h,d,w)',
  [RotationType_DHW]: 'RotationType_DHW (d,h,w)',
  [RotationType_DWH]: 'RotationType_DWH (d,w,h)',
  [RotationType_WDH]: 'RotationType_WDH (w,d,h)',
};

export class Item {

  name = '';
  width = 0;
  height = 0;
  depth = 0;
  weight = 0;

  rotationType = RotationType_WHD;
  position = []; // x, y, z

  constructor(name, w, h, d, wg) {
    this.name = name;
    this.width = w || 1;
    this.height = h || 1;
    this.depth = d || 1;
    this.weight = wg;
  }

  getWidth() {
    return this.width //Math.ceil(this.width);
  }

  getHeight() {
    return this.height //Math.ceil(this.height);
  }

  getDepth() {
    return this.depth //Math.ceil(this.depth);
  }

  getWeight() {
    return this.weight //Math.ceil(this.weight);
  }

  getRotationType() {
    return this.rotationType;
  }

  getRotationTypeString() {
    return RotationTypeStrings[this.getRotationType()];
  }

  getDimension() {
    let d;
    switch (this.rotationType) {
      case RotationType_WHD:
        d = [this.getWidth(), this.getHeight(), this.getDepth()];
        break;
      case RotationType_HWD:
        d = [this.getHeight(), this.getWidth(), this.getDepth()];
        break;
      case RotationType_HDW:
        d = [this.getHeight(), this.getDepth(), this.getWidth()];
        break;
      case RotationType_DHW:
        d = [this.getDepth(), this.getHeight(), this.getWidth()];
        break;
      case RotationType_DWH:
        d = [this.getDepth(), this.getWidth(), this.getHeight()];
        break;
      case RotationType_WDH:
        d = [this.getWidth(), this.getDepth(), this.getHeight()];
        break;
    }
    return d;
  }

  intersect(i2) {
    return rectIntersect(this, i2, WidthAxis, HeightAxis) &&
        rectIntersect(this, i2, HeightAxis, DepthAxis) &&
        rectIntersect(this, i2, WidthAxis, DepthAxis);
  }

  getVolume() {
    return Number((this.getWidth()+this.getHeight()+this.getDepth()).toFixed(2))
    //this.getWidth() * this.getHeight() * this.getDepth();
  }
}

export const rectIntersect = (i1, i2, x, y) => {
  let d1, d2, cx1, cy1, cx2, cy2, ix, iy;

  d1 = i1.getDimension();
  d2 = i2.getDimension();

  cx1 = i1.position[x] + d1[x] / 2;
  cy1 = i1.position[y] + d1[y] / 2;
  cx2 = i2.position[x] + d2[x] / 2;
  cy2 = i2.position[y] + d2[y] / 2;

  ix = Math.max(cx1, cx2) - Math.min(cx1, cx2);
  iy = Math.max(cy1, cy2) - Math.min(cy1, cy2);
  // console.log(ix, iy, d1, d2)
  // console.log('d1', d1)
  // console.log('d2', d2)

  // console.log('cx1', cx1)
  // console.log('cy1', cy1)
  // console.log('cx2', cx2)
  // console.log('cy2', cy2)
  let rs = ix < (d1[x] + d2[x]) / 2 && iy < (d1[y] + d2[y]) / 2;
  // console.log('rs', rs)
  return rs;
};