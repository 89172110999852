import {
    Directive,
    ElementRef,
    AfterViewInit,
    Renderer
  } from '@angular/core';

  @Directive({
    selector: '[venobox]'
  })
  export class KetVenoboxDirective implements AfterViewInit{
    constructor(private el: ElementRef, private render: Renderer) {}

    ngAfterViewInit(){
        $(this.el.nativeElement)['venobox']({
            framewidth: '95%',        // default: ''
            frameheight: (window.innerHeight-100)+'px',       // default: ''
            border: '0px'            // default: '0'
        });

    }

  }
