import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ExtensionService } from '@cms/services/extension.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { OrderService } from '@cms/services/order.service';
import {cloneDeep} from 'lodash';
import { Etc } from '@cms/util/etc';
import * as moment from 'moment';
declare var navigator: any

@Component({
  selector: 'app-extension-peak',
  templateUrl: './peak.component.html',
  styleUrls: ['./peak.component.css']
})
export class PeakComponent implements OnInit {
  @Output() back = new EventEmitter();
  constructor(
    private Service_Extension: ExtensionService,
    private message: NzMessageService,
    private modal: NzModalService,
    private Service_Order: OrderService,
    private _etc: Etc,
    private drawerRef: NzDrawerRef<string>
  ) { }
  tabselected: any = 'desc';
  model: any = {};
  message_duration: number = 4000;
  _temp_option:any;
  _temp_custom1:any;
  allcreateby:any = [];
  modelsearch: any = {
    startdate: "",
    enddate: "",
    status: "",
    ordercode: "",
    email: "",
    trackcode: "",
    search: "",
    paymenttype: "",
    created_by: "",
    page: 1,
    perpage: 10,
    peak: true,
    peak_status: "1"
  }
  statusimg:any = [];
  usernameSearch:any='';
  paymentimg:any = [];
  loading:any = false;
  order:any = [];
  total:any = 0;
  async ngOnInit() {
    this.statusimg = this._etc.orderstatus_img();
    this.paymentimg = this._etc.payment_img();
    this.allcreateby = await this.Service_Order.getcreatedby();
    this.Service_Extension.search({ name: 'Peak' }).then((res: any) => {
      console.log(res);
      this.model = res;
      this._temp_option = (this.model.option != null)? this.model.option: "2";
      this._temp_custom1 = (this.model.custom1 != null) ? cloneDeep(this.model.custom1) : { cod: "include"};
    }).catch(data => {

    });

    window['_check_extenion']()

  }
  selectCustomer(obj: any) {
    console.log(obj)

    this.usernameSearch = `${obj.name} ${obj.lastname}`
    this.modelsearch.search = `${obj.username}`
    this.search();
  }
  resend(obj){

  }

  onSearchChange(e: any) {
    this.usernameSearch = `${e}`

    if (`${e}` == "") {
      this.modelsearch.search = ""
      this.search();
    }
  }
  search() {
    /*
    this.loading = true;
    this.Service_Order.search(this.modelsearch).then((res: any) => {
      console.log(res);
      this.order = res.data;
      this.total = res.count;
    }).catch((data) => {
      console.log(data.error.message);
    }).finally(() => {
      this.loading = false;
    });
    // console.log(this.rangePicker);
    */
  }
  pagechage(page) {
    this.modelsearch.page = page;
    this.search();
  }
  setSelected(tab) {
    this.tabselected = tab;
  }
  copylink(key) {
    // this.model.connected = 1;
    try {
      navigator.clipboard.writeText(key).then((res: any) => {
        this.message.success('copy success', { nzDuration: this.message_duration });
      });
    } catch (err) { }
  }
  change_key() {
    this.model.connected = 0;

  }
  delete_key(){
    this.modal.confirm({
      nzTitle: `Do you want to disconnect from peak`,
      nzContent: '',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => {
        $('.theme-loader').show();
        this.Service_Extension.disconnectPeak(this.model).then((res: any) => {
          $('.theme-loader').hide();
          this.ngOnInit();
        }).catch((data) => {
          this.modal.error({
            nzTitle: 'Error',
            nzContent: data.error.message
          });
          $('.theme-loader').hide();
        });
      },
      nzCancelText: 'No',
      nzOnCancel: () => { }
    });
  }

  save(){
    this.model.option = this._temp_option;
    this.model.custom1 = this._temp_custom1;
    this.connect();

  }
  connect(){
    // if (this.model.username == '' || this.model.username == undefined) {
    //   this.message.warning("Please fill Connect ID", { nzDuration: this.message_duration });
    //   return;
    // } else
    if (this.model.api_key == '' || this.model.api_key == undefined) {
      this.message.warning("Please fill Client Token");
      return;
    }
    // else if (this.model.api_secret == '' || this.model.api_secret == undefined) {
    //   this.message.warning("Please fill Secret Key", { nzDuration: this.message_duration });
    //   return;
    // }
    $('.theme-loader').fadeIn();
    this.Service_Extension.connectPeak(this.model).then((res:any)=>{
      this.ngOnInit();
      $('.theme-loader').fadeOut();
    }).catch((data)=>{
      $('.theme-loader').fadeOut();
      this.message.error(data.error.message);
    });
  }
  _back() {
    this.drawerRef.close();
  }

}
