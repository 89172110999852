import { Component, OnInit, AfterViewInit } from '@angular/core';
import { cloneDeep, merge } from 'lodash'
import { SettingService } from '@cms/services/setting.service';
import { apiError } from '@cms/interface/interface';
import { Router } from '@angular/router';
import { TranslateService } from '@cms/services/translate.service';
@Component({
    selector: 'app-button-setting',
    templateUrl: './button-setting.component.html',
    styleUrls: ['./button-setting.component.css']
})
export class ButtonSettingComponent implements OnInit, AfterViewInit {
    options: any
    button:any = {
        backgroundcolor: "#525e68",
        backgroundcolorhover: "#fff",
        border: "2",
        bordercolor: "#525e68",
        bordercolorhover: "#525e68",
        borderhover: "2",
        borderradius: "9",
        borderradiushover: "9",
        fontcolor: "#fff",
        fontcolorhover: "#525e68"
    }
    ecommerce:any = {
        backtotopbackgroundcolor: "#fac769",
        backtotopfontcolor: "#a66900",
        backtotophover: "#fac769",
        cartbuttoncolor: "#fac769",
        cartbuttonhover: "#fac769",
        menucarthover: "#cccccc",
        cartbuttonfontcolor: "#fff",
        cartbuttonbgfontcolor: "#000"
    }
    image_cart_model: string = '/upload-img/Theme/toy-bag.png'
    whatshelp_button: any
    btn_back_to_top_status: boolean = false
    btn_back_to_top_transparent: boolean = false
    btn_cart_status: boolean = false
    btn_cart_transparent: boolean = false
    _theme_color: any
    color_primary:any
    color_data: any[] = [
        {
            name: "Gray",
            value: "#656665"
        },
        {
            name: "Orange",
            value: "#FF6550"
        },
        {
            name: "Red",
            value: "#E74339"
        },
        {
            name: "Green",
            value: "#A8CE50"
        },
        {
            name: "Blue",
            value: "#129BF4"
        },
        {
            name: "Purple",
            value: "#932C8B"
        },
        {
            name: "Pink",
            value: "#FF318E"
        },
        {
            name: "Black",
            value: "#000000"
        }
    ]

    constructor(
        private _router: Router,
        private _setting: SettingService,
        private _translate: TranslateService
    ) { }

    ngOnInit() {
    }


    ngAfterViewInit(){
        // console.log(window["_theme_color"])
       setTimeout(()=>{
            try{
                this.button = merge(this.button, cloneDeep(window["_theme_color"]["button"]))
                this.ecommerce = merge(this.ecommerce, cloneDeep(window["_theme_color"]["ecommerce"]))
            }catch(err){}
       },100)
        //console.log(this.button)

        this._setting.searchOption({
            pop_key: [
                'btn_back_to_top_status',
                'btn_back_to_top_transparent',
                'bag_icon',
                'btn_cart_status',
                'btn_cart_transparent',
                'whatshelp_button',
                'theme_value'

            ]
        }).then((res: any[])=>{
            this.options = res
            for(let obj of res){
                switch (obj.pop_key) {
                    case 'bag_icon':
                        this.image_cart_model = obj.pop_value
                        break;
                    case 'whatshelp_button':
                        // this.whatshelp_button = `${obj.pop_value}`.replace(/\\/g, '')
                        this.whatshelp_button = obj.pop_value;
                        this.whatshelp_button = JSON.parse(this.whatshelp_button)
                        let messenger = this.whatshelp_button.data.find(x=>x.name == "Messenger")
                        messenger.placeholder = "92363196434xxxx"
                        messenger.example = "92363196434xxxx (Page ID)"
                        break;
                    case 'btn_back_to_top_status':
                        this.btn_back_to_top_status = Number(obj.pop_value) == 1? true:false
                        break;
                    case 'btn_back_to_top_transparent':
                        this.btn_back_to_top_transparent = Number(obj.pop_value) == 1? true:false
                    case 'btn_cart_status':
                        this.btn_cart_status = Number(obj.pop_value) == 1? true:false
                        break;
                    case 'btn_cart_transparent':
                        this.btn_cart_transparent = Number(obj.pop_value) == 1? true:false
                        break;
                    case 'theme_value':
                        this._theme_color = obj.pop_value

                        try {

                            var _data = JSON.parse(obj.pop_value)
                            console.log(_data.color_primary);
                            window["_theme_color"] = _data
                            this.color_primary = _data.color_primary
                        } catch (e) {

                            eval(`${obj.pop_value} window._theme_color = _theme_color;`)

                        }
                        setTimeout(()=>{
                            try{
                                this.button = merge(this.button, cloneDeep(window["_theme_color"]["button"]))
                                this.ecommerce = merge(this.ecommerce, cloneDeep(window["_theme_color"]["ecommerce"]))
                            }catch(err){}
                       },100)
                        break;
                    default:
                        break;
                }
            }
            this.txtConvert();
            console.log(this.whatshelp_button)

        }).catch((e: apiError)=>{
            console.log(e.message)
        })
    }

    txtConvert() {
        let converted = this._translate.thaiUnicodeDecoder(this.whatshelp_button.config.call_to_action);
        try{
            this.whatshelp_button.config.call_to_action = converted;
        } catch(err) {
            console.log(err);
        }
    }
 

    txtchange(){
        try{
            this.whatshelp_button.config.greeting_message = this.whatshelp_button.config.greeting_message.replace(/\"/g,'');
        }catch(e){}
    }

    genStyle(){
        var s: string = `
            <style>
                .tmt-btn-all{
                  background-color: ${this.button.backgroundcolor};
                  color:  ${this.button.fontcolor};
                  border: solid  ${this.button.border}px;
                  border-color:  ${this.button.bordercolor};
                  border-radius: ${this.button.borderradius}px;
                }
                .tmt-btn-all:hover{
                  background-color: ${this.button.backgroundcolorhover};
                  color:  ${this.button.fontcolorhover};
                  border: solid  ${this.button.borderhover}px;
                  border-color:  ${this.button.bordercolorhover};
                  border-radius: ${this.button.borderradiushover}px;
                }

                .tmt-btn-all:focus{
                  background-color: ${this.button.backgroundcolor};
                  color:  ${this.button.fontcolor};
                  border: solid  ${this.button.border}px;
                  border-color:  ${this.button.bordercolor};
                  border-radius: ${this.button.borderradius}px;
                }

                .tmt-btn-all.hover{
                  background-color: ${this.button.backgroundcolorhover};
                  color:  ${this.button.fontcolorhover};
                  border: solid  ${this.button.borderhover}px;
                  border-color:  ${this.button.bordercolorhover};
                  border-radius: ${this.button.borderradiushover}px;
                }

                .static-bag{
                    background-color: ${this.ecommerce.cartbuttoncolor};
                }
                .static-bag:hover{
                    background-color: ${this.ecommerce.cartbuttonhover};
                }
                .static-top{
                    background-color: ${this.ecommerce.backtotopbackgroundcolor};
                }
                .static-top i{
                    color: ${this.ecommerce.backtotopfontcolor};
                }
                .static-top:hover{
                    background-color: ${this.ecommerce.backtotophover};
                }

                .static-bag .number-on-bag-float{
                  background-color: ${this.ecommerce.cartbuttonbgfontcolor};
                  color: ${this.ecommerce.cartbuttonfontcolor};
                }

            </style>
        `

        return s
    }


    btnCheckStatus(obj: any){
        if(obj.status == 1){
            obj.status = 0
        }else{
            obj.status = 1
        }
    }

    whatshelp_button_logo(e:any){

      var domain = ""
      if(!window["_host"]){
        domain = window.location.origin
      }else{
        domain = `https://${window["_host"]}`
      }

        this.whatshelp_button.config.logo = `${domain}${e}`
        console.log(this.whatshelp_button.config.logo)
    }

    save(){
        for(let obj of this.options){
            switch (obj.pop_key) {
                case 'bag_icon':
                    obj.pop_value = this.image_cart_model
                    break;
                case 'whatshelp_button':
                    obj.pop_value = JSON.stringify(this.whatshelp_button)
                    break;
                case 'btn_back_to_top_status':
                    //console.log(this.btn_back_to_top_status)
                    obj.pop_value = this.btn_back_to_top_status? '1' : '0'
                    break;
                case 'btn_back_to_top_transparent':
                    //console.log(this.btn_back_to_top_transparent)
                    obj.pop_value = this.btn_back_to_top_transparent? '1' : '0'
                    //console.log(obj.pop_value);
                    break;
                case 'btn_cart_status':
                    //console.log(this.btn_cart_status)
                    obj.pop_value = this.btn_cart_status? '1' : '0'
                    break;
                case 'btn_cart_transparent':
                    //console.log(this.btn_cart_transparent)
                    obj.pop_value = this.btn_cart_transparent? '1' : '0'
                    break;
                case 'theme_value':
                    window["_theme_color"]["button"] = this.button
                    window["_theme_color"]["ecommerce"] = this.ecommerce
                    obj.pop_value = JSON.stringify(window["_theme_color"])
                    break;
                default:
                    break;
            }
        }
        $('.theme-loader').fadeIn();
        this._setting.saveOption(this.options).then((res:any)=>{
            var url = decodeURI(this._router.url);
            this._router.navigated = false;
            this._router.navigate([`/system`]).then(()=>this._router.navigate([url]));
        }).catch((e: apiError)=>{
            console.log(e.error.message)
        }).finally(()=>{
            $('.theme-loader').fadeOut();
        })
    }


}
