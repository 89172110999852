import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { cloneDeep } from 'lodash';
import { ExtensionService } from '@cms/services/extension.service';

declare var alasql: any

@Component({
  selector: 'app-modal-get-lazada-order',
  templateUrl: './modal-get-lazada-order.component.html',
  styleUrls: ['./modal-get-lazada-order.component.css']
})
export class ModalGetLazadaOrderComponent implements OnInit {
  @Input() data: any;

  constructor(
    private Service_Extension: ExtensionService,
    private modalService: NzModalService,
    private modalRef: NzModalRef
  ) { }

  validateOrderId:boolean = false

  model:any = {
    order_id: ""
  }
  ngOnInit() {

  }

  ok() {

    console.log(this.model.order_id);
    if (!this.model.order_id){
      this.validateOrderId = true
      // this.modalService.error({
      //   nzTitle: "กรุณากรอกหมายเลขออเดอร์ Lazada"
      // });
      return false;
    }
    $('.theme-loader').fadeIn();
    this.Service_Extension.addOrderLazadaToQueue(this.model).then((res:any)=>{
      $('.theme-loader').fadeOut();
      this.modalService.success({
        nzTitle: 'อยู่ระหว่างดำเนินการดึงข้อมูล จาก Lazada',
        nzContent: 'ขณะนี้ระบบกำลังดำเนินการดึงข้อมูล จาก Lazada ซึ่งจะทำการดึงข้อมูลสำเร็จภายใน 5 นาที',
        nzOkText: 'ตกลง',
        nzWrapClassName: 'vertical-center-modal',
      });
      this.modalRef.close(true);
    }).catch((data)=>{
      $('.theme-loader').fadeOut();
      this.modalService.error({
        nzTitle: data.error.message
      });
    });
   
 

    return false;
  }

  close(){
    this.modalRef.close(true);
  }
}