import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonSettingRoutingModule } from './button-setting-routing.module';
import { ButtonSettingComponent } from './button-setting.component';
import { FormsModule } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { KetSanitizeHtmlModule } from '@ketshopweb/ui/sanitize-html';
import { KetPermissionModule } from '@ketshopweb/ui/permission';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ColorPickerModule } from 'ngx-color-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { KetFilemanagerModule } from '@ketshopweb/ui/filemanager';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [
    ButtonSettingComponent
  ],
  imports: [
    CommonModule,
    ButtonSettingRoutingModule,
    FormsModule,
    NzGridModule,
    KetSanitizeHtmlModule,
    KetPermissionModule,
    NzButtonModule,
    NzTabsModule,
    NzInputModule,
    ColorPickerModule,
    NzInputNumberModule,
    NzSwitchModule,
    KetFilemanagerModule,
    NzRadioModule,
    NzSelectModule,
    NzAlertModule,
    NzToolTipModule
  ]
})
export class ButtonSettingModule { }
