import { PackerBox } from './Packer'
import { Bin } from './Bin'
import { Item }  from './Item'
let box_sizes:any[] = []

export class Packer{
    
    constructor( ){}

  boxs = [
    { type: "static", name: "ไปรษณีย์ ก. (14x20x6 cm)", value: "14x20x6", "width": 14, "length": 20, "height": 6 },
    { type: "static", name: "ไปรษณีย์ ข. (17x25x9 cm)", value: "17x25x9", "width": 17, "length": 25, "height": 9 },
    { type: "static", name: "ไปรษณีย์ ค. (20x30x11 cm)", value: "20x30x11", "width": 20, "length": 30, "height": 11 },
    { type: "static", name: "ไปรษณีย์ ง. (22x35x14 cm)", value: "22x35x14", "width": 22, "length": 35, "height": 14 },
    { type: "static", name: "ไปรษณีย์ จ. (24x40x17 cm)", value: "24x40x17", "width": 24, "length": 40, "height": 17 },
    { type: "static", name: "ไปรษณีย์ ฉ. (30x45x20 cm)", value: "30x45x20", "width": 30, "length": 45, "height": 20 },
    { type: "static", name: "กล่องเบอร์ 0 (11x17x6 cm)", value: "11x17x6","width": 11, "length": 17, "height": 6 },
    { type: "static", name: "กล่องเบอร์ 1 (30x100x30 cm)", value: "30x100x30","width": 30, "length": 100, "height": 30 },
    { type: "static", name: "กล่องเบอร์ 2 (31x36x13 cm)", value: "31x36x13","width": 31, "length": 36, "height": 13 },
    { type: "static", name: "กล่องเบอร์ 3 (31x36x26 cm)", value: "31x36x26","width": 31, "length": 36, "height": 26 },
    { type: "static", name: "กล่องเบอร์ 4 (55x100x55 cm)", value: "55x100x55","width": 55, "length": 100, "height": 55 },
    { type: "static", name: "กล่องเบอร์ 5 (40x45x35 cm)", value: "40x45x35","width": 40, "length": 45, "height": 35 },
    { type: "static", name: "กล่องเบอร์ 6 (45x55x40 cm)", value: "45x55x40","width": 45, "length": 55, "height": 40 },
    { type: "static", name: "ขนาดพิเศษ 1 (50x15x6 cm)", value: "50x15x6","width": 50, "length": 15, "height": 6 },
    { type: "static", name: "ขนาดพิเศษ 2 (50x15x20 cm)", value: "50x15x20","width": 50, "length": 15, "height": 20 },
    { type: "static", name: "Size 40 (18x12x10 cm)", value: "18x12x10", "width": 18, "length": 12, "height": 10 },
    { type: "static", name: "Size 50 (15x24x11 cm)", value: "15x24x11", "width": 15, "length": 24, "height": 11 },
    { type: "static", name: "Size 60 (27x19.5x13 cm)", value: "27x19.5x13", "width": 27, "length": 19.5, "height": 13 },
    { type: "static", name: "Size 80 (35x25x20 cm)", value: "35x25x20", "width": 35, "length": 25, "height": 20 },
    { type: "static", name: "Size 100 (42.6x30.7x25.8 cm)", value: "42.6x30.7x25.8", "width": 42.6, "length": 30, "height": 25.8 },
    { type: "static", name: "Size 120 (50x37x30 cm)", value: "50x37x30", "width": 50, "length": 37, "height": 30 }
  ]


   callPack(packer: any, timeout = 3000){
        return new Promise((resolve, reject) => {
            let timeset = setTimeout(() => {
                console.log('pack_false')
                resolve(false)
            },timeout)
            setTimeout(()=>{
                packer.pack()
                clearTimeout(timeset)
                console.log('pack_true')
                resolve(true)
            },10)
        })
   }

   getBox(details:any[] = [], box_sizes:any[] = []):Promise<any>{
    return new Promise(async (resolve, reject) => {
        let shippop_box:any[] = this.boxs
        shippop_box.map((o)=>{
            o['sum'] = o.width+o.length+o.height
            return o
        })
        shippop_box.sort((a:any,b:any)=>a.sum-b.sum)
        if(box_sizes.length > 0){
            shippop_box = box_sizes
            shippop_box.map((o)=>{
                o['sum'] = o.width+o.length+o.height
                return o
            })
            shippop_box.sort((a:any,b:any)=>a.sum-b.sum)
        }

        console.log('shippop_box', shippop_box)
        console.log('details', details)
        if(details.length > 200){
            console.log('details.length > 200', details.length)
            resolve(shippop_box[shippop_box.length-1])
            return
        }
        let product = []
        let i = 0
        for(let obj of details){
            product.push({
                "name": `product${i+1}`,
                "width": obj.size_width || 1,
                "height": obj.size_height || 1, 
                "length": obj.size_length || 1
            })
            i++
        }
    
        let available_boxs = []
        let sum_available_boxs:any[] = []
        for(let box of shippop_box){
            let packer = new PackerBox();
            let bin = new Bin(box.value, box.width, box.height, box.length, 1);
            packer.addBin(bin)
            for(let p of product){
                let item = new Item(p.name, p.width, p.height, p.length, 0);
                packer.addItem(item)
            }
            //packer.pack()
            let _pack = await this.callPack(packer)
            // console.log('_pack', _pack)
            if(!_pack){
                resolve(null)
                break;
            }
            for(let available_bin of packer.bins){
                if(available_bin.items.length == product.length){
                    available_bin['sum'] = (available_bin.width+available_bin.height+available_bin.depth)
                    available_boxs.push(available_bin)
                    sum_available_boxs.push(available_bin['sum'])
                }
            } 
            if(available_boxs.length > 0){
                break;
            }
        }
        let selected = null
        let min_box:any = {}
        // console.log('available_boxs', available_boxs)
        if(available_boxs.length > 0){
            min_box = available_boxs.find(x=>x.sum == Math.min(...sum_available_boxs))
            selected = shippop_box.find(x=>x.value==min_box.name)
            // console.log('box_select',min_box, selected)
            // console.log('min_box', min_box.items)
        }
        if(!selected){
            selected = shippop_box[shippop_box.length-1]
        }
        resolve(selected)
    })
  }

}