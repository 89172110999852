import { Component, OnInit, Input } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { ProductsService } from '@cms/services/products.service';
@Component({
  selector: 'ket-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.less']
})
export class KetProductFilterComponent implements OnInit {

  constructor(
    private nzDrawerRef: NzDrawerRef,
    protected Service_Product: ProductsService,
  ) { }

  page_name: any = "product";
  @Input() productTag: any[] = [];
  @Input() allcate: any = null;
  @Input() _model : any = null;

  @Input() priceRange : any = null;
  @Input() priceRangeValue : any = null;
  @Input() renderType : any = null;

  selected:any[] = []
  isUncateNull: boolean = false;
  _data_category_attribute = []

  async ngOnInit() {
    this.isUncateNull = await this.checkForUncate();
    this.selected = this._model.tags || []
    this.productTag.forEach(data => {
      data.active = false;
      this.selected.forEach(tag => {
         if(tag == data.id){
           data.active = true;
         }
       })
     });

    console.log(this.productTag)
  }

  search(){
    this.nzDrawerRef.close({
      key: 'search',
      value: ''
    })
  }

  selectCate(value:any) {
    this.nzDrawerRef.close({
      key: 'selectCate',
      value: value
    })
  }

  priceRangeChange(){
    // this.nzDrawerRef.close({
    //   key: 'filterPrice',
    //   value: this.priceRangeValue
    // })
  }

  clearAttr(attr) {
    // console.log(attr);
    attr.attribute_value.forEach(obj => {
      obj.checked = false;
    });
    let ind = this._model.category_attribute.findIndex(x => x.attr_id == attr.attr_id);
    if (ind != -1) {
      this._model.category_attribute.splice(ind, 1);
      // this._data = [];
      // this._search();
    }
  }
  attrCheckChange(attr, item) {
    if (item.checked) {
      let already = this._model.category_attribute.find(x => x.attr_id == item.attr_id);
      if (!already) {
        let model = {
          attr_id: item.attr_id,
          attr_name: attr.attr_name,
          selected_value: []
        }
        model.selected_value.push({
          id: item.id,
          attr_value: item.attr_value
        });
        this._model.category_attribute.push(model);
      } else {
        let chk = already.selected_value.find(x => x.id == item.id);
        if (!chk) {
          already.selected_value.push({
            id: item.id,
            attr_value: item.attr_value
          });
        }
      }
    } else {
      let already = this._model.category_attribute.find(x => x.attr_id == item.attr_id);
      if (already) {
        for (let i = 0; i < already.selected_value.length; i++) {
          if (already.selected_value[i].id == item.id) {
            already.selected_value.splice(i, 1);
          }
        }
        if (already.selected_value.length == 0) {
          let ind = this._model.category_attribute.findIndex(x => x.attr_id == item.attr_id);
          if (ind != -1) {
            this._model.category_attribute.splice(ind, 1);
          }
        }
      }

    }
  }

  tags(obj: any){
    if(obj.active){
      for(let i=0;i<this.selected.length;i++){
        if(this.selected[i] == obj.id){
          this.selected.splice(i,1);
        }
      }
    }else{
      this.selected.push(obj.id);
    }
    obj.active = !obj.active;
  }

  filter(){
    this.nzDrawerRef.close({
      key: 'filter',
      value: {
        priceRangeValue: this.priceRangeValue,
        tags: this.selected
      }
    })
  }

  async checkForUncate(): Promise<boolean>{
    const model = {
      view: 'grid',
      cate_id: -1,
      search: "",
      page: 1,
      perpage: 10,
      tags: [],
      sort: "3"
    }
    try {
      const uncateProducts: any = await this.Service_Product.searchProductIndex(model);
      if (uncateProducts.count) return false;
      return true;
    } catch (err) {
      console.log(err);
    }
  }

}