import { Component, OnInit } from '@angular/core';
import { UtilService } from '@cms/services/util.service';
import { DashboardService } from '@cms/services/dashboard.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TeamService } from '@cms/services/team.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { FeatureService } from '@cms/services/feature.service';

@Component({
    selector: 'app-report-team',
    templateUrl: './report-team.component.html',
    styleUrls: ['./report-team.component.css']
})
export class ReportTeamComponent implements OnInit {
    role:string = "";
    id:any =null;
    feature:boolean = false;
    constructor(
        private activatedRoute: ActivatedRoute, 
        private router: Router,
        private ServiceTeam: TeamService,
        private Service_Feature: FeatureService
    ){

    }

    async ngOnInit() {
        this.feature = await this.Service_Feature.getFeature("REPORT_STAFF");
      
        if (!this.feature) { return; }

        let id = +this.activatedRoute.snapshot.paramMap.get('id');
        if(id){
            let team:any = await this.ServiceTeam.getTeamById(id);
            console.log(team);
            this.role = team.user_role;
            this.id = team.id;

        }else{
            try{
                let _token = JSON.parse(localStorage.getItem('access_token'));
                var user = jwt_decode(_token.access_token);
                // console.log(user);
                this.role = user.user_role;
                this.id = user.id;
            }catch(e){
                this.router.navigate(['/system']);
            }
        }
        // this.router.navigate(['/system/e-commerce/product']);
      
    }

}