import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '@cms/services/util.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AuthenService } from '@cms/services/authen.service';

@Component({
  selector: 'app-modal-expire',
  templateUrl: './modal-expire.component.html',
  styleUrls: ['./modal-expire.component.css']
})
export class ModalExpireComponent implements OnInit {
  @Input() data: any;

  ngOnInit() {
  
  }

  ok(){
    
  }
}