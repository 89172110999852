import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tmtFilter',
    pure: false
})
export class TmtFilterPipe implements PipeTransform {
    transform(items: any[], key: string, key2: string = '', value: string = undefined): any {
        //console.log(key, key2, value)
        if (!items || !key) {
            return items;
        }
        if(value == undefined){
          return items.filter(item => item[key].indexOf(key2) !== -1);
        }else{
          return items.filter(item => item[key][key2].indexOf(value) !== -1);
        }
    }
}
