import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPromotion } from '@cms/types'

@Injectable({
    providedIn: 'root'
})

export class PromotionService {

    constructor(
        private _http: HttpClient
    ) { }

    updatePromotionFreeItem(data:any){
        return this._http.post('/promotion/updatePromotionFreeItem', data).toPromise();
    }

    deletePromotionFreeItem(id:any){
        return this._http.delete('/promotion/promotionFreeItem/' + id).toPromise();
    }

    getPromotionFreeItemByID(id: any) {
        return this._http.get('/promotion/promotionFreeItem/' + id).toPromise();
    }
    searchPromotionFreeItem(data:any){
        return this._http.post('/promotion/searchPromotionFreeItem', data).toPromise();
    }
    createPromotionFreeItem(data:any){
        return this._http.post('/promotion/createPromotionFreeItem',data).toPromise();
    }

    activePromotion(){
        return this._http.get('/promotion/activePromotion').toPromise();
    }
    getPromotion() {
        return this._http.get('/promotion').toPromise();
    }
    checkCoupon(obj: any) {
        return this._http.post('/promotion/checkCoupon', obj).toPromise();
    }
    changeCurrentPromotion(obj:any){
        return this._http.post('/promotion/changeCurrentPromotion',obj).toPromise();
    }

    searchCoupon(obj:any){
        return this._http.post('/promotion/searchCoupon',obj).toPromise();
    }

    searchCouponGroup(id: number, obj: any) {
        return this._http.post('/promotion/searchCouponGroup/' + id, obj).toPromise();
    }

    createCoupon(obj:any){
        return this._http.post('/promotion/coupon', obj).toPromise();
    }

    createCouponGroup(obj: any) {
        return this._http.post('/promotion/coupon/group', obj).toPromise();
    }

    updateCoupon(obj: any) {
        return this._http.put('/promotion/coupon/' + obj.id, obj).toPromise();
    }

    deleteCoupon(id){
        return this._http.delete('/promotion/coupon/' + id).toPromise();
    }

    searchDiscountCondition(obj:any){
        return this._http.post('/promotion/searchDiscountCondition',obj).toPromise();
    }

    createDiscountCondition(obj: any) {
        return this._http.post('/promotion/discountCondition', obj).toPromise();
    }

    updateDiscountCondition(obj: any){
        return this._http.put('/promotion/discountCondition/'+obj.id, obj).toPromise();
    }
    deleteDiscountCondition(id) {
        return this._http.delete('/promotion/discountCondition/' + id).toPromise();
    }

    getCouponByID(id){
        return this._http.get('/promotion/coupon/'+id).toPromise();
    }
    getConditionByID(id) {
        return this._http.get('/promotion/discountCondition/' + id).toPromise();
    }

    reportOverview(data){
        return this._http.post('/promotion/reportOverview',data).toPromise();
    }

    reportCustomerType(data){
        return this._http.post('/promotion/reportCustomerType', data).toPromise();
    }

    reportOrderChannel(data) {
        return this._http.post('/promotion/reportOrderChannel', data).toPromise();
    }

    reportBestSeller(data){
        return this._http.post('/promotion/reportBestSeller', data).toPromise();
    }

    reportOverviewOrder(data){
        return this._http.post('/promotion/reportOverviewOrder', data).toPromise();
    }

    getReportOrder(data){
        return this._http.post('/promotion/getReportOrder', data).toPromise();
    }

    // exportGroupCoupon(id: number) {
    //     return this._http.get(`/promotion/exportCouponCode/${id}`).toPromise();
    // }

    searchPromoWrapping(data: any): Promise<any>{
        return this._http.post('/promotion/searchPromotionWrapping', data).toPromise();
    }

    getPromoWrapping(id: any): Promise<any>{
        return this._http.get(`/promotion/promotionWrapping/${id}`).toPromise();
    }

    createPromoWrapping(data: IPromotion.IPromoWrappingCreate): Promise<any>{
        return this._http.post('/promotion/promotionWrapping', data).toPromise();
    }

    updatePromoWrapping(id: any, data: IPromotion.IPromoWrappingUpdate): Promise<any>{
        return this._http.put(`/promotion/promotionWrapping/${id}`, data).toPromise();
    }

    deletePromoWrapping(id: any): Promise<any>{
        return this._http.delete(`/promotion/promotionWrapping/${id}`).toPromise();
    }
}
