import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { ExtensionService } from '@cms/services/extension.service';

declare var alasql: any

@Component({
    selector: 'app-modal-delete-jd-product',
    templateUrl: './modal-delete-jd-product.component.html',
    styleUrls: ['./modal-delete-jd-product.component.css']
})
export class ModalDeleteJDProductComponent implements OnInit {
    @Input() data: any;
    constructor(
        private modal: NzModalRef,
        private modalService: NzModalService,
        private message: NzMessageService,
        private Service_Extension: ExtensionService
    ) { }
    message_duration: any = 3000;
    model:any = {
        password: ""
    }
    async ngOnInit() {

    }

    cancel(){
        this.modal.close();
    }

    ok(){
        if(this.model.password == ""){
            this.message.error("กรุณาใส่รหัสผ่าน",{nzDuration: this.message_duration });
            return;
        }

        let obj = {
            productId: this.data.productId,
            password: this.model.password
        }
        $('.theme-loader').show();
        this.Service_Extension.deleteProductJD(obj).then((res)=>{
            
            this.modal.close(true);
            $('.theme-loader').fadeOut();
        }).catch((data:any)=>{
            this.modalService.error({
                nzTitle: 'Error',
                nzContent: data.error.message,
                nzOnOk: () => {
                }
            });
            $('.theme-loader').fadeOut();
        });

        console.log(obj);

    }
}