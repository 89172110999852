import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UtilService } from '@cms/services/util.service';
import { CustomerService } from '@cms/services/customer.service';
import { apiError } from '@cms/interface/interface';
import { cloneDeep } from "lodash";

@Component({
  selector: 'select-customer',
  template: `

    <input type="text" nz-input nzSize="small" [(ngModel)]="Model" (ngModelChange)="onSearch()" [placeholder]="echo" [nzAutocomplete]="auto" name="auto" (keyup.enter)="onEnter()" >
    <nz-autocomplete #auto>
        <nz-auto-option  *ngFor="let obj of List" [nzValue]="obj.name" >
            <span (click)="select(obj)">
                <div *ngIf="obj?.type == 'guest'">{{obj.name}} {{obj.lastname}} (Guest)</div>
                <div *ngIf="obj?.type !== 'guest'">{{obj.name}} {{obj.lastname}}</div>
                <div style="font-size: 11px;color: #828282;line-height: 5px;">{{obj.username}}</div>
            </span>
        </nz-auto-option>
    </nz-autocomplete>

  `
})
export class SelectCustomerComponent implements OnInit, OnChanges {

  @Output() valueChange =  new EventEmitter();
  @Output() nameChange =  new EventEmitter();
  @Input() data?:string  = ''
  @Input() limit?:number  = 10
  @Input() echo?:string  = 'ชื่อ - นามสกุล'

  List: any[] = [];
  Model:string;

  constructor(
    private _util: UtilService,
    private _customer: CustomerService
  ) {

   }


  ngOnInit() {

    this.Model = this.data || '';

    this.onSearch()

  }


  onSearch(state: boolean = true){
    //console.log('search customer')
    this._customer.search({
        "sort": "desc",
        "status": "",
        "search": this.Model || '',
        "page": 1,
        "perpage": 15
    }).then((res: any)=>{
        console.log(res)
        this.List = res.data
    }).catch((e: apiError)=>{
        console.log(e.error.message)
    })

    this.nameChange.emit(this.Model)
 }

 ngOnChanges(changes: SimpleChanges){
    if(changes.data){
      if(changes.data.previousValue !== undefined){
        this.Model = changes.data.currentValue;
      }
    }
  }

  select(obj:any){
      console.log(obj)
      this.valueChange.emit(cloneDeep(obj));
  }

  onEnter(){
    //console.log(this.Model)
    if(this.List.length > 0){
        this.valueChange.emit(cloneDeep(this.List[0]));
    }
  }


}
