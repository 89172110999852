import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'highlight'
})
export class hilightPipe implements PipeTransform {

  constructor() { }
  transform(value: any, args: any): any {
    if (!args) {
      return value;
    }
    // console.log(value);
    value = `${value}`;
    value = value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    // Match in a case insensitive maneer
    const re = new RegExp(args, 'gi');
    const match = value.match(re);

    // If there's no match, just return the original value.
    if (!match) {
      return value;
    }

    const result = value.replace(re, "<mark>" + match[0] + "</mark>");
    return result;
  }
}