import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie";
import { Store, select } from "@ngrx/store";
import { SET_PROVINCE, SET_LANGS } from "../store/action";
import Hashids from 'hashids/esm'
import { environment } from 'src/environments/environment';

let IS_FB_EVENT_API = true
let memory_cache: any = {
  fonts_json: null
}

@Injectable({
  providedIn: "root"
})
export class UtilService {
  feature: any = {};

  constructor(
    private _http: HttpClient,
    private _cookie: CookieService,
    private store: Store<{ app_store: any }>
  ) {}


  cacheCall(_promise: Observable<any>, key1: string){
    return new Promise((resolve, reject)=>{
      if(memory_cache[key1]){
        return resolve(memory_cache[key1])
      }else{
        _promise.toPromise().then(res=>{
          memory_cache[key1] = res;
          resolve(res)
        }).catch(err=>{
          reject(err)
        })
      }
    })
  }

  searchMap(keyword = ""){
    return this._http.post("/util/searchMap", {
      search: keyword
    }).toPromise();
  }

  filterShippingAddress(data) {
    return this._http.post("/util/filterShippingAddress", data).toPromise();
  }

  limitFeature() {
    return this._http.get("/util/limitFeature").toPromise();
  }


  getSLA() {
    return this._http.get("/util/sla").toPromise();
  }
  acceptSLA(data:any) {
    return this._http.post("/util/acceptSLA",data).toPromise();
  }

  listCountry() {
    return this._http.get("/util/country").toPromise();
  }

  getWebsiteUsage() {
    return this._http.get("/util/websiteUsage").toPromise();
  }

  getWebsiteExpire() {
    return this._http.get("/util/websiteExpire").toPromise();
  }

  websiteDetail(){
    return this._http.get("/util/websiteDetail").toPromise();
  }

  getKetshopOrder() {
    return this._http.get("/util/ketshopOrder").toPromise();
  }

  getShippopName() {
    return this._http.get("/util/shippopname").toPromise();
  }

  getShippopImage() {
    let shippop = {
    //"THP": "/assets/images/core-imgs/ems.jpg",
    "THP": "/system/core-imgs/ems.jpg",
    "EMST": "/system/core-imgs/ems.jpg",
   // "TP2": "/assets/images/core-imgs/thpost.jpg",
    "TP2": "/system/core-imgs/thpost.jpg",
    //"APF": "/assets/images/core-imgs/alphafast.jpg",
    "APF": "/system/core-imgs/alphafast.jpg",
    "KRYP": "/system/core-imgs/KRYP.jpg",
    "KRYD": "/system/core-imgs/KRYD.jpg",
    "JNTP": "/system/core-imgs/jntp.jpg",
    "JNTD": "/system/core-imgs/jntd.jpg",
    "BEST": "/system/core-imgs/best.jpg",
    "RSB": "",
    //"SKT": "/assets/images/core-imgs/skootar.jpg",
    "SKT": "/system/core-imgs/skootar.jpg",
    "GRE": "",
    "GRB": "",
    "DHL": "",
    //"LLM": "/assets/images/core-imgs/lalamove.jpg",
    "LLM": "/system/core-imgs/lalamove.jpg",
    //"NIK": "/assets/images/core-imgs/nikologistic.jpg",
    "NIK": "/system/core-imgs/nikologistic.jpg",
    //"SCG": "/assets/images/core-imgs/scgexpress.jpg",
    "SCG": "/system/core-imgs/scgexpress.jpg",
    //"NJV": "/assets/images/core-imgs/ninjavan.jpg",
    "NJV": "/system/core-imgs/ninjavan.jpg",
    //"SCGF": "/assets/images/core-imgs/scg15.jpg",
    "SCGF": "/system/core-imgs/scg15.jpg",
    //"SCGC": "/assets/images/core-imgs/sgc8.jpg",
    "SCGC": "/system/core-imgs/sgc8.jpg",
    //"CJE": "/assets/images/core-imgs/cjlogistics.png"
    "CJE": "/system/core-imgs/cjlogistics.png",
    "FLE": "/system/core-imgs/flashexpress.png",
    "TRUE": "/system/core-imgs/true-l.png"
      
    };
    return shippop;
  }

  sendMailContactProduct(data: any) {
    return this._http.post("/util/sendMailContactProduct", data).toPromise();
  }

  sendMailContact(data: any) {
    return this._http.post("/util/sendMailContact", data).toPromise();
  }

  getBoxSize() {
    return this._http.get("/delivery/boxsize").toPromise();
  }

  getEmailTemplate() {
    return this._http.get("/util/emailTemplate").toPromise();
  }

  getEmailTemplates() {
    return this._http.get("/util/emailTemplates").toPromise();
  }

  saveEmailTemplate(obj) {
    return this._http.post("/util/saveEmailTemplate", obj).toPromise();
  }

  saveEmailTemplates(obj) {
    return this._http.post("/util/saveEmailTemplates", obj).toPromise();
  }

  getFonts() {
    return this.cacheCall(this._http.get("/assets/default/fonts.json"), "fonts_json")
    // return this._http.get("/assets/default/fonts.json").toPromise();
  }

  getDateTime() {
    return this._http.get("/util/datetime").toPromise();
  }

  getProvince() {
    return new Promise((resolve, reject) => {
      try {
        let _subscribe = this.store
          .pipe(select("app_store", "PROVINCE"))
          .subscribe((res: any[]) => {
            if (res.length == 0) {
              this._http
                .get("/assets/default/province.json")
                .toPromise()
                .then((resp: any[]) => {
                  this.store.dispatch(new SET_PROVINCE(resp));
                })
                .catch(err => {
                  reject(err);
                });
            } else {
              if(_subscribe){
                _subscribe.unsubscribe()
              }
              resolve(res);
            }
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  getDistrict(obj: any) {
    return this._http.post("/util/searchAmphur", obj).toPromise();
  }

  getSubDistrict(obj: any) {
    return this._http.post("/util/searchTambon", obj).toPromise();
  }

  getInstagram(link: string) {
    return this._http.get(`${link}?__a=1`).toPromise();
    //return this._http.post('/util/instagram', {link:link}).toPromise();
  }

  searchPageLink(data: any) {
    return this._http.post("/page/searchPageLink", data).toPromise();
  }

  getPageLink(data: any) {
    if (!data.page) {
      return new Promise((resolve, reject) => {
        reject("page Not Found");
      });
    }
    var key: any = `pagelink_${data.page}`;
    if (this._cookie.get(key)) {
      return new Promise((resolve, reject) => {
        try {
          resolve(JSON.parse(localStorage.getItem(key)));
        } catch (err) {
          this._cookie.remove(key);
          reject(err);
        }
      });
    } else {
      return this._http.post("/page/pagelink", data).toPromise();
    }
    //return this._http.post('/page/pagelink',data).toPromise();
  }

  getPageLinkAll() {
    return this._http.get("/page/pagelink_all").toPromise();
  }

  getStaticPage() {
    return this._http.get("/page/static_page").toPromise();
  }

  getFeatureLang() {
    return this._http.get("/util/featureLang").toPromise();
  }

  saveLangSetting(obj: any) : Promise<any> {
    return this._http.post("/util/languageSetting", obj).toPromise();
  }

  searchLangMessage(obj: any) {
    return this._http.post("/util/searchLangMessage", obj).toPromise();
  }

  saveLangMessage(obj: any) {
    return this._http.put("/util/saveLangMessage/" + obj.id, obj).toPromise();
  }

  getChannels() {
    return this._http.get("/util/getChannels").toPromise();
  }

  getAllLang() {
    return this._http.get("/util/allLang").toPromise();
  }

  getFooter(lang: string = undefined) {
    if (lang) {
      return this._http.get(`/util/footer?lang=${lang}`).toPromise();
    } else {
      return this._http.get("/util/footer").toPromise();
    }
  }

  saveFooter(obj: any) {
    return this._http.post("/util/saveFooter", obj).toPromise();
  }

  getDefaultFooter() {
    return this._http.get("/assets/default/default-footer.json").toPromise();
  }

  getAllLangFormat() {
    return this._http.get("/util/langFormat").toPromise();
  }
  searchLangFormat(data) {
    return this._http.post("/util/searchLangFormat", data).toPromise();
  }
  saveLangFormat(data) {
    return this._http.post("/util/saveLangFormat", data).toPromise();
  }
  getLangs() {
    if (window["seo"]) {
      return this._http.get("/util/lang").toPromise();
    }
    return new Promise((resolve, reject) => {
      try {
        var _subLang = this.store
          .pipe(select("app_store", "LANGS"))
          .subscribe((res: any[]) => {
            if (res.length == 0) {
              this._http
                .get("/util/lang")
                .toPromise()
                .then((resp: any[]) => {
                  this.store.dispatch(new SET_LANGS(resp));
                })
                .catch(err => {
                  reject(err);
                });
            } else {
              if(_subLang){
                _subLang.unsubscribe();
              }
              resolve(res);
            }
          });
      } catch (err) {
        reject(err);
      }
    });
  }
  inuseActivity(data) {
    return this._http.post("/util/inuseActivity", data).toPromise();
  }

  getNotificationCount() {
    return this._http.get("/util/notificationCount").toPromise();
  }

  getKeyCodes(code: any) {
    var _keycode = {
      0: "That key has no keycode",
      3: "break",
      8: "backspace / delete",
      9: "tab",
      12: "clear",
      13: "enter",
      16: "shift",
      17: "ctrl",
      18: "alt",
      19: "pause/break",
      20: "caps lock",
      21: "hangul",
      25: "hanja",
      27: "escape",
      28: "conversion",
      29: "non-conversion",
      32: "spacebar",
      33: "page up",
      34: "page down",
      35: "end",
      36: "home",
      37: "left arrow",
      38: "up arrow",
      39: "right arrow",
      40: "down arrow",
      41: "select",
      42: "print",
      43: "execute",
      44: "Print Screen",
      45: "insert",
      46: "delete",
      47: "help",
      48: "0",
      49: "1",
      50: "2",
      51: "3",
      52: "4",
      53: "5",
      54: "6",
      55: "7",
      56: "8",
      57: "9",
      58: ":",
      59: "semicolon (firefox), equals",
      60: "<",
      61: "equals (firefox)",
      63: "ß",
      64: "@ (firefox)",
      65: "a",
      66: "b",
      67: "c",
      68: "d",
      69: "e",
      70: "f",
      71: "g",
      72: "h",
      73: "i",
      74: "j",
      75: "k",
      76: "l",
      77: "m",
      78: "n",
      79: "o",
      80: "p",
      81: "q",
      82: "r",
      83: "s",
      84: "t",
      85: "u",
      86: "v",
      87: "w",
      88: "x",
      89: "y",
      90: "z",
      91: "Windows Key / Left ⌘ / Chromebook Search key",
      92: "right window key",
      93: "Windows Menu / Right ⌘",
      95: "sleep",
      96: "numpad 0",
      97: "numpad 1",
      98: "numpad 2",
      99: "numpad 3",
      100: "numpad 4",
      101: "numpad 5",
      102: "numpad 6",
      103: "numpad 7",
      104: "numpad 8",
      105: "numpad 9",
      106: "multiply",
      107: "add",
      108: "numpad period (firefox)",
      109: "subtract",
      110: "decimal point",
      111: "divide",
      112: "f1",
      113: "f2",
      114: "f3",
      115: "f4",
      116: "f5",
      117: "f6",
      118: "f7",
      119: "f8",
      120: "f9",
      121: "f10",
      122: "f11",
      123: "f12",
      124: "f13",
      125: "f14",
      126: "f15",
      127: "f16",
      128: "f17",
      129: "f18",
      130: "f19",
      131: "f20",
      132: "f21",
      133: "f22",
      134: "f23",
      135: "f24",
      144: "num lock",
      145: "scroll lock",
      151: "airplane mode",
      160: "^",
      161: "!",
      162: "؛ (arabic semicolon)",
      163: "#",
      164: "$",
      165: "ù",
      166: "page backward",
      167: "page forward",
      168: "refresh",
      169: "closing paren (AZERTY)",
      170: "*",
      171: "~ + * key",
      172: "home key",
      173: "minus (firefox), mute/unmute",
      174: "decrease volume level",
      175: "increase volume level",
      176: "next",
      177: "previous",
      178: "stop",
      179: "play/pause",
      180: "e-mail",
      181: "mute/unmute (firefox)",
      182: "decrease volume level (firefox)",
      183: "increase volume level (firefox)",
      186: "semi-colon / ñ",
      187: "equal sign",
      188: "comma",
      189: "dash",
      190: "period",
      191: "forward slash / ç",
      192: "grave accent / ñ / æ / ö",
      193: "?, / or °",
      194: "numpad period (chrome)",
      219: "open bracket",
      220: "back slash",
      221: "close bracket / å",
      222: "single quote / ø / ä",
      223: "`",
      224: "left or right ⌘ key (firefox)",
      225: "altgr",
      226: "< /git >, left back slash",
      230: "GNOME Compose Key",
      231: "ç",
      233: "XF86Forward",
      234: "XF86Back",
      235: "non-conversion",
      240: "alphanumeric",
      242: "hiragana/katakana",
      243: "half-width/full-width",
      244: "kanji",
      251: "unlock trackpad (Chrome/Edge)",
      255: "toggle touchpad"
    };
    return _keycode[code];
  }

  widgetAnalytic(data: any) {
    /* 
      {
        type: string;
        value: string;
      }
      type example: 'header', 'contact_button'
    */
    return this._http.post("/util/widgetAnalytic", data).toPromise();
  }

  enCodeId(text: string): string{
    let public_key =  environment.public_key
    public_key = public_key.replace(/(\r\n|\n|\r)/gm, "");
    let hashids = new Hashids(public_key)
    let encode = hashids.encode([text])
    return encode
  }

  deCodeId(encode: string): any{
    let public_key =  environment.public_key
    public_key = public_key.replace(/(\r\n|\n|\r)/gm, "");
    let hashids = new Hashids(public_key)
    let data = hashids.decode(encode)
    return data[0]
  }

  fb_events(data: any){
    return new Promise((resolve, reject) => {
      if(!IS_FB_EVENT_API){
        return resolve(true)
      }
      this._http.post("/util/fb_events", data).toPromise().then((res: any) => {
        if(!res.status){
          IS_FB_EVENT_API = false
        }
        resolve(res)
      }).catch(err => reject(err))
    })
  }

  getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

}
