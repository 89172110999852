import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { UtilService } from '@cms/services/util.service';
import { CustomerService } from '@cms/services/customer.service';
@Component({
  selector: 'app-modal-create-customer',
  templateUrl: './modal-create-customer.component.html',
  styleUrls: ['./modal-create-customer.component.css']
})
export class ModalCreateCustomerComponent implements OnInit {
  @Input() data: any;
  allProvince:any = [];
  message_duration = 3000;
  tambon_list: any[] = [];
  amphur_list: any[] = [];
  model:any = {
    province: 'กรุงเทพมหานคร' 
  }
  constructor(private modal: NzModalRef, 
    private message: NzMessageService,
    private Service_Util: UtilService,
    private Service_Customer: CustomerService) { }

  async ngOnInit() {
    this.allProvince =  await this.Service_Util.getProvince();
    this.searchTambon("");
    this.searchAmphur("");
  }
  searchTambon(event) {
    event = event || "";
    let obj = {
      "name": event
    }
    this.Service_Util.getSubDistrict(obj).then((res: any) => {
      this.tambon_list = res;
      // console.log(this.tambon_list);
    }).catch(data => {
      console.log(data.error.message);
    });
  }
  searchAmphur(event) {
    event = event || "";
    let obj = {
      "name": event
    }
    this.Service_Util.getDistrict(obj).then((res: any) => {
      this.amphur_list = res;
      // console.log(this.amphur_list);
    }).catch(data => {
      console.log(data.error.message);
    });
  }
  async ok(){
    try{
    let rs = await this.Service_Customer.create(this.model);
    this.modal.close(rs);
    }catch(e){
      this.message.error(e.error.message, { nzDuration: this.message_duration });
      return false;
    }
  }

}
