import { Component, OnInit ,Input, OnDestroy } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { OrderService } from '@cms/services/order.service';
import { Router } from '@angular/router';
import { pathLangPipe } from '@cms/pipes/path-lang.pipe';
import { interval } from 'rxjs';
import { TranslateService } from '@cms/services/translate.service';
import { UtilService } from '@cms/services/util.service';
declare var QRCode: any;

@Component({
  selector: 'app-modal-qr',
  templateUrl: './modal-qr.component.html',
  styleUrls: ['./modal-qr.component.css']
})
export class ModalQrComponent implements OnInit,OnDestroy {
  @Input() data:any;
  page_name:string = 'confirm-order';
  domain:string;
  message_duration: number;
  order:any;
  subscription:any;
  thankyou:string;

  qr_render:boolean = false

  constructor(
    private message: NzMessageService,
    private modal: NzModalRef,
    private modalService: NzModalService,
    private Service_Order: OrderService,
    private router: Router,
    private path_lang: pathLangPipe,
    private Service_Translate: TranslateService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    // console.log(this.modal);

    this.domain = window.location.hostname;
    this.thankyou = this.path_lang.transform('thankyou');

    // this.Service_Order.ordercode
    if (this.data.optional.guest){
      this.Service_Order.ordercode(this.data.optional.ordercode).then((res:any)=>{
        console.log(res);
        if (res.status != 1 || res.qr == null) {
          // this.router.navigate([`/`]);
          if(res.status > 1){
            this.message.success(this.Service_Translate.translate('คำสั่งซื้อได้รับชำระแล้ว', 'confirm-payment'))
          }
          if(res.status == -1){
            this.message.warning(this.Service_Translate.translate('คำสั่งซื้อถูกยกเลิก', 'confirm-payment'))
          }
          // this.modal.close()x
          return;
        }
        this.order = res;
        console.log("ORDERRRR");
        this.qr_render = true
        setTimeout(() => {
          try{
            new QRCode($('#qr_code')[0], { width: 250, height: 250, text: this.order.qr.link });
           }catch(err){}
        },10);
        let interval_checkstatus = interval(1000);
        this.subscription = interval_checkstatus.subscribe(this.checkOrderStatus.bind(this));

      }).catch((data) => {
        console.log(data);
      });
    }else{

      this.Service_Order.orderByID(this.data.orderid).then((res: any) => {
        console.log(res);
        if (res.status != 1 || res.qr == null) {
          // this.router.navigate([`/`]);
          if(res.status > 1){
            this.message.success(this.Service_Translate.translate('คำสั่งซื้อได้รับชำระแล้ว', 'confirm-payment'))
          }
          if(res.status == -1){
            this.message.warning(this.Service_Translate.translate('คำสั่งซื้อถูกยกเลิก', 'confirm-payment'))
          }
          // this.modal.close()
          return;
        }
        this.order = res;
        console.log("ORDERRRR");
        this.qr_render = true
        setTimeout(() => {
          try{
            new QRCode($('#qr_code')[0], { width: 250, height: 250, text: this.order.qr.link  });
          }catch(err){}
        },10);
        let interval_checkstatus = interval(1000);
        this.subscription = interval_checkstatus.subscribe(this.checkOrderStatus.bind(this));

      }).catch((data) => {
        console.log(data);
      });
    }


  }
  checkOrderStatus(n){
    console.log(n);
    // console.log(this);
    this.Service_Order.checkOrderStatus(this.order.id).then((res:any)=>{
      if(res.status == 2){
        if (this.data.state == 'confirmorder'){
          this.router.navigate([`${this.thankyou}`]);
          this.modal.close();
        }else{
          let model = {
            order: this.order,
            paymentType: this.order.paymenttype
          }
          localStorage.last_order = JSON.stringify(model);
          this.router.navigate([`${this.thankyou}`]);
          this.modal.close();
        }
      }
    });
  }
  ok(){
    if (this.data.optional.guest){
      // this.router.navigate([`/`]);
      // this.modal.close();
      this.modalService.confirm({
        nzTitle: `กรุณาชำระเงินภายใน 7 วัน`,
        nzContent: `คุณสามารถชำระเงินภายหลังได้ที่ลิงค์นี้ <a href="${location.origin}/order-page/${this.utilService.enCodeId(this.data.optional.ordercode)}" target="_blank">${location.origin}/order-page/${this.utilService.enCodeId(this.data.optional.ordercode)}</b>`,
        nzOkText: 'เสร็จสิ้น',
        nzOkType: 'danger',
        nzOnOk: () => {
          this.router.navigate([`/`]);
          this.modal.close();
        },
        nzCancelText: 'ยกเลิก',
        nzOnCancel: () => {

        }
      });


    }else{
      let memberhistory = this.path_lang.transform('mem-history');
      this.router.navigate([`${memberhistory}`]);
      this.modal.close();
    }

    // console.log('test');
  }

  ngOnDestroy(){
    console.log("NG DESTROY");
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}
