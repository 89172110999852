import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LangGuard } from './lang.guard';
import { DeliveryViewComponent } from './system/delivery-view/delivery-view.component';
import { AuthenGuard } from './authen.guard';
// import { PageCreditRedirectComponent } from './pages/page-credit-redirect/page-credit-redirect.component';
const routes: Routes = [
    {
      path: 'system/login', loadChildren: './system/modules/login/login.module#LoginModule'
    },
    {
      path: 'system/forgotpassword', loadChildren: './system/modules/forgot-password/forgot-password.module#ForgotPasswordModule'
    },
    {
      path: 'system/e-commerce/view/delivery/:trackcode', canActivate: [AuthenGuard], component: DeliveryViewComponent
    },
    {
      path: 'system/e-commerce/order/edit', loadChildren: './system/modules/e-commerce/order-edit-modal.module#OrderEditModalModule'
    },
    {
      path: 'system/e-commerce/order-detail', loadChildren: './system/order/order-detail/order-detail.module#OrderDetailModule'
    },
    // {
    //   path: 'system/messenger', loadChildren: './system/modules/messenger/messenger.module#MessengerModule'
    // },
    // {
    //   path: 'system/line-chat', loadChildren: './system/modules/line-chat/line-chat.module#LineChatModule'
    // },
    {
      path: 'system/messenger', redirectTo: "system/chats-app", pathMatch: "full"
    },
    {
      path: 'system/line-chat', redirectTo: "system/chats-app", pathMatch: "full"
    },
    {
      path: 'system/chats-app',  canActivate: [AuthenGuard], loadChildren: './system/chats-app/chats-app.module#ChatsAppModule'
    },
    {
        path: 'system', loadChildren: './system/system.module#SystemModule'
    },
    {
      path: 'confirmregister', loadChildren: './front/modules/confirmregister/confirmregister.module#ConfirmregisterModule'
    },
    {
      path: 'forgotpassword', loadChildren: './front/modules/forgotpassword/forgotpassword.module#ForgotpasswordModule'
    },
    { path: 'orderlink', loadChildren: './pages/order-link-page/order-link-page.module#OrderLinkPageModule'  },
    { path: 'crypto-redirect', loadChildren: './pages/page-credit-redirect/credit-redirect.module#CreditRedirectModule' },
    { path: 'credit-redirect', loadChildren: './pages/page-credit-redirect/credit-redirect.module#CreditRedirectModule' },
    { path: 'sale-page', loadChildren: './pages/sale-page/render-sale-page.module#RenderSalePageModule'  },
    { path: 'order-page', loadChildren: './pages/order-page/order-page.module#OrderPageModule'  },
    { path: '', canActivate: [LangGuard], loadChildren: './render/render-frontend.module#RenderFrontendModule' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
