import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ProductsService } from '@cms/services/products.service';
import { ExtensionService } from '@cms/services/extension.service';
import { SettingService } from '@cms/services/setting.service';
import * as _ from 'lodash';
declare var alasql: any

@Component({
    selector: 'app-modal-add-sku-from-ketshop',
    templateUrl: './modal-add-sku-from-ketshop.component.html',
    styleUrls: ['./modal-add-sku-from-ketshop.component.css']
})
export class ModalAddSkuFromKetshopComponent implements OnInit {
    @Input() data: any;

    constructor(
        private modal: NzModalRef,
        private modalService: NzModalService,
        private Service_Product: ProductsService,
        private Service_Extension: ExtensionService,
        private Service_Setting: SettingService
    ) { }
    modelsearch: any = {
        expand_property: true,
        page: 1,
        perpage: 10,
        search: "",
        lang: "lang1"
    }
    product: any = [];
    total: number = 0;
    allcategory:any = [];

    selected_item = [];

    async ngOnInit() {
       
        this.allcategory = await this.Service_Product.productMainCategory();
        this.allcategory.unshift({ id: "-1", lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory" });
        this.allcategory.unshift({ id: "", lang1: "All", lang2: "All", lang3: "All", lang4: "All" });
        this.search();

    }
    search() {
        this.Service_Extension.searchKetshopProductToJD(this.modelsearch).then((data: any) => {
            console.log(data);
            data.data.forEach(element => {
                element.disabled = false;
                let chk = this.data.sku.find(x=>x == element.sku);
                if(chk){
                    element.disabled = true;
                }
              
            });
            this.product = data.data;
            this.total = data.count;
        }).catch((data) => {
            console.log(data);
        });
    }

    pagechage(page) {
        this.modelsearch.page = page;
        this.search();
    }

    selected(item){
        this.selected_item = [];
        this.selected_item.push(item);
        this.modal.close(this.selected_item);
    }

    ok(){

    }
}