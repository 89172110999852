import { Component, OnInit,Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CustomerService } from '@cms/services/customer.service';
import { UtilService } from '@cms/services/util.service';
import { AuthenService } from '@cms/services/authen.service';

@Component({
  selector: 'app-modal-edit-billing-address',
  templateUrl: './modal-edit-billing-address.component.html',
  styleUrls: ['./modal-edit-billing-address.component.css']
})
export class ModalEditBillingAddressComponent implements OnInit {
  page_name: string = "member-profile";
  @Input() data:any;
  constructor(
    private message: NzMessageService,
    private modal: NzModalRef,
    private Service_Customer: CustomerService,
    private Service_Util: UtilService,
    private _authen : AuthenService
  ) { }

  model:any = {
    "address1": "",
    "subdistrict": "",
    "district": "",
    "province": "",
    "zipcode": ""
  }

  province:any[] = [];
  tambon_list:any[] = [];
  amphur_list:any[] = [];
  ngOnInit() {
    this.Service_Util.getProvince().then((res: any[]) => {
      this.province = res
    });
    this.model.id = this.data.id;
    this.model.address1 = this.data.address1;
    this.model.subdistrict = this.data.subdistrict;
    this.model.district = this.data.district;
    this.model.province = this.data.province;
    this.model.zipcode = this.data.zipcode;
    this.model.name = this.data.name;
    this.model.lastname = this.data.lastname;
    this.model.tel = this.data.tel;
    this.searchTambon(this.model.subdistrict);
    this.searchAmphur(this.model.district);

  }
  message_duration: number = 4000;
  searchTambon(event){
    event = event || "";
    let obj = {
      "name": event
    }
    this.Service_Util.getSubDistrict(obj).then((res: any) => {
      this.tambon_list = res;
      // console.log(this.tambon_list);
    }).catch(data => {
      console.log(data.error.message);
    });
  }
  searchAmphur(event) {
    event = event || "";
    let obj = {
      "name": event
    }
    this.Service_Util.getDistrict(obj).then((res: any) => {
      this.amphur_list = res;
      // console.log(this.amphur_list);
    }).catch(data => {
      console.log(data.error.message);
    });
  }

  async ok(){
    console.log(this.model);
    if (this.model.name == null || this.model.name == undefined || this.model.name == "") {
      this.message.error("โปรดกรอกชื่อ", { nzDuration: this.message_duration });
      return false;
    }
    else if (this.model.lastname == null || this.model.lastname == undefined || this.model.lastname == "") {
      this.message.error("โปรดกรอกนามสกุล", { nzDuration: this.message_duration });
      return false;
    } else if (this.model.tel == null || this.model.tel == undefined || this.model.tel == "") {
      this.message.error("โปรดกรอกเบอร์โทรศัพท์", { nzDuration: this.message_duration });
      return false;
    }
    else if (this.model.subdistrict == null || this.model.subdistrict == undefined || this.model.subdistrict == ""){
      this.message.error("โปรดกรอกตำบล/แขวง", { nzDuration: this.message_duration });
      return false;
    } else if (this.model.district == null || this.model.district == undefined || this.model.district == "") {
      this.message.error("โปรดกรอกอำเภอ/เขต", { nzDuration: this.message_duration });
      return false;
    }
    else if (this.model.province == null || this.model.province == undefined || this.model.province == "") {
      this.message.error("โปรดกรอกจังหวัด", { nzDuration: this.message_duration });
      return false;
    } else if (this.model.zipcode == null || this.model.zipcode == undefined || this.model.zipcode == "") {
      this.message.error("โปรดกรอกรหัสไปรษณีย์", { nzDuration: this.message_duration });
      return false;
    }

    try {

      var _user = this._authen.getJwtDecode()
      if(_user.user_role == "customer"){
        await this.Service_Customer.updateProfile(this.model);
      }else{
        this.model['first_name'] = this.model.name
        this.model['last_name'] = this.model.lastname
        this.model['address'] = this.model.address1
        this.model['status'] = 1
        await this.Service_Customer.update(this.model);
      }
      this.modal.close(true);


    } catch (e) {
      this.message.error(e.error.message, { nzDuration: this.message_duration });
      return false;
    }
    return false;
  }
  close(){
    this.modal.close(true);
  }

}
