import { Component, OnInit, Input } from '@angular/core';
import { ConfirmPaymentService } from '@cms/services/confirmpayment.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { ModalViewSlipComponent } from '@cms/system/modal/modal-view-slip/modal-view-slip.component';
import { ModalEditConfirmpaymentComponent } from '@cms/system/modal/modal-edit-confirmpayment/modal-edit-confirmpayment.component';
import { ModalCreateConfirmpaymentComponent } from '@cms/system/modal/modal-create-confirmpayment/modal-create-confirmpayment.component';
import * as moment from 'moment';
import { ModalExportConfirmpaymentComponent } from '@cms/system/modal/modal-export-confirmpayment/modal-export-confirmpayment.component';
import { FeatureService } from '@cms/services/feature.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmpayment-modal',
  templateUrl: './confirmpayment.component.html',
  styleUrls: ['./confirmpayment.component.css']
})
export class ConfirmpaymentModalComponent implements OnInit {


  @Input() ordercode: any = ''
  @Input() order_status: number = 1

  allstatus:any = [];
  model: any = {
    "search": "",
    "status": "",
    "from": "bank",
    "ordercode": "",
    "date": "",
    "page": 1,
    "perpage": 10
  }
  statusimg:any ={
    "1": "/assets/images/core-imgs/icon/payment-status1.png",
    "0": "/assets/images/core-imgs/icon/payment-status0.png",
    "-1": "/assets/images/core-imgs/icon/payment-status-1.png"
  }
  loading:boolean = false;
  date:any;
  confirmpayment:any=[];
  total:any = 0;
  feature:boolean = true;

  constructor(
    private Service_ConfirmPayment: ConfirmPaymentService,
    private notification: NzNotificationService,
    private modalService: NzModalService,
    private Service_Feature: FeatureService,
    private router: Router,
  ) { }

  async ngOnInit() {

    if(this.ordercode){
      this.model.ordercode = this.ordercode
    }
    // console.log(this.Service_ConfirmPayment);
    this.feature = await this.Service_Feature.getFeature("PRODUCT_PAGE");
    if (!this.feature) {
      return;
    }
    this.allstatus = await this.Service_ConfirmPayment.confirmstatus();
    this.allstatus.unshift({status:"",name:"All"});
    this.search();
  }

  dateChange(event){
    console.log(event);
    if(!event){
      this.model.date = "";
    }else{
      this.model.date = moment(event).format('YYYY-MM-DD');
    }
    this.search();
  }
  pagechage(page) {
    this.model.page = page;
    this.search();
  }
  createConfirmpayment(){
    const modal = this.modalService.create({
      nzTitle: 'Confirm Payment',
      nzContent: ModalCreateConfirmpaymentComponent,
      nzStyle: { top: '20px' },
      // nzWidth: "95%",
      nzKeyboard: false,
      nzMaskClosable: false,
      nzComponentParams: {
        ordercode: this.ordercode,
        data: {}
      },
      nzOnOk: (componentInstance) => {
        return componentInstance.ok();
      },
      nzOnCancel: (componentInstance) => {

      }
    }).afterClose.subscribe((result) => {
      modal.unsubscribe()
      this.search();
    });
  }
  export(){
    const modal = this.modalService.create({
      nzTitle: 'Export',
      nzContent: ModalExportConfirmpaymentComponent,
      nzStyle: { top: '20px' },
      // nzWidth: "700",
      nzKeyboard: false,
      nzMaskClosable: false,
      nzOnOk: (componentInstance) => {
        return componentInstance.ok();
      },
      nzOnCancel: (componentInstance) => {

      },
      nzComponentParams: {
        data: {

        }
      }
    });
  }
  edit(obj){
    // ModalEditConfirmpaymentComponent
    const modal = this.modalService.create({
      nzTitle: 'Edit',
      nzContent: ModalEditConfirmpaymentComponent,
      nzStyle: { top: '20px' },
      nzWidth: "700",
      nzKeyboard: false,
      nzMaskClosable: false,
      nzOnOk: (componentInstance) => {
        return componentInstance.ok();
      },
      nzOnCancel: (componentInstance) => {

      },
      nzComponentParams: {
        data: {
          confirm: obj
        }
      }
    }).afterClose.subscribe((result) => {
      modal.unsubscribe()
      this.search();
    });
  }

  viewslip(obj){
    const modal = this.modalService.create({
      nzTitle: 'หลักฐานการโอนเงิน',
      nzContent: ModalViewSlipComponent,
      nzWrapClassName: "vertical-center-modal",
      // nzWidth: "95%",
      nzFooter: null,
      nzKeyboard: false,
      nzMaskClosable: false,
      nzOnOk: (componentInstance) => {
        return componentInstance.ok();

      },
      nzOnCancel: (componentInstance) => {

      },
      nzComponentParams: {
        data: {
          img: obj.picture
        }
      }
    });
  }
  search(){
    this.loading = true;
    this.Service_ConfirmPayment.search(this.model).then((res:any)=>{
      console.log(res);

      this.confirmpayment = res.data;
      this.total = res.count;
    }).catch((data) => {
      console.log(data.error.message);
    }).finally(()=>{
      this.loading = false;
    });
  }

  async view(obj){
    let url = window.location.origin+'/system/e-commerce/order-detail/'+obj;
    window.open(url, '_blank', "menubar=no,toolbar=no,location=no,width=1280,height=720");

  }

}
