import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from '@cms/services/util.service';
import { LANGS } from '@cms/interface/interface';

@Component({
  selector: 'select-langs',
  template: `
    <nz-select
      style="width: 100%;"
      nzPlaceHolder="Select a lang"
      [(ngModel)]="langModel"
      (ngModelChange)="onModelChange()"
    >
      <nz-option
        nzCustomContent
        *ngFor="let obj of langs"
        [nzValue]="obj.ref"
        [nzLabel]="obj.name"

      >
      <!-- <nz-avatar [nzSrc]="obj.pic"></nz-avatar>  -->
      <span > {{obj.name}} </span> </nz-option>
    </nz-select>
  `
})
export class SelectLangsComponent implements OnInit {

  @Output() langChange =  new EventEmitter();
  @Input() data:string;

  constructor(
    private _util: UtilService,
  ) {

   }

  fonts:any = [];
  langModel:string;
  langs: LANGS[];

  ngOnInit() {
    this.langModel = this.data || 'lang1';
    this._util.getFonts().then((res:any) =>{
      this.fonts = res.data;
    });

    this._util.getLangs().then((res: LANGS[]) =>{
        //console.log(res)
        this.langs = res;
    });
  }

  onModelChange(){
    this.langChange.emit(this.langModel);
  }



}
