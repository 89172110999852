import { Injectable } from '@angular/core';
import { Etc } from '../util/etc';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private _etc:Etc,
    private _http:HttpClient,
    private _cookie:CookieService
    ) { }
    search(data: any) {
        return this._http.post('/customer/search', data).toPromise();
    }

    create(data: any){
        return this._http.post('/customer', data).toPromise();
    }

    getPointToUse(data: any = null) {
        return this._http.post('/customer/getPointToUse', data).toPromise();
    }

    searchLogPoint(data) {
        return this._http.post('/customer/searchLogPoint', data).toPromise();
    }
    getCustomerPoint(data) {
        return this._http.post('/customer/getCustomerPoint', data).toPromise();
    }
    adjustPoint(data) {
        return this._http.post('/customer/adjustPoint', data).toPromise();
    }

    update(data:any){
        return this._http.put('/customer/'+data.id,data).toPromise();
    }

    delete(data:any){
        return this._http.delete('/customer/'+data.id).toPromise();
    }

    confirmRegister(data:any){
        return this._http.post('/customer/confirmregister',data).toPromise();
    }

    forgotPassword(data:any){
        return this._http.post('/customer/forgotpassword', data).toPromise();
    }

    checkForgotPassword(data:any){
        return this._http.post('/customer/checkForgotPasswordToken', data).toPromise();
    }

    register(data:any){
        return this._http.post('/customer/register',data).toPromise();
    }

    changePasswordForgot(data:any){
        return this._http.post('/customer/changePasswordForgot', data).toPromise();
    }

    me(header: any = null){
       return header?this._http.get('/customer/me',header).toPromise():this._http.get('/customer/me').toPromise();
    }

    byId(id:any){
      return this._http.get(`/customer/${id}`).toPromise();
    }

    byFacebookId(id:any){
        return this._http.get(`/customer/facebook/${id}`).toPromise() as any;
    }

    summary() {
        return this._http.get(`/customer/summary`).toPromise();
    }


    updateProfile(data:any){
        return this._http.post('/customer/updateProfile', data).toPromise();
    }

    createSendingAddress(data:any){
        return this._http.post('/customer/address', data).toPromise();
    }

    updateSendingAddress(data:any){
        return this._http.put('/customer/address/'+data.id, data).toPromise();
    }

    deleteSendingAddress(id){
        return this._http.delete('/customer/address/' + id).toPromise();
    }

    orderHistory(data:any,from = ''){
        return this._http.post(`/customer/orderHistory?from=${from}`,data).toPromise();
    }

    registerGuest(data:any){
      return this._http.post('/customer/registerGuest',data).toPromise();
    }

    getWishList() {
        return this._http.get('/customer/wishList').toPromise();
    }

    addWishList(data: any) {
        return this._http.post('/customer/wishList', data).toPromise();
    }

    removeWishList(id: any) {
        return this._http.delete(`/customer/wishList/${id}`).toPromise();
    }
}
