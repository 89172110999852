import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KetSelectFontComponent } from './select-font.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { KetFontModule } from '@ketshopweb/ui/font';

@NgModule({
  declarations: [
    KetSelectFontComponent
  ],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    KetFontModule
  ],
  exports:[
    KetSelectFontComponent
  ]
})
export class KetSelectFontModule { }