import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UtilService } from '@cms/services/util.service';

@Component({
  selector: 'select-province',
  template: `
    <nz-select
      style="width: 100%;"
      nzShowSearch
      nzPlaceHolder="Select a province"
      [(ngModel)]="provinceModel"
      (ngModelChange)="onModelChange()"
      name="province_name"
      [nzDisabled]="disabled"
    >
      <nz-option
        *ngFor="let obj of provinceList"
        [nzValue]="obj.province_name"
        [nzLabel]="obj.province_name"

      ></nz-option>
    </nz-select>
  `
})
export class SelectProvinceComponent implements OnInit, OnChanges {

  @Output() provinceChange =  new EventEmitter();
  @Input() data?:string  = ''
  @Output() Location =  new EventEmitter();
  @Input() disabled?:boolean  = false

  provinceList: any[] = [];
  provinceModel:string;

  constructor(
    private _util: UtilService,
  ) {

   }


  ngOnInit() {

    this.provinceModel = this.data || 'กรุงเทพมหานคร';

    this._util.getProvince().then((res:any[]) =>{
        this.provinceList = res
        //console.log(res)
        if(this.provinceModel == 'กรุงเทพมหานคร'){
          this.provinceChange.emit(this.provinceModel);
          var obj = this.provinceList.find(x=>x.province_name == this.provinceModel)
          if(obj){
              this.Location.emit({
                  lat: obj.province_lat,
                  lng: obj.province_lon
              })
          }
        }
    })


  }

  onModelChange(){
    this.provinceChange.emit(this.provinceModel);
    var obj = this.provinceList.find(x=>x.province_name == this.provinceModel)
    if(obj){
        this.Location.emit({
            lat: obj.province_lat,
            lng: obj.province_lon
        })
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.data){
      if(changes.data.previousValue !== undefined){
        this.provinceModel = changes.data.currentValue;
      }
    }
  }

}
