import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { UtilService } from '@cms/services/util.service';
import { TagService } from '@cms/services/tag.service';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-modal-tag',
  templateUrl: './modal-tag.component.html',
  styleUrls: ['./modal-tag.component.css']
})
export class ModalTagComponent implements OnInit {
  @Input() data: any;

  constructor(private Service_Util:UtilService,
              private modal: NzModalRef,
              private message: NzMessageService,
              private Service_Tag:TagService) { }
  lang:any = [];

  model:any= {
    lang1: "",
    lang2: "",
    lang3: "",
    lang4: ""
  }
  message_duration:any= 3000;

  async ngOnInit() {
    this.lang = await this.Service_Util.getLangs();
    // console.log(lang);
    if(this.data.state == 'edit'){
      this.model = cloneDeep(this.data.model);
    }
  }

  async ok(){
    for (let i = 0; i < this.lang.length; i++) {
      console.log(this.model[this.lang[i].ref]);
      if (this.model[this.lang[i].ref]==null || this.model[this.lang[i].ref].trim() == "") {
        this.message.error(`Please fill tag name ( ${this.lang[i].name} )`, { nzDuration: this.message_duration });
        return false;
      }
    }

    if(this.data.state=='create'){
      try{
        return await this.Service_Tag.create(this.model);
      }catch(e){
        this.message.error(e.error.message, { nzDuration: this.message_duration });
        return false;
      }
    }else{
      try {
        await this.Service_Tag.update(this.model);

      } catch (e) {
        this.message.error(e.error.message, { nzDuration: this.message_duration });
        return false;
      }
    }
  }

}
