import { Injectable } from '@angular/core';
import { Etc } from '../util/etc';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

@Injectable({
    providedIn: 'root'
})
export class ConfirmPaymentService {

    constructor(
        private _etc: Etc,
        private _http: HttpClient,
        private _cookie: CookieService
    ) { }


    search(data: any) {
        return this._http.post('/payment', data).toPromise();
    }

    confirmstatus(){
        return this._http.get('/payment/status').toPromise();
    }

    updateStatus(obj:any) {
        return this._http.put('/payment/' + obj.id,obj).toPromise();
    }
    byID(id){
        return this._http.get('/payment/confirmpayment/'+id).toPromise();
    }
    allBank() {
        return this._http.get('/payment/bank').toPromise();
    }
    bankList() {
        return this._http.get('/payment/bankList').toPromise();
    }
    createConfirmpaymentBackEnd(obj:any){
        return this._http.post('/payment/confirmpaymentBackend',obj).toPromise();
    }

    createConfirmpaymentFrontEnd(obj: any) {
        return this._http.post('/payment/confirmpaymentFrontend', obj).toPromise();
    }

    createBank(obj){
        return this._http.post('/payment/bank',obj).toPromise();
    }

    bankStatus(obj:any){
      return this._http.post('/payment/bank_status',obj).toPromise();
    }

    updateBank(obj){
        return this._http.put('/payment/bank/' + obj.id, obj).toPromise();
    }

    deleteBank(id){
        return this._http.delete('/payment/bank/' + id).toPromise();
    }

    uploadSlip(obj){
        const HttpUploadOptions = {
            headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
        }
        return this._http.post('/payment/uploadSlip', obj).toPromise();
    }

    listOrderToConfirm(obj){
        return this._http.post('/payment/listOrderToConfirm', obj).toPromise();
    }

    markPayment(obj:any, orderId: any){
        return this._http.post(`/payment/mark/${orderId}`, obj).toPromise()
    }

    installmentBank(totals = 0) {
        return this._http.post('/payment/installmentBank', {
            totals: totals
        }).toPromise();
    }

}
