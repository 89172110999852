import {
  Directive, ElementRef, Renderer, Input, SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[ket-font]'
})
export class KetFontDirective{
  constructor(private el: ElementRef, private render: Renderer) {}

  @Input('ket-font') font:string;

  ngAfterViewInit(){

    this.render.setElementStyle(this.el.nativeElement, 'font-family', this.font)

  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.font){
      //console.log('font changed', changes.font);
      if(changes.font.previousValue !== undefined){
        this.font = changes.font.currentValue;
        //console.log('change')
        this.render.setElementStyle(this.el.nativeElement, 'font-family', this.font)
      }
    }
  }

}
