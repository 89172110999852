import { Component, OnInit, Input } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import { apiError } from '@cms/interface/interface'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { pathLangPipe } from '@cms/pipes/path-lang.pipe';

declare var navigator:any;

@Component({
  selector: 'app-modal-show-link-page',
  templateUrl: './modal-show-link-page.component.html',
  styleUrls: ['./modal-show-link-page.component.css']
})
export class ModalShowLinkPageComponent implements OnInit {

  @Input() data:any;

  langs: any[] = []
  link:any = {
    lang1: "",
    lang2: "",
    lang3: "",
    lang4: ''
  }

  constructor(
    private _util: UtilService,
    private _noti: NzNotificationService,
    protected pathLang: pathLangPipe
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.loadLang()
  }

  loadLang(){
    this._util.getLangs().then((res: any[])=>{
      this.langs = res
      // console.log(res)
      this.langs.forEach(o => {
        this.link[o.ref] = this.getLink(o);
      });
     
    }).catch((e: apiError)=>{
      console.log(e.error.message)
    })
  }

  getLink(lang){
    console.log(lang, this.data.page)

    console.log(this.pathLang.transform('blog-detail'));
    if (this.data.page.defaul_stat == 0){
      let blog_detail = this.pathLang.transform('blog-detail');
      return `${window.location.origin}/${lang.name}${blog_detail}/${this.data.page.page['permalink_'+lang.ref]}`
    }

    if (this.data.page.defaul_stat == -1 || this.data.page.defaul_stat == 1){
      return `${window.location.origin}/${lang.name}/${this.data.page[lang.ref]}`
    }

    if (this.data.page.defaul_stat == 2){
      return `${this.data.page.path}`;
    }

    if (this.data.page.defaul_stat == 3){
      let product_page = this.pathLang.transform('Products');
      return `${window.location.origin}/${lang.name}${product_page}/${this.data.page.path}/${this.data.page[lang.ref]}`;
    }

    if (this.data.page.defaul_stat == 4){
      console.log(this.data.page);
      let product_detail = this.pathLang.transform('product-detail');
      return `${window.location.origin}/${lang.name}${product_detail}/${this.data.page.page['permalink_'+lang.ref]}`
    }
    // return `${window.location.origin}/${obj.name}/${this.data.page[obj.ref]}`
  }

  path(obj:any){
    
  }

  copy(obj:any) {
    try {
      var text = this.getLink(obj);
        navigator.clipboard.writeText(text).then((res: any) => {
            this._noti.create('success',  'copy success','');
        });
    } catch (err) {}
  }

}
