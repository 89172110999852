import { Injectable } from '@angular/core';
import { Etc } from '../util/etc';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

@Injectable({
    providedIn: 'root'
})
export class EPaymentService {

    constructor(
        private _etc: Etc,
        private _http: HttpClient,
        private _cookie: CookieService
    ) { }


    search(data: any) {
        return this._http.post('/services/v2/payment-gateway/transaction/ebalance', data).toPromise()
      }    


      qrWithOutOrder(data: { amount: number; order_type: string; return_url: string; sub_cus_id: number }) {
        return this._http.post(`/services/v2/payment-gateway/qrcode/generator-without-order`, data).toPromise() as Promise<{
          amount: number
          form: {
            fields: {
              input_type: string
              name: string
              value: string
            }[]
            method: string
            url: string
          }
          is_form: boolean
          message: string
          qrcode: string
          reference_no: string
          status: number
        }>
      }
    

  creditWithOutOrder(data: {
    amount: number
    card: {
      ccv: string
      exp_month: string
      exp_year: string
      name: string
      number: string
    }
    order_type: string
    return_url: string
    sub_cus_id: number
  }) {
    return this._http
      .post(`/services/v2/payment-gateway/credit-card/redirect-without-order`, data)
      .toPromise() as Promise<{
      amount: number
      form: {
        fields: {
          input_type: string
          name: string
          value: string
        }[]
        method: string
        url: string
      }
      message: string
      reference_no: string
      status: number
    }>
  }

  installmentWithOutOrder(data: {
    amount: number
    installment: {
      bankins: string
      monthins: number
    }
    order_type: string
    return_url: string
    sub_cus_id: number
  }) {
    return this._http
      .post(`/services/v2/payment-gateway/credit-card/installment-without-order`, data)
      .toPromise() as Promise<{
      amount: number
      form: {
        fields: {
          input_type: string
          name: string
          value: string
        }[]
        method: string
        url: string
      }
      message: string
      reference_no: string
      status: number
    }>
  }

      checkOrderReference(reference_no) {
        return this._http.get(`/services/v2/payment-gateway/orders/reference/${reference_no}`).toPromise() as Promise<{
          err_reason: string
          transaction_id: number
          transaction_no: string
          transaction_status: number
        }>
      }

      saveSetting(data = {}) {
        return this._http.post(`/services/v2/payment-gateway/setting/save_model`, data).toPromise() as Promise<{
          channels: {
            channel_type: string
            min_pay: number
            status: number
            display_installment_rate: number
          }[]
          installment: {
            code: string
            max_term: number
            status: number
          }[]
          sub_cus_id?: number
        }>
      }

    listSetting(data = {}) {
        return this._http.post(`/services/v2/payment-gateway/setting/model`, data).toPromise() as Promise<{
          api_key: string
          authorization: string
          channels: {
            channel_type: string
            created_at: string
            display_installment_rate: number
            id: number
            is_required_card: number
            merchant_id: number
            min_pay: number
            rate_type: string
            rate_value: number
            status: number
            updated_at: string
          }[]
          created_at: string
          cus_id: number
          id: number
          installment: {
            code: string
            color: string
            created_at: string
            id: number
            logo: string
            max_term: number
            merchant_id: number
            monthly_minimum: number
            name: string
            provider: string
            status: number
            terms: string
            updated_at: string
          }[]
          provider: string
          provider_merchant_id: string
          secret_key: string
          status: boolean | number
          sub_cus_id: number
          updated_at: string
        }>
      }
    allStatus() {
        return this._http.get('/payment/auto-status').toPromise();
    }
    
    checkTxn(transaction: any, action: string) {
        return this._http.get(`/order/checkPayment/${transaction}/${action}`).toPromise();
    }

    inquiry(data: { is_postback: boolean; ordercode: string; reference_no: string,  force?: boolean }) {
        return this._http.post('/services/v2/payment-gateway/inquiry', data).toPromise()
      }

}
