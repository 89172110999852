import { KetPermissionModule } from '@ketshopweb/ui/permission/permission.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KetModalEditCustomerComponent } from './modal-edit-customer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { KetThailandAddressModule } from '../thailand-address';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { KetTranslateModule } from '../translate';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

@NgModule({
  declarations: [
    KetModalEditCustomerComponent
  ],
  entryComponents: [KetModalEditCustomerComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzButtonModule,
    NzAutocompleteModule,
    KetPermissionModule,
    ReactiveFormsModule,
    KetThailandAddressModule,
    NzIconModule,
    KetTranslateModule,
    NzRadioModule,
    NzDatePickerModule
  ],
  exports:[
    KetModalEditCustomerComponent
  ]
})
export class KetModalEditCustomerModule { }