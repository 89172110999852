import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { HttpClient } from '@angular/common/http';
import { ProductsService } from '@cms/services/products.service';
import { ExtensionService } from '@cms/services/extension.service';
import { SettingService } from '@cms/services/setting.service';
import * as _ from 'lodash';
import { ModalAddSkuFromKetshopComponent } from '../modal-add-sku-from-ketshop/modal-add-sku-from-ketshop.component';
import { ModalDeleteJDProductComponent } from '../modal-delete-jd-product/modal-delete-jd-product.component';
declare var alasql: any

@Component({
    selector: 'app-modal-jd-product',
    templateUrl: './modal-jd-product.component.html',
    styleUrls: ['./modal-jd-product.component.css']
})
export class ModalJDProductComponent implements OnInit {
    @Input() data: any;

    constructor(
        private modal: NzModalRef,
        private modalService: NzModalService,
        private Service_Product: ProductsService,
        private Service_Extension: ExtensionService,
        private Service_Setting: SettingService,
        private _http: HttpClient
    ) { }

    message_duration: any = 3000;
    modelsearch: any = {
        page: 1,
        perpage: 10,
        search: "",
        lang: "lang1"
    }
    product: any = [];
    total:number = 0;
    all_category:any = [];

    category_level1: any = [];
    category_level2: any = [];
    category_level3: any = [];

    category_level1_loading:boolean = false;
    category_level2_loading: boolean = false;
    category_level3_loading: boolean = false;

    category_level1_selected;
    category_level2_selected;
    category_level3_selected;

    allcategory: any = [];
    step: number = 0;
    available_stock = 0;

    ket_product_id = null;


    inf: number = Infinity;
    category_attr: any = {};

    model_categoryAttrs: any = {};
    default_saleProp:any = {
        checked: false,
        comAttId: 0,
        dimension: 1,
        focus: false,
        id: 0,
        isEditting: false,
        localeName: "",
        orderSort: 1
    }

    prop_option_available:any = [

    ]
    prop_enable: number = 0;


    model_image:any = {
        main_image: [
            { url: "", url_jd: "" },
            { url: "", url_jd: "" },
            { url: "", url_jd: "" },
            { url: "", url_jd: "" },
            { url: "", url_jd: "" },
            { url: "", url_jd: "" },
            { url: "", url_jd: "" },
            { url: "", url_jd: "" },
            { url: "", url_jd: "" },
            { url: "", url_jd: "" }
        ]
    }

    jd_image_endpoint: string = "https://img.jd.co.th/n11/";
    // categoryId: number = 1602;
    putShelf: boolean = false;
    model: any = {
        // afterSales: "",
        payFirst: true,
        locale: "th_TH",
        vat: true,
        // categoryAttrs:[],
        brandId: 0,
        canUseJQ: false,
        pcDescription: "",
        appDescription: "",
        sn: false,
        putShelf: 0,
        canUseDQ: false,
        thName: "",
        categoryId: null,
        skuList: [],
        imageList: {

        },
        // updateSkuStockMap
        is15ToReturn: 0,
        warranty: "",

    }
    _option: any = [
        "bold",
        "italic",
        "paragraphFormat",
        "strikeThrough",
        "underline",
        "fontFamily",
        "fontSize",
        "tmtcolors",
        "|",
        "align",
        "insertHR",
        "insertLink",
        "outdent",
        "indent",
        "formatOL",
        "formatUL",
        "|",
        "emoticons",
        "insertTable",
        // "mediamanager",
        //"insertVideo",
        // "column",
        "undo",
        "redo"
    ];
    froalaOption: any = {
        toolbarButtons: this._option,
        toolbarButtonsSM: this._option,
        toolbarButtonsMD: this._option,
        height: 150,
    }

    model_other:any = {
        cod: false
    }
    model_dimension:any = {
        "length": "0",
        "width": "0",
        "height": "0",
        "weight": "0.1",
    }

    ketshop_product:any = {};

    model_sku:any = [];

    async ngOnInit() {
            //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
            //Add 'implements OnInit' to the class.
        if (this.data.state == 'edit') {
            this.step = 2;
            let m = {
                productId: this.data.productId
            }
            $('.theme-loader').fadeIn();
            try {
                let product_jd: any = await this.Service_Extension.getProductJDByID(m);
                console.log(product_jd);
                this.model.categoryId = product_jd.categoryId;
                await this.getCategoryAttr();
                this.model.thName = product_jd.thName;
                this.model.pcDescription = product_jd.dis;
                this.model.appDescription = product_jd.appdis;
                this.model.brandId = product_jd.brandId;
                this.model.is15ToReturn = product_jd.is15ToReturn;
                this.model.warranty = product_jd.warranty;
                this.model.canUseJQ = product_jd.canUseJQ;
                this.model.canUseDQ = product_jd.canUseDQ;
                this.model_other.cod = (product_jd.payFirst) ? false : true;
                this.model.productId = this.data.productId;
                this.model_dimension.length = (+product_jd.skuList[0].length/10).toString();
                this.model_dimension.width = (+product_jd.skuList[0].width/10).toString();
                this.model_dimension.height = (+product_jd.skuList[0].height/10).toString();
                this.model_dimension.weight = product_jd.skuList[0].weight;
                this.model.putShelf =  product_jd.skuList[0].skuStatus;
                this.putShelf = !!product_jd.skuList[0].skuStatus;
                this.prop_enable = product_jd.skuList[0].saleAttrs.length;

                // console.log(this.category_attr.categoryAttrs);
                this.category_attr.categoryAttrs.forEach(attr => {
                    if (product_jd.categoryAttrs && product_jd.categoryAttrs[attr.id]){
                        this.model_categoryAttrs[attr.id] = product_jd.categoryAttrs[attr.id];
                    }
                    
                });


                if (this.prop_enable == 0) {

                } else if (this.prop_enable == 1) {
                    let dimension1 = this.category_attr.saleAttrs.find(x => x.dimension == 1);
                    product_jd.skuList.forEach(jd => {
                        let haveprop1 = dimension1.categoryValues.find(x => x.localeName == jd.saleAttrs[0].localeName);
                        if (!haveprop1) {
                            let obj = _.cloneDeep(this.default_saleProp);
                            obj.localeName = jd.saleAttrs[0].localeName;
                            obj.dimension = 1;
                            dimension1.categoryValues.push(obj);
                        }
                        let m = {
                            property_info: jd.saleAttrs[0].localeName,
                            property_image: [
                                // { url: "", url_jd: "" }
                            ],
                            children: [
                                {
                                    property_info2: "",
                                    sku: jd.outerId,
                                    barcode: jd.upcCode,
                                    price: jd.jdPrice,
                                    instock: jd.stockNum,
                                    ketshop_product_id: jd.ketshop_product_id,
                                    available_stock: jd.available_stock
                                }
                            ]
                        }
                        let img = product_jd.imageList[jd.saleAttrs[0].id];
                        if (jd.saleAttrs[0].id  == 0){
                            console.log('---');
                            console.log(product_jd.imageList, jd.saleAttrs[0].localeName);
                            console.log('---');
                            img = product_jd.imageList['_'+jd.saleAttrs[0].localeName];
                            console.log(img);
                            if(!img){
                                img = product_jd.imageList[jd.saleAttrs[0].localeName];
                            }
                        }
                        if (img) {
                            img.forEach(_image => {
                                m.property_image.push({
                                    url: this.jd_image_endpoint + _image.imgUrl,
                                    url_jd: _image.imgUrl
                                });
                            });
                        }
                        // jd.saleAttrs[0].
                        this.model_sku.push(m);
                    });

                    console.log(this.model_sku);

                } else if (this.prop_enable == 2) {

                    product_jd.skuList.forEach(sku => {
                        sku.saleAttrs = alasql(`SELECT * FROM ? ORDER BY dimension`, [sku.saleAttrs]);
                    });
                    let all_attr = product_jd.skuList.map(x => x.saleAttrs[0]);
                    console.log(product_jd.skuList);
                    let dimension1 = this.category_attr.saleAttrs.find(x => x.dimension == 1);
                    let dimension2 = this.category_attr.saleAttrs.find(x => x.dimension == 2);

                    product_jd.skuList.forEach(sku => {
                        
                        let haveprop1 = dimension1.categoryValues.find(x => x.localeName == sku.saleAttrs[0].localeName);
                        if (!haveprop1){
                            let obj = _.cloneDeep(this.default_saleProp);
                            obj.localeName = sku.saleAttrs[0].localeName;
                            obj.dimension = 1;
                            dimension1.categoryValues.push(obj);
                        }

                        let haveprop2 = dimension2.categoryValues.find(x => x.localeName == sku.saleAttrs[1].localeName);
                        if (!haveprop2) {
                            let obj = _.cloneDeep(this.default_saleProp);
                            obj.localeName = sku.saleAttrs[1].localeName;
                            obj.dimension = 2;
                            dimension2.categoryValues.push(obj);
                        }

                        sku.property_info = sku.saleAttrs[0].localeName;
                        sku.property_id = sku.saleAttrs[0].id;
                    });

                    // console.log(all_attr);
                    let attr = alasql(`SELECT * FROM ? GROUP BY property_info`, [product_jd.skuList]);
                    console.log(attr);
                    attr.forEach(obj => {
                        let m = {
                            property_info: obj.property_info,
                            property_image: [
                                // { url: "", url_jd: "" }
                            ],
                            children: []
                        }
                        let child = product_jd.skuList.filter(x => x.property_info == obj.property_info);
                        child.forEach(ch => {
                            m.children.push({
                                property_info2: ch.saleAttrs[1].localeName,
                                sku: ch.outerId,
                                barcode: ch.upcCode,
                                price: ch.jdPrice,
                                instock: ch.stockNum,
                                 ketshop_product_id: ch.ketshop_product_id,
                                available_stock: ch.available_stock
                            });
                        });

                        let img = product_jd.imageList[obj.property_id];
                        if (obj.property_id == 0) {
                            // img = product_jd.imageList[obj.property_info];
                            img = product_jd.imageList['_' + obj.property_info];
                            if (!img) {
                                img = product_jd.imageList[obj.property_info];
                            }
                        }
                       

                        if (img) {
                            img.forEach(_image => {
                                m.property_image.push({
                                    url: this.jd_image_endpoint + _image.imgUrl,
                                    url_jd: _image.imgUrl
                                });
                            });
                        }
                        this.model_sku.push(m);
                        // console.log(m);
                    });

                    console.log(this.model_sku);
                    // return;
                }

                product_jd.imageList["0000000000"].forEach((mainimg, ind) => {
                    this.model_image.main_image[ind].url = this.jd_image_endpoint + mainimg.imgUrl;
                    this.model_image.main_image[ind].url_jd = mainimg.imgUrl;
                });
            } catch (e) {
                console.log(e);
                this.modalService.error({
                    nzTitle: `Error Cannot Get Product`
                });
            
            }
            $('.theme-loader').fadeOut();
       

            return;
        }else{
            this.getCategory();
            this.allcategory = await this.Service_Product.productMainCategory();
            this.allcategory.unshift({ id: "-1", lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory" });
            this.allcategory.unshift({ id: "", lang1: "All", lang2: "All", lang3: "All", lang4: "All" });
            this.search();

        }
       
    }

    delete(){
        const modal = this.modalService.create({
            nzTitle: 'ลบสินค้า JDCentral',
            nzContent: ModalDeleteJDProductComponent,
            nzStyle: { top: '20px' },
            nzWidth: "30%",
            nzClassName: 'modal-medium',
            nzKeyboard: false,
            nzMaskClosable: false,
            nzFooter: null,
            nzOnOk: (componentInstance) => {
                return componentInstance.ok();
            },
            nzOnCancel: (componentInstance) => {

            },
            nzComponentParams: {
                data: {
                    productId: this.data.productId
                }
            }
        }).afterClose.subscribe((result) => {
            modal.unsubscribe()
            if (result) {
                this.modal.close(true);
            }
        });
    }

    getCategoryAttr() {
        return new Promise((resolve, reject) => {
            let obj = {
                categoryId: this.model.categoryId
            }
            this.Service_Extension.getJDCategoryAttr(obj).then((res: any) => {
                console.log(res);
                this.category_attr = res;
                if (this.category_attr.brands.length > 0) {
                    this.model.brandId = this.category_attr.brands[0].brandId;
                }
                if (this.category_attr.warrantys.length > 0) {
                    this.model.warranty = this.category_attr.warrantys[0];
                }
                this.category_attr.saleAttrs.forEach(o => {
                    o.state = 'normal';
                    o.temp_prop = "";
                });
                this.prop_option_available = [];
                let prop_require = alasql("SELECT COUNT(*) as c FROM ? WHERE required = 1", [this.category_attr.saleAttrs]);
                prop_require = prop_require[0].c;
                switch (prop_require) {
                    case 1:
                        this.prop_option_available.push(
                            { prop_enable: 1, name: "ใช้งาน 1 คุณสมบัติสินค้า" }
                        );
                        if (this.category_attr.saleAttrs.length == 2) {
                            this.prop_option_available.push(
                                { prop_enable: 2, name: "ใช้งาน 2 คุณสมบัติสินค้า" }
                            );
                        }
                        this.prop_enable = 1;
                        break;
                    case 2:
                        this.prop_option_available.push(
                            { prop_enable: 2, name: "ใช้งาน 2 คุณสมบัติสินค้า" }
                        );
                        this.prop_enable = 2;
                        break;
                    case 0:
                        this.prop_option_available.push(
                            { prop_enable: 0, name: "-" }
                        );
                        this.prop_enable = 0;
                        if (this.category_attr.saleAttrs.length == 1) {
                            this.prop_option_available.push(
                                { prop_enable: 1, name: "ใช้งาน 1 คุณสมบัติสินค้า" }
                            );
                        }
                        else if (this.category_attr.saleAttrs.length == 2) {
                            this.prop_option_available.push(
                                { prop_enable: 1, name: "ใช้งาน 1 คุณสมบัติสินค้า" }
                            );
                            this.prop_option_available.push(
                                { prop_enable: 2, name: "ใช้งาน 2 คุณสมบัติสินค้า" }
                            );
                        }
                        break;
                    default:

                        break;
                }
                console.log(prop_require);
                resolve(1);

            }).catch((data) => {
                reject(data);
                console.log(data);
            });
        })

    }

    selectCategory(obj, level) {
        switch (level) {
            case 1:
                this.category_level1_selected = obj;
                this.category_level2_selected = null;
                this.category_level3_selected = null;
                this.category_level3 = [];
                let level2 = alasql('SELECT * FROM ? WHERE categoryRootId = ? GROUP BY categorySecId', [this.all_category, obj.categoryId]);
                let ids_level2 = level2.map(x => x.categorySecId);
                this.getCategoryByIDs({ categoryIds: ids_level2 }, 'category_level2');
                console.log(obj);
                console.log(level2);
                break;
            case 2:
                this.category_level2_selected = obj;
                this.category_level3_selected = null;
                let level3 = alasql('SELECT * FROM ? WHERE categorySecId = ? GROUP BY categoryThirdId', [this.all_category, obj.categoryId]);
                let ids_level3 = level3.map(x => x.categoryThirdId);
                this.getCategoryByIDs({ categoryIds: ids_level3 }, 'category_level3');
                break;
            case 3:
                this.category_level3_selected = obj;
                this.model.categoryId = obj.categoryId;
                break;
            default:
                break;
        }
    }

    getCategory() {
        this.Service_Extension.getJDCategory().then((res: any) => {
            // console.log(res);
            this.all_category = res;

            let category_root = alasql("SELECT * FROM ? GROUP BY categoryRootId", [res]);
            console.log(category_root);
            let ids = category_root.map(x => x.categoryRootId);

            this.getCategoryByIDs({ categoryIds: ids }, 'category_level1');

        }).catch((data) => {
            console.log(data);
        });
    }

    getCategoryByIDs(data, key) {
        this[`${key}_loading`] = true;
        this.Service_Extension.getJDCategoryByIDs(data).then((res: any) => {
            let arr = [];
            for (var k in res.result) {
                if (res.result[k].yn) {
                    arr.push(res.result[k]);
                }
            }
            this[key] = arr;
            this[`${key}_loading`] = false;
            console.log(res);
        }).catch((data) => {
            this[`${key}_loading`] = false;
            console.log(data);
        });
    }

    back(){
        this.step--;
    }

    async next(){
        if (this.step == 1) {
            if (this.category_level3_selected) {
                this.step = 2;
                await this.getCategoryAttr();
                this.initModel(this.ketshop_product);
            }
            return false;
        }
    }

    search() {
        this.Service_Extension.searchKetshopProductToJD(this.modelsearch).then((data: any) => {
            console.log(data);
            this.product = data.data;
            this.total = data.count;
        }).catch((data) => {
            console.log(data);
        });
    }

    pagechage(page) {
        this.modelsearch.page = page;
        this.search();
    }

    openModalAddSKU(parent = null){
        let sku = [];
       this.model_sku.forEach(m => {
           m.children.forEach(child => {
               sku.push(child.sku);
            });
        });
        console.log(sku);
        const modal = this.modalService.create({
            nzTitle: null,
            nzContent: ModalAddSkuFromKetshopComponent,
            // nzStyle: { top: '20px' },
            nzBodyStyle: {
                'padding': '0px',
                'background': '#f6f6f6'
            },
            nzClassName: 'modal-medium',
            nzWrapClassName: 'vertical-center-modal',
            nzWidth: "90%",
            nzKeyboard: false,
            nzMaskClosable: false,
            nzFooter: null,
            nzOnOk: (componentInstance) => {
                return componentInstance.ok();
            },
            nzOnCancel: (componentInstance) => {

            },
            nzComponentParams: {
                data: {
                    state: this.data.state,
                    marketplace: "jd",
                    sku: sku
                }
            }
        }).afterClose.subscribe((result) => {
            modal.unsubscribe()
            console.log(result);
            if (result && result.length > 0) {
                result.forEach(o => {
                    this.add_sku_list(o, parent);
                });
                // this.searchProduct();
            }
        });
    }

    selected(item) {
        this.Service_Product.getProductByID(item.id).then((data: any) => {
            // console.log(data);
            this.ket_product_id = data.id;
            this.available_stock = data.instock;
            this.ketshop_product = data;
            // this.initModel(data);
            this.step++;
        }).catch((data) => {
            console.log(data);
        });
    }

    propEnableChange(prop){
        let old_value = this.prop_enable;
        let new_value = prop;
        if(new_value == 1 && old_value == 2){
            // 2 -> 1
            this.model_sku.forEach(obj => {
                for (let i = obj.children.length - 1; i >= 0; i--) {
                    if(i > 0){
                        obj.children.splice(i,1);
                    }
                }
            });
        }
        console.log('old_value ' + old_value);
        console.log('new_value ' + new_value);
    }

    propChange(obj) {
        console.log(obj.property_info);
        let chk = this.model_sku.filter(x => x.property_info == obj.property_info);
        if (chk.length > 1) {
            this.modalService.error({
                nzTitle: "Duplicate Property " + obj.property_info
            });
            setTimeout(() => {
                obj.property_info = null;
                console.log(obj);
            }, 500)
            return;
        }
    }

    propChange2(item, obj) {
        console.log(item);
        let chk = item.children.filter(x => x.property_info2 == obj.property_info2);
        console.log(chk);
        if (chk.length > 1) {
            this.modalService.error({
                nzTitle: "Duplicate Property " + obj.property_info2
            });
            setTimeout(() => {
                obj.property_info2 = null;
                console.log(obj);
            }, 500)
            return;
        }
    }

    addItem2(input: HTMLInputElement, i): void {

        const value = input.value;
        let obj = _.cloneDeep(this.default_saleProp);
        obj.localeName = value;
        obj.dimension = i + 1;
        if (value.trim() == "") {
        this.modalService.error({
            nzTitle: "Please fill Property"
        });
        return;
        }
        let chk = this.category_attr.saleAttrs[i].categoryValues.find(x => x.localeName == obj.localeName);
        if (chk) {
        this.modalService.error({
            nzTitle: "Duplicate Name: " + obj.localeName
        });
        return;
        }


        this.category_attr.saleAttrs[i].categoryValues.push(obj);
        input.value = "";

        // this.listOfItem = [...this.listOfItem, input.value || `New item ${this.index++}`];

    }
  
  addItem(input: HTMLInputElement,i): void {
   
        const value = input.value;
        let obj = _.cloneDeep(this.default_saleProp);
        obj.localeName = value;
        obj.dimension = i + 1;
        if (value.trim() == "") {
        this.modalService.error({
            nzTitle: "Please fill Property"
        });
        return;
        }
        let chk = this.category_attr.saleAttrs[i].categoryValues.find(x => x.localeName == obj.localeName);
        if (chk) {
        this.modalService.error({
            nzTitle: "Duplicate Name: " + obj.localeName
        });
        return;
        }

        
        this.category_attr.saleAttrs[i].categoryValues.push(obj);
        input.value = "";
    
        // this.listOfItem = [...this.listOfItem, input.value || `New item ${this.index++}`];
        
    }

  removeProp(event, i, saleAttrsIndex){
        if (saleAttrsIndex == 0){
        
        
        } else if (saleAttrsIndex == 1){
        this.model_sku.forEach(o => {
            o.children.filter(x => x.property_info2 == this.category_attr.saleAttrs[saleAttrsIndex].categoryValues[i].localeName).map(x => x.property_info2 = null)
        });
        }
        this.category_attr.saleAttrs[saleAttrsIndex].categoryValues.splice(i, 1);
        // this.listOfItem.splice(i,1);
        // console.log(this.listOfItem);
    }

    addProp2(item) {
        // item.children.push({
        //     property_info2: "",
        //     sku: "",
        //     barcode: "",
        //     price: 0,
        //     instock: 0
        // });
        
    }

    removeSku(i) {
        this.model_sku.splice(i, 1);
    }
    removeSku2(item, i_parent, i_children) {
        if (item.children.length == 1) {
            this.model_sku.splice(i_parent, 1);
        } else {
            item.children.splice(i_children, 1);
        }
    }

    add_sku_list(item,parent){

        if(this.prop_enable == 1){
            if (item.prop_enable > 0){
                let haveprop = this.category_attr.saleAttrs[0].categoryValues.find(x => x.localeName == item.property_info);
                if (!haveprop) {
                    let obj = _.cloneDeep(this.default_saleProp);
                    obj.localeName = item.property_info;
                    obj.dimension = 1;
                    this.category_attr.saleAttrs[0].categoryValues.push(obj);
                }
            }
        } else if (this.prop_enable == 2){
            if (item.prop_enable == 0) {

            } else {
                let haveprop = this.category_attr.saleAttrs[0].categoryValues.find(x => x.localeName == item.property_info);
                if (!haveprop) {
                    let obj = _.cloneDeep(this.default_saleProp);
                    obj.localeName = item.property_info;
                    obj.dimension = 1;
                    this.category_attr.saleAttrs[0].categoryValues.push(obj);
                }
                if(item.prop_enable == 2){
                    let haveprop = this.category_attr.saleAttrs[1].categoryValues.find(x => x.localeName == item.property_info2);
                    if (!haveprop) {
                        let obj = _.cloneDeep(this.default_saleProp);
                        obj.localeName = item.property_info2;
                        obj.dimension = 2;
                        this.category_attr.saleAttrs[1].categoryValues.push(obj);
                    }
                }
            }
        }

        if(parent){
            parent.children.push({
                ketshop_product_id: item.id,
                property_info2: item.property_info2,
                sku: item.sku,
                barcode: item.barcode,
                price: item.price,
                available_stock: item.instock,
                instock: 0
            })

        }else{
            let m = {
                property_info: item.property_info,
                property_image: [
                    { url: item.feature_img, url_jd: "" }
                ],
                children: [{
                    ketshop_product_id: item.id,
                    property_info2: "",
                    sku: item.sku,
                    barcode: item.barcode,
                    price: item.price,
                    available_stock: item.instock,
                    instock: 0
                }]
            };
            this.model_sku.push(m);
        }

        

        console.log(this.model_sku);
    }

    initModel(data){
        console.log(data);
        this.model_sku = [];

        this.model.thName = data.title_lang1;
        this.model.pcDescription = data.short_desc_lang1;
        this.model.appDescription = data.short_desc_lang1;
        // this.model_dimension.length = (+data.size_length * 10).toString();
        // this.model_dimension.width = (+data.size_width * 10).toString();
        // this.model_dimension.height = (+data.size_height * 10).toString();
        // this.model_dimension.weight = (+data.size_height * 0.001).toString();
        this.model_dimension.length = (+data.size_length).toString();
        this.model_dimension.width = (+data.size_width).toString();
        this.model_dimension.height = (+data.size_height).toString();
        this.model_dimension.weight = (+data.weight * 0.001).toString();
        this.model_image.main_image[0].url = data.feature_img;

        if (data.prop_enable == 0) {
            let m = {
                property_info: null,
                property_image: [
                    { url: data.feature_img, url_jd: "" }
                ],
                children: [{
                    ketshop_product_id: data.id,
                    property_info2: "",
                    sku: data.sku,
                    barcode: data.barcode,
                    price: data.price,
                    available_stock: data.instock,
                    instock: 0
                }]
            };

            this.model_sku.push(m);
        }else if(data.prop_enable == 1){
            let prop1 = alasql('SELECT * FROM ? GROUP BY property_info', [data.properties]);
            console.log(prop1);
            let chk = this.prop_option_available.find(x=>x.prop_enable > 0);
            if(chk){
                prop1.forEach(p => {
                    let haveprop = this.category_attr.saleAttrs[0].categoryValues.find(x => x.localeName == p.property_info);
                    if (!haveprop){
                        let obj = _.cloneDeep(this.default_saleProp);
                        obj.localeName = p.property_info;
                        obj.dimension = 1;
                        this.category_attr.saleAttrs[0].categoryValues.push(obj);
                    }
                });

                data.properties.forEach(obj => {
                    let m = {
                        property_info: obj.property_info,
                        property_image: [
                            { url: obj.feature_img, url_jd: "" }
                        ],
                        children: [{
                            ketshop_product_id: obj.id,
                            property_info2: "",
                            sku: obj.sku,
                            barcode: obj.barcode,
                            price: obj.price,
                            available_stock: obj.instock,
                            instock: 0
                        }]
                    };

                    this.model_sku.push(m);
                });
              

            }
           
            console.log(this.category_attr.saleAttrs);
        }else if(data.prop_enable == 2){
            let prop1 = alasql('SELECT * FROM ? GROUP BY property_info', [data.properties]);
            console.log(prop1);
            for (let i = 0; i < data.properties.length; i++){
                let current = data.properties[i];
                
            }
        }
        // this.model_sku.push({
        //     property_info: "",
        //     property_image: [
        //         { url: "", url_jd: "" }
        //     ],
        //     children: [{
        //         property_info2: "",
        //         sku: "",
        //         barcode: "",
        //         price: 0,
        //         instock: 0
        //     }]
        // });
       
    }

    validateSKU(obj):boolean{
    let chk = true;
        if(this.prop_enable == 2 && obj.propert_info2 == ""){
        this.modalService.error({
            nzTitle: "กรุณากรอก คุณสมบัติ 2 ให้ครบถ้วน"
        });
        return false;
        }
        if (obj.sku == "") {
            this.modalService.error({
            nzTitle: "กรุณากรอก SKU"
        });
        return false;
        }
        if(obj.barcode == ""){
            this.modalService.error({
            nzTitle: "กรุณากรอก Barcode"
        });
        return false;
        }
        if (obj.price == "") {
            this.modalService.error({
            nzTitle: "กรุณากรอก ราคา"
        });
        return false;
        }
        // if (obj.instock == "") {
        //   this.modal.error({
        //     nzTitle: "กรุณากรอก จำนวน stock"
        //   });
        //   return false;
        // }

        return chk
    }

    async toDataURL(imageurl) {
        console.log(imageurl);
        // if (/^http/g.test(imageurl)) {
        //     imageurl = imageurl;
        // } else {
        //     imageurl = `${location.origin}${imageurl}`;
        // }
    
        let base64 = await this.Service_Extension.getBase64Image({ img: imageurl});
        
        return base64;
        // return new Promise(async (resolve, reject) => {
        //     let blob = await this._http.get<Blob>(imageurl, { responseType: 'blob' as 'json' }).toPromise();
        //     const reader = new FileReader()
        //     reader.onloadend = () => resolve(reader.result)
        //     reader.onerror = reject
        //     reader.readAsDataURL(blob)
        // })

    }

    async ok(){

        console.log(this.model_categoryAttrs);
        for (var key in this.model_categoryAttrs) {
            console.log(this.model_categoryAttrs[key]);
            if (this.model_categoryAttrs[key] == null) {
                delete this.model_categoryAttrs[key];

            } else if (this.model_categoryAttrs[key].length == 0) {
                delete this.model_categoryAttrs[key];
            }
        }

        this.model.categoryAttrs = this.model_categoryAttrs;
        this.model.skuList = [];
        this.model.imageList = {};
        this.model.payFirst = (this.model_other.cod) ? false : true;
        this.model.putShelf = (this.putShelf) ? 1 : 0;

        if (this.model_dimension.length == "") {
            this.modalService.error({
                nzTitle: "กรุณากรอก ความยาว (cm.)"
            });
            return false;
        }
        if (this.model_dimension.width == "") {
            this.modalService.error({
                nzTitle: "กรุณากรอก ความกว้าง (cm.)"
            });
            return false;
        }
        if (this.model_dimension.height == "") {
            this.modalService.error({
                nzTitle: "กรุณากรอก ความสูง (cm.)"
            });
            return false;
        }
        if (this.model_dimension.weight == "") {
            this.modalService.error({
                nzTitle: "กรุณากรอก น้ำหนัก (kg.)"
            });
            return false;
        }
        if (this.model_sku.length == 0) {
            this.modalService.error({
                nzTitle: "กรุณาเพิ่ม SKU"
            });
            return false;
        }

        let _length = +this.model_dimension.length * 10;
        let _width = +this.model_dimension.width * 10;
        let _height = +this.model_dimension.height * 10;
        let _weight = +this.model_dimension.weight;

        let skuList: any = {
            "outerId": "",
            "jdPrice": "",
            "stockNum": 0,
            "skuStatus": 1,
            "productCode": "",
            "upcCode": "",
            "length": `${_length}`,
            "width": `${_width}`,
            "height": `${_height}`,
            "weight": `${_weight}`,
            "saleAttrs": []
        };

        let main_image = this.model_image.main_image.filter(x => x.url != "");
        if (main_image.length == 0) {
            this.modalService.error({
                nzTitle: "กรุณาใส่รูปภาพสินค้าหลัก"
            });
            return false;
        }

        for (let i = 0; i < this.model_sku.length; i++) {
            let current = this.model_sku[i];
            let orderSort = i;

            if (this.prop_enable == 0) {

            } else if (this.prop_enable == 1) {


                if (current.property_info == "") {
                    this.modalService.error({
                        nzTitle: "กรุณากรอก คุณสมบัติ 1 ให้ครบถ้วน"
                    });
                    return false;
                }

                let property_image = current.property_image.filter(x => x.url != "");
                if (property_image.length == 0) {
                    this.modalService.error({
                        nzTitle: `กรุณาใส่รูปภาพสินค้า ${this.category_attr.saleAttrs[0].name} ${current.property_info}`
                    });
                    return false;
                }

                let children = current.children[0];
                let chk = this.validateSKU(children);
                if (!chk) { return false; }
                let _skuList = _.cloneDeep(skuList);
                _skuList.outerId = `${children.sku}`;
                _skuList.jdPrice = `${children.price}`;
                _skuList.ketshop_product_id = children.ketshop_product_id;
                _skuList.stockNum = children.instock;
                _skuList.upcCode = `${children.barcode}`;
                let attr1 = this.category_attr.saleAttrs[0].categoryValues.find(x => x.localeName == current.property_info);
                if (!attr1) {
                    this.modalService.error({
                        nzTitle: `Attribute ${current.property_info} not found`
                    });
                    return false;
                }
                attr1.orderSort = orderSort;
                _skuList.saleAttrs.push(attr1);
                this.model.skuList.push(_skuList);
            } else if (this.prop_enable == 2) {
                if (current.property_info == "") {
                    this.modalService.error({
                        nzTitle: "กรุณากรอก คุณสมบัติ 1 ให้ครบถ้วน"
                    });
                    return false;
                }

                let property_image = current.property_image.filter(x => x.url != "");
                if (property_image.length == 0) {
                    this.modalService.error({
                        nzTitle: `กรุณาใส่รูปภาพสินค้า ${this.category_attr.saleAttrs[0].name} ${current.property_info}`
                    });
                    return false;
                }

                for (let j = 0; j < current.children.length; j++) {
                    let children = current.children[j];
                    let chk = this.validateSKU(children);
                    if (!chk) { return false; }
                    let _skuList = _.cloneDeep(skuList);
                    _skuList.outerId = `${children.sku}`;
                    _skuList.jdPrice = `${children.price}`;
                    _skuList.stockNum = children.instock;
                    _skuList.ketshop_product_id = children.ketshop_product_id;
                    _skuList.upcCode = `${children.barcode}`;
                    let attr1 = this.category_attr.saleAttrs[0].categoryValues.find(x => x.localeName == current.property_info);
                    if (!attr1) {
                        this.modalService.error({
                            nzTitle: `Attribute ${current.property_info} not found`
                        });
                        return false;
                    }
                    _skuList.saleAttrs.push(attr1);
                    let attr2 = this.category_attr.saleAttrs[1].categoryValues.find(x => x.localeName == children.property_info2);
                    if (!attr2) {
                        this.modalService.error({
                            nzTitle: `Attribute ${children.property_info2} not found`
                        });
                        return false;
                    }
                    _skuList.saleAttrs.push(attr2);
                    this.model.skuList.push(_skuList);
                }

            }
        }// end for validate sku

        //// upload Image ////
        console.log(main_image);
        $('.theme-loader').fadeIn();
        for (let i = 0; i < main_image.length; i++) {
            let current = main_image[i];
            if (current.url_jd) { continue; }
            
            try {
                let img: any = await this.Service_Extension.uploadImageJD({ img: current.url });
                console.log(img);
                current.url_jd = img;

            } catch (e) {
                $('.theme-loader').hide();
                console.log(e.error.message);
                this.modalService.error({
                    nzTitle: `${e.error.message}`
                });
                return false;
            }

        }

        for (let i = 0; i < this.model_sku.length; i++) {
            let current = this.model_sku[i];

            for (let j = 0; j < current.property_image.length; j++) {
                let img = current.property_image[j];

                if (img.url_jd) { continue; }
               
                
                try {
                    let imgjd: any = await this.Service_Extension.uploadImageJD({ img: img.url });
                    console.log(imgjd);
                    img.url_jd = imgjd;

                } catch (e) {
                    $('.theme-loader').hide();
                    console.log(e);
                    console.log(e.error.message);
                    this.modalService.error({
                        nzTitle: `${e.error.message}`
                    });
                    return false;
                }
            }

        }

        try {
            this.model.imageList["0000000000"] = [];
            main_image.forEach((obj, index) => {
                this.model.imageList["0000000000"].push({
                    colorId: "0000000000",
                    imgUrl: obj.url_jd,
                    index: (index + 1)
                });
            });
        } catch (e) {
            console.log(e);
        }

        if (this.prop_enable > 0) {

            try {
                this.model_sku.forEach((obj, index) => {
                    let saleattr = this.category_attr.saleAttrs[0].categoryValues.find(x => x.localeName == obj.property_info);
                    console.log(saleattr);
                    let _key = '_'+obj.property_info;
                    if (saleattr && saleattr.id != 0) {
                        _key = saleattr.id
                    }

                    this.model.imageList[`${_key}`] = [];

                    obj.property_image.forEach((o, i) => {
                        let m = {
                            colorId: `${_key}`,
                            imgUrl: o.url_jd,
                            index: (i + 1)
                        }
                        // if()
                        this.model.imageList[`${_key}`].push(m)
                    });
                });
            } catch (e) {
                console.log(e);
            }
        }

        
        let obj = {
            prop_enable: this.prop_enable,
            jd: this.model
        }
       if(this.data.state == 'create'){
           this.Service_Extension.createProductJD(obj).then((res) => {
               $('.theme-loader').hide();
               this.modal.close(true);
           }).catch((data) => {
               $('.theme-loader').hide();
               this.modalService.error({
                   nzTitle: `${data.error.message}`
               });
           });
       }else{
     
           this.Service_Extension.updateProductJD(obj).then((res) => {
               $('.theme-loader').hide();
               this.modal.close(true);
           }).catch((data) => {
               $('.theme-loader').hide();
               this.modalService.error({
                   nzTitle: `${data.error.message}`
               });
           });
       }
       
        console.log(JSON.stringify(obj));


    }
}