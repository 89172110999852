import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class KetHasDataDomService {

  constructor(
  ) { }

  random_string(amount) {
    var text = "";
    var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < amount; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }


  uid() {
    var date: any = new Date();
    var year:any = date.getUTCFullYear() - 2000;
    var mon:any = date.getUTCMonth() + 1;
    var day:any = date.getUTCDate();
    var hour:any = date.getUTCHours();
    var min:any = date.getUTCMinutes();
    var sec:any = date.getUTCSeconds();
    var milisec:any = date.getMilliseconds();
    var random = this.random_string(6);
    if (milisec < 10) {
        milisec = "0" + (milisec.toString());
    }
    if (sec < 10) {
        sec = "0" + (sec.toString());
    }
    if (min < 10) {
        min = "0" + (min.toString());
    }
    if (hour < 10) {
        hour = "0" + (hour.toString());
    }
    if (day < 10) {
        day = "0" + (day.toString());
    }
    if (mon < 10) {
        mon = "0" + (mon.toString());
    }
    if (year < 10) {
        year = "0" + (year.toString());
    }
    var numStr:any = year.toString() + mon.toString() + day.toString() + hour.toString() + min.toString() + sec.toString() + milisec.toString();
    var num:any = parseInt(numStr);
    var stkNum:any = num.toString(36);
    stkNum += random;
    return stkNum;
}

}