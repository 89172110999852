import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KetLangsPipe } from './langs.pipe';

@NgModule({
  declarations: [
    KetLangsPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    KetLangsPipe
  ]
})
export class KetLangsModule { }