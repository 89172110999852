import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { FeatureService } from '@cms/services/feature.service';

@Injectable({
  providedIn: 'root'
})
export class KetFeatureLandingService {

  constructor(
    private _http:HttpClient,
    private featureService: FeatureService
  ) { }

  private feature:any = null;
  private date_time:any;

  async _init(){
    console.log('_init feature ');
    let date:any = await this.getDateTime();
    this.date_time = date.date_time;
    let obj = await this.featureService.getAllFeature();
    this.feature = obj;
    Object.freeze(this.feature);
    console.log(this.feature);
  }

  getDateTime(){
      return this._http.get('/util/datetime').toPromise();
  }


  async getFeature(key = null){
    if(this.feature === null){
        await this._init();
    }
    if(key){
        let _f = this.feature.find(x => x.name == key);
        if(key == "ORDER_MARKETPLACE"){
          let shopee = this.feature.find(x => x.name == "SHOPEE");
          let lazada = this.feature.find(x => x.name == "LAZADA");
          let jd = this.feature.find(x => x.name == "JDCENTRAL");
          
          if (shopee.value == "0" && lazada.value == "0" && jd.value == "0"){
            console.log('ordermarketplace return false')
            return false;
          }else{
            console.log('ordermarketplace return true')
            return true;
          }
          
        }
        if(key == "SALEPAGE"){
          if(!_f){
            return false;
          }
        }
        if(!_f){
            return true;
        }
        if (_f.name == "FBCHAT" || _f.name == "PROMOTION" ||
            _f.name == "PREMADE" || _f.name == "BLOG_PAGE" ||
            _f.name == "PRODUCT_PAGE" || _f.name == "PREMADE" ||
          _f.name == "CUSTOM_ELEMENT" || _f.name == "LINECHAT" ||
          _f.name == "PROMOTION" || _f.name == "MEMBER_POINT" ||
          _f.name == "FREE_ITEM" || _f.name == "SECTION_BOOTSTRAP" ||
          _f.name == "OMNICHAT"){
            if (_f.value == "1"){
                return true;
            } else if (_f.value == "0"){
                return false;
            }else {
              let daypass = moment(this.date_time).diff(moment(_f.value), 'second');
                if (daypass > 0) {
                    return false;
                }else {
                    return true;
                }
            }
        }else{
            if (_f.value == "1") {
                return true;
            } else if (_f.value == "0") {
                return false;
            }
        }

    }
    return this.feature;
}

}