import { Component, OnInit,Input } from '@angular/core';
import { UtilService } from '@cms/services/util.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AuthenService } from '@cms/services/authen.service';

@Component({
  selector: 'app-modal-export-order',
  templateUrl: './modal-export-order.component.html',
  styleUrls: ['./modal-export-order.component.css']
})
export class ModalExportOrderComponent implements OnInit {
  @Input() data:any;
  currentdatetime: any;
  rangePicker: any = [];
  access_token: string;
  constructor(
    private Service_Util: UtilService,
    private _authen: AuthenService
  ) { }

  model:any = {
    startdate: "",
    enddate: "",
    status: "",
    paymenttype: "",
    type: 1
  }
  api_path:string;

  async ngOnInit() {
    this.api_path = environment.api_path;
    this.access_token = this._authen.getToken().access_token;
    this.currentdatetime = await this.Service_Util.getDateTime();
    this.rangePicker = [moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss'), moment(this.currentdatetime.date).endOf('day').format("YYYY-MM-DD HH:mm:ss")];
    this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD');
    this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD');
    if (this.data.created_by){
      this.model.created_by = this.data.created_by;
    }
    console.log(this.rangePicker);
  }

  dateRangeChange(){
    console.log(this.rangePicker);
    try {
      this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD HH:mm:ss');
      this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD HH:mm:ss');
    } catch (e) {
      this.model.startdate = moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss');
      this.model.enddate = moment(this.currentdatetime.date).format('YYYY-MM-DD HH:mm:ss');
    }
    console.log(this.model);
  }

  

  ok(){
    $("form#order-export").submit();
  }

}
