import {
  Directive,
  ElementRef,
  AfterViewInit,
  Input,
  Renderer,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[tmt-gen-style]'
})
export class TmtGenStyleDirective implements AfterViewInit, OnChanges{
  constructor(private el: ElementRef, private render: Renderer) {}

  @Input() data:any

  ngAfterViewInit(){

    //this.render.setElementStyle(this.el.nativeElement, 'font-family', this.font)

  }

  ngOnChanges(changes:SimpleChanges){
    console.log(changes)
    // if(changes.font){
    //   //console.log('font changed', changes.font);
    //   if(changes.font.previousValue !== undefined){
    //     this.font = changes.font.currentValue;
    //     //console.log('change')
    //     this.render.setElementStyle(this.el.nativeElement, 'font-family', this.font)
    //   }
    // }
  }



}
