import { Component, OnInit, Input } from '@angular/core';
import { OrderService } from '@cms/services/order.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ConfirmPaymentService } from '@cms/services/confirmpayment.service';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-create-confirmpayment',
  templateUrl: './modal-create-confirmpayment.component.html',
  styleUrls: ['./modal-create-confirmpayment.component.css']
})
export class ModalCreateConfirmpaymentComponent implements OnInit {

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Order: OrderService,
    private Service_ConfirmPayment:ConfirmPaymentService
  ) { }
  
  @Input() data: any;
  @Input() ordercode: any = null;

  messageDuration:number = 3000;
  order_list:any[] = [];
  tmporder:any;
  date:any;
  time:any;
  allBank:any = [];
  defaultOpenValue:Date = new Date(0,0,0,0,0,0);
  model:any = {
    ordercode: "",
    date: "",
    time: "",
    picture: "",
    value: "",
    bank: "",
    name: "",
    lastname: "",
    tel: "",
    remark: "",
    bank_data: {}
  }

  file: File = null;

  async ngOnInit() {
    this.allBank = await this.Service_ConfirmPayment.allBank();

    try{
      this.model.bank_data = (this.allBank.length > 0)? this.allBank[0]:null;
      this.bankChange(this.model.bank_data);
    }catch(err){}

    if(this.ordercode){
      this.searchOrder(this.ordercode);
    }else{
      this.searchOrder("");
    }
  }

  orderSelect(event){
    console.log(event);
    this.model.ordercode = event.ordercode;
    this.model.name = event.bill_name;
    this.model.lastname = event.bill_lastname;
    this.model.tel = event.bill_tel;
    this.model.value = event.totals;
  }

  searchOrder(event) {
    event = event || "";
    let obj = {
      startdate: "",
      enddate: "",
      status: "1",
      ordercode: event,
      email: "",
      trackcode: "",
      search: "",
      paymenttype: "",
      created_by: "",
      "page": 1,
      "perpage": 10
    }
    if(this.ordercode){
      obj.status = ""
    }
    this.Service_Order.search(obj).then((res: any) => {
      this.order_list = res.data as any[];
      // console.log(this.customer_list);
      if(this.ordercode){
        let obj = this.order_list.find(x=>x.ordercode == this.ordercode)
        this.orderSelect(obj)
      }
    }).catch(data => {
      console.log('data', data)
      console.log(data.error.message);
    });
  }
  bankChange(event){
    this.model.bank = `${event.name} ${event.acnum} ${event.account}`;
    console.log(event);
  }

  timeChange(event){
    // console.log(event);
    if (!event) {
      this.model.time = "";
    } else {
      this.model.time = moment(event).format('HH:mm:ss');
    }
  }

  dateChange(event) {
    // console.log(event);
    if (!event) {
      this.model.date = "";
    } else {
      this.model.date = moment(event).format('YYYY-MM-DD');
    }
  }

  async ok() {
    // console.log(this.model)
    // return false;
    if(this.model.ordercode.trim() == ""){
      this.message.error("โปรดกรอกหมายเลขการสั่งซื้อ", { nzDuration: this.messageDuration })
      return false;
    } else if (this.model.date.trim() == ""){
      this.message.error("โปรดกรอกวันที่โอน", { nzDuration: this.messageDuration })
      return false;
    } else if (this.model.time.trim() == "") {
      this.message.error("โปรดกรอกเวลาที่โอน", { nzDuration: this.messageDuration })
      return false;
    } else if (!this.model.value) {
      this.message.error("โปรดกรอกจำนวนเงิน", { nzDuration: this.messageDuration })
      return false;
    }
    // console.log(JSON.stringify(this.model));
    // return false;
    $('.theme-loader').fadeIn();
    if(this.file){
      const formData: FormData = new FormData();
      formData.append('file', this.file);

      try{
        let upload:any = await this.Service_ConfirmPayment.uploadSlip(formData);
        console.log(upload.img);
        this.model.picture = upload.img;
      }catch(e){
        $('.theme-loader').fadeOut();
        this.message.error("Cannot Upload Slip Image",{nzDuration: 4000})
        return
      }

    }

    console.log(this.model)
    this.Service_ConfirmPayment.createConfirmpaymentBackEnd(this.model).then((res:any)=>{
      $('.theme-loader').fadeOut();
      this.modal.close();
    }).catch((data:any)=>{
      $('.theme-loader').fadeOut();
      this.message.error(data.error.message,{nzDuration: this.messageDuration});
    });

    return false;
  }

  handleFileInput(files: FileList){
    this.file = files.item(0);
  }

}
