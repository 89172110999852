import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '@cms/services/products.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-search-by-tag',
  templateUrl: './modal-search-by-tag.component.html',
  styleUrls: ['./modal-search-by-tag.component.css']
})
export class ModalSearchByTagComponent implements OnInit {

  @Input() data:any = [];
  allTag:any = [];
  selected:any = [];

  constructor(
    private Service_Product:ProductsService,
    private modal: NzModalRef
  ) { }

  async ngOnInit() {
    //console.log(this.data)
    this.allTag = await this.Service_Product.listProductTag();
    this.selected = [...this.data.tags];
    console.log(this.selected);
    this.allTag.forEach(data => {
      data.active = false;
      this.selected.forEach(tag => {
         if(tag == data.id){
           data.active = true;
         }
       })
     });

  }

  selectedTag(obj){
   
    if(obj.active){
      for(let i=0;i<this.selected.length;i++){
        if(this.selected[i] == obj.id){
          this.selected.splice(i,1);
        }
      }
    }else{
      this.selected.push(obj.id);
    }
    obj.active = !obj.active;
  }

  ok(){
    console.log(this.selected);
    this.modal.close(this.selected);
  }

}
