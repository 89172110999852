import {
  Directive,
  ElementRef,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  Renderer,
  HostListener,
  ViewChild,
  OnDestroy
} from '@angular/core';
import _ from "underscore";

@Directive({
  selector: '[resizeAbleColumns],[resizeAbleColumn]'
})
export class resizeAbleColumnDirective implements AfterViewInit, OnDestroy{

  @Output() ResizeOnDragStart = new EventEmitter();
  @Output() ResizeOnDragEnd = new EventEmitter();
  @Output() ResizeOnDrag = new EventEmitter();

  @Input() resizeAbleColumn:any;
  @Input() data:any;

  @Input() eventClick:string;

  @Input() state_top_bottom:boolean = false;

  constructor(private el: ElementRef, private render: Renderer) {}

  ngAfterViewInit(){
    //console.log('Init Custom')
    //console.log(this.el.nativeElement)
  }

  ngOnDestroy(){
    this.cleanMouseEvents();
  }

  state = {
    isDragging: false
  };

  isMouseDown = null;
  startPosition = null;
  currentPosition = null;
  MIN_COL_WIDTH: number = 60;
  resizeHandleEl: any = undefined;
  startHeight: number = 10;


  sumWidth:any = undefined;

  lastDelta:any = {
    x: null,
    y: null
  };

  @HostListener('click', ['$event'])
  onMouseClick(event:any) {
    //console.log(event);
    if(this.resizeAbleColumn !== undefined && event.target.className == this.eventClick){
      event.stopPropagation();
    }
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event:any) {
   if(this.resizeAbleColumn !== undefined && event.target.className == this.eventClick){

    if(this.state_top_bottom){
      //console.log(event);
      this.handleMouseDown(event);
      return;
    }

    this.resizeHandleEl = $(event.target).closest('.resize-handle')[0];
    this.resizeHandleEl.style.opacity = 1;

    $(this.el.nativeElement).closest('[resizeablecolumns]')[0].style.cursor = "col-resize";

    this.handleMouseDown(event);
   }

  }


  getClientOffset(event:any){
    return {
      x: event.clientX,
      y: event.clientY
    }
  };


  handleClick(e:any){
    e.stopPropagation();
  };

  handleMouseDown(e:any){
    // left click only
    if (e.button !== 0) {
      return;
    }

    //console.log(e)

    this.isMouseDown = true;
    this.currentPosition = this.startPosition = this.getClientOffset(e);

    this.ResizeOnDragStart.emit();

    if(this.state_top_bottom){

      this.startHeight = this.data['desktop']["paddingBottom"]?this.data['desktop']["paddingBottom"]:10;

    }

    this.initMouseEvents();

    //console.log(this.currentPosition);

    this.handleResize();

    this.state.isDragging = true;
  };

  handleMouseMove(e:any){
    this.currentPosition = this.getClientOffset(e);
  };

  handleMouseUp(e:any){

    this.isMouseDown = false;
    this.startPosition = null;
    this.lastDelta = {
      x: null,
      y: null
    };

    this.state.isDragging = false;
    this.cleanMouseEvents();
    this.ResizeOnDragEnd.emit();

  };

  handleResize = () => {
    if (this.isMouseDown) {
      requestAnimationFrame(this.handleResize);

      const deltaX = this.currentPosition.x - this.startPosition.x;
      const deltaY = this.currentPosition.y - this.startPosition.y;

      const lastDeltaX = this.lastDelta.x;
      const lastDeltaY = this.lastDelta.y;

      if (deltaX !== lastDeltaX || deltaY !== lastDeltaY) {
        this.lastDelta = {
          x: deltaX,
          y: deltaY
        };

        if(this.state_top_bottom){
          //console.log(deltaY);
          this.onResizeY(deltaY);
        }else{
          this.onResize(deltaX);
        }


      }
    }
  };

  getResizingColumns(){
    return _.map($(this.el.nativeElement).closest('[resizeablecolumns]')[0].children, elem => elem.getBoundingClientRect().width );
  }

  toDecimalTen(number){
    return Math.floor(number * 10)/10;
  }

  onResizeY(y:number){
    var sumHeight = (this.startHeight+y);
    if(sumHeight < 10){
      sumHeight = 10;
    }

    this.data['desktop']["paddingBottom"] = sumHeight;

    ///console.log(sumHeight);

  }


  last_cursor_position_x = 0;
  columnWidths:any;
  onResize(x:number){

    //console.time('onResize')

    this.columnWidths = this.columnWidths || this.getResizingColumns();
    this.last_cursor_position_x = x;
    var firstColumnIndex = this.resizeAbleColumn-1;
    var secondColumnIndex = this.resizeAbleColumn;
    let col1Width = this.columnWidths[firstColumnIndex] + x;
    let col2Width = this.columnWidths[secondColumnIndex] - x;


    const sum = _.reduce(this.columnWidths, (memo, num) => memo + num, 0);
    const widthOtherColumns = _.reduce(
    _.reject(
      this.columnWidths,
        (item, key) =>
        key === firstColumnIndex || key == secondColumnIndex
      ),
      (memo, num) => memo + num,
      0
      );
    col1Width = Math.min(
      Math.max(this.MIN_COL_WIDTH, col1Width),
      sum - widthOtherColumns - this.MIN_COL_WIDTH
    );
    col2Width = Math.min(
      Math.max(this.MIN_COL_WIDTH, col2Width),
      sum - widthOtherColumns - this.MIN_COL_WIDTH
    );

    var firstColumnWidth:any = this.toDecimalTen((col1Width * 100) / sum);
    var secondColumnWidth:any = this.toDecimalTen((col2Width * 100) / sum);


    if(!this.sumWidth){
      this.sumWidth = (this.data[firstColumnIndex].columnWidth+this.data[secondColumnIndex].columnWidth);
    }


    secondColumnWidth = this.sumWidth-(firstColumnWidth+secondColumnWidth)+secondColumnWidth;
    secondColumnWidth = Number(secondColumnWidth.toFixed(1));
    firstColumnWidth = Number(firstColumnWidth.toFixed(1));


    //console.log(this.data)


    this.data[firstColumnIndex].columnWidth = firstColumnWidth;
    this.data[secondColumnIndex].columnWidth = secondColumnWidth;

    this.ResizeOnDrag.emit({
      fistElWidth: firstColumnWidth,
      curentElWidth: secondColumnWidth,
    });

    //console.timeEnd('onResize')
  }

  // @HostListener('window:mouseup', ['$event'])
  // onMouseUp(e){
  //   this.handleMouseUp(e)
  // }

  // @HostListener('window:mousemove', ['$event'])
  // onMouseMove(e){
  //   this.handleMouseMove(e)
  // }


  initMouseEvents(){

    jQuery(window).on("mousemove", (e)=>this.handleMouseMove(e));
    jQuery(window).on("mouseup", (e)=>this.handleMouseUp(e));

  }

  cleanMouseEvents() {
    jQuery(window).off("mousemove", (e)=>this.handleMouseMove(e));
    jQuery(window).off("mouseup", (e)=>this.handleMouseUp(e));
    jQuery(window).off("mousedown", (e)=>{});
    jQuery(window).off("click", (e)=>{});
    // angular.element(window).off("mousemove", $scope.handleMouseMove);
    // angular.element(window).off("mouseup", $scope.handleMouseUp);


    this.last_cursor_position_x = 0;
    this.columnWidths = undefined;
    this.sumWidth = undefined;

    try{
      $(this.el.nativeElement).closest('[resizeablecolumns]')[0].style.cursor = "auto";
      this.resizeHandleEl.style.opacity = 0;
    }catch{}

    this.resizeHandleEl = undefined;

    //console.log('Stop Drag Resize');
  }

}
