import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from '@cms/services/util.service';

@Component({
  selector: 'select-fonts',
  template: `
    <nz-select
      style="width: 100%;"
      nzPlaceHolder="Select a font"
      [(ngModel)]="fontModel"
      (ngModelChange)="onModelChange()"
    >
    <nz-option
        nzValue=""
        nzLabel="Default"
      ></nz-option>
      <nz-option
        nzCustomContent
        *ngFor="let obj of fonts"
        [nzValue]="obj.value"
        [nzLabel]="obj.name"

      > <span [font]="obj.value"> {{obj.name}} </span> </nz-option>
    </nz-select>
  `
})
export class SelectFontsComponent implements OnInit {

  @Output() font =  new EventEmitter();
  @Input() data:string;

  constructor(
    private _util: UtilService,
  ) {

   }

  fonts:any = [];
  fontModel:string;

  ngOnInit() {
    this.fontModel = this.data || '';
    this._util.getFonts().then((res:any) =>{
      this.fonts = res.data;
    });
  }

  onModelChange(){
    this.font.emit(this.fontModel);
  }



}
