import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { cloneDeep } from "lodash";
import { google } from 'google-maps';
declare var google: google;
@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements OnInit, AfterViewInit, OnChanges {

    @ViewChildren('gmapedit') gmapEdit: QueryList<any>;

    @Input() gHeight: number = 400
    @Input() gLat: number = 13.8053227
    @Input() gLng: number = 100.5848104

    mapedit: google.maps.Map;
    markeredit: any;

    @Output() onChange = new EventEmitter()

    @Input() data: any = {
        "lat": 13.8053227,
        "lng": 100.5848104
    }

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.renderMap()
    }

    renderMap(){

        if (this.gmapEdit.first) {
          let myLatlng = new google.maps.LatLng(this.data.lat, this.data.lng);
          let mapProp = {
            center: myLatlng,
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: !this.data.disablescroll
          };
          this.mapedit = new google.maps.Map(this.gmapEdit.first.nativeElement, mapProp);
          this.markeredit = new google.maps.Marker({
            position: myLatlng,
            map: this.mapedit,
            draggable: true,
            title: ""
          });

          google.maps.event.addListener(this.markeredit, 'dragend', function (e) {
            this.markerDragEnd(e.latLng.lat(), e.latLng.lng());
          }.bind(this));

          google.maps.event.addListener(this.mapedit, 'zoom_changed', function (e) {
           // this.zoomChange(this.mapedit.getZoom());
          }.bind(this));
        }


      }

    state_markerDragEnd: boolean = false
    markerDragEnd(lat:any,lng:any){
        this.state_markerDragEnd = true
        this.data.lat = lat;
        this.data.lng = lng;
        this.gLat = lat
        this.gLng = lng
        setTimeout(() => {
            this.onChange.emit(cloneDeep(this.data))
            setTimeout(()=>{
                this.state_markerDragEnd = false
            },200)
        },100);
    }

    ngOnChanges(changes: SimpleChanges){

        if(changes.gLat && !this.state_markerDragEnd){
          if(changes.gLat.previousValue !== undefined){
            setTimeout(()=>{
                if(changes.gLat){
                    //this.data.lng = changes.gLat.currentValue;
                    this.renderMap()
                }
            },100)
          }
        }

       if(changes.gLng && !this.state_markerDragEnd){
          if(changes.gLng.previousValue !== undefined){
            setTimeout(()=>{
                if(changes.gLng){
                   // this.data.lng = changes.gLng.currentValue;
                    this.renderMap()
                }
            },100)
          }
        }

        if(changes.data){
            if(changes.data.previousValue !== undefined){
              setTimeout(()=>{
                this.renderMap()
              },100)
            }
          }


      }


}
