import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { cloneDeep } from "lodash"

let PAGE_OPTIONS:any[] = []

@Injectable({
  providedIn: "root",
})
export class SettingService {
  constructor(private _http: HttpClient) {}

  getNotify() {
    return this._http.get("/setting/notify").toPromise();
  }

  saveNotify(obj: any) {
    return this._http.put("/setting/notify", obj).toPromise();
  }


  getVerifyHtml() {
    return this._http.get("/setting/verifyHtml").toPromise();
  }

  deleteVerifyHtml(obj: any) {
    return this._http.delete(`/setting/verifyHtml/${obj.id}`).toPromise();
  }



  async searchOption(obj: any,force_get:boolean = false) {
    // console.log(obj)
    //{pop_key: []}

    if (force_get){
      return this._http.post("/util/searchOption", obj).toPromise();
    }
    
    let rs: any[] = [];
    let keys = {
      pop_key: []
    }
    return new Promise(async (resolve, reject) => {
      try {
        // console.log(option)
        for (let key of obj.pop_key) {

          if (PAGE_OPTIONS.find((x) => x.pop_key == key)) {
            rs.push(PAGE_OPTIONS.find((x) => x.pop_key == key));
          }else{
            keys.pop_key.push(key)
          }

        }
        if (obj.pop_key.length == rs.length) {
          return resolve(cloneDeep(rs));
        }
        try {
          let resp:any = await this._http
            .post("/util/searchOption", keys)
            .toPromise();

            rs = [...rs, ...resp]

            for(let _new of resp){
              if (!PAGE_OPTIONS.find((x) => x.pop_key == _new.pop_key)) {
                PAGE_OPTIONS.push(_new)
              }
            }

          return resolve(cloneDeep(rs));
        } catch (err) {
          return reject(err);
        }
      } catch (e) {
        try {
          let resp:any = await this._http
            .post("/util/searchOption", keys)
            .toPromise();
          return resolve(resp);
        } catch (err) {
          return reject(err);
        }
      }
    });
  }

  saveOption(obj: any) {
    for(let item of obj){
      if(item.pop_key == 'ecommerce_setting'){
        if(PAGE_OPTIONS.findIndex(x=>x.pop_key == 'price_setting') !== -1){
          PAGE_OPTIONS.splice(PAGE_OPTIONS.findIndex(x=>x.pop_key == 'price_setting'), 1)
        }
      }
      if(PAGE_OPTIONS.findIndex(x=>x.pop_key == item.pop_key) !== -1){
        PAGE_OPTIONS.splice(PAGE_OPTIONS.findIndex(x=>x.pop_key == item.pop_key), 1)
      }
    }
    return this._http.post("/util/saveOption", obj).toPromise();
  }

  clearTempPageOption(){
    PAGE_OPTIONS = []
  }

  listTheme() {
    return this._http.get("/util/listTheme").toPromise();
  }

  changeTheme(obj: any) {
    return this._http.post("/util/changeTheme", obj).toPromise();
  }
}
