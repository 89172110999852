import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { pathLangPipe } from '@cms/pipes/path-lang.pipe';
import { UtilService } from '@cms/services/util.service';

declare var fbq: any;

@Component({
  selector: 'app-modal-contact',
  templateUrl: './modal-contact.component.html',
    styleUrls: ['./modal-contact.component.css']
}) 
export class ModalContactComponent implements OnInit {
  @Input() data: any;
  page_name: string = 'product-detail';
  message_duration: number = 4000;
  constructor(
    private message: NzMessageService,
    private modal: NzModalRef,
    private router: Router,
    private path_lang: pathLangPipe,
    private Service_Util: UtilService
  ) { }
    
  model:any = {
    name: "",
    tel: "",
    email: "",
    message: ""
  }

  ngOnInit() {
    this.model.product_id = this.data.product.id;
    this.model.link = decodeURI(location.href);
  }

  ok(){
      if (this.model.name == "" || this.model.tel == "" || this.model.email == "") {
        this.message.error("โปรดกรอกข้อมูลให้ครบถ้วน",{nzDuration: this.message_duration});
        return false;
      }
      this.Service_Util.sendMailContactProduct(this.model).then((res: any) => {
          console.log(res);
        //   this.message.success("Success", { nzDuration: this.message_duration });
        try {
          fbq('trackCustom', 'Contact', this.model);
        } catch (err) { }
        this.modal.close(true);
      }).catch((data: any) => {
          console.log(data);
      });
    console.log(this.data.product);
    return false;
  }

}
