import { Component, OnInit,Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ConfirmPaymentService } from '@cms/services/confirmpayment.service';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-modal-addedit-bank-account',
  templateUrl: './modal-addedit-bank-account.component.html',
  styleUrls: ['./modal-addedit-bank-account.component.css']
})
export class ModalAddeditBankAccountComponent implements OnInit {

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_ConfirmPayMent: ConfirmPaymentService
  ) { }
  @Input() data:any;
  bankList:any = [];
  tmpbank:any = {};

  model:any = {
    code: null,
    logo: "",
    name: "",
    acnum: "",
    account: ""
  }
  message_duration:number = 4000;
  async ngOnInit() {
    this.bankList = await this.Service_ConfirmPayMent.bankList();
    if(this.data.state == 'edit'){
      this.model = cloneDeep(this.data.model);
      let _bank = this.bankList.find(x=>x.id == this.model.code);
      if (!_bank){
        this.tmpbank = this.bankList.find(x => x.id == 0);
      }else{
        this.tmpbank = _bank;
      }
      // console.log(this.model);
    }else{
      this.tmpbank = this.bankList[0];
      this.bankChange(this.tmpbank);
    }
    
  }

  bankChange(e){
    console.log(this.tmpbank);
    if(this.tmpbank.id != 0 ){
      this.model.code = this.tmpbank.id;
      this.model.name = this.tmpbank.name;
    }else{
      this.model.code = this.tmpbank.id;
      this.model.name = "";
    }
    console.log(this.model);
  }

  async ok(){
    if(this.model.name.trim() == ""){
      this.message.error("โปรดกรอกชื่อธนาคาร", { nzDuration: this.message_duration });
      return false;
    } 
     else if (this.model.account.trim() == ""){
      this.message.error("โปรดกรอกชื่อบัญชี", { nzDuration: this.message_duration });
      return false;
    } else if (this.model.acnum.trim() == "") {
      this.message.error("โปรดกรอกเลขบัญชี", { nzDuration: this.message_duration });
      return false;
    } 
    // } else if (this.model.code)

    try{
      if(this.data.state == 'create'){
        await this.Service_ConfirmPayMent.createBank(this.model);
        this.modal.close(true);
      }else{
        console.log(this.model);
        await this.Service_ConfirmPayMent.updateBank(this.model);
        this.modal.close(true);
      }
    } catch (e) {
      this.message.error(e.error.message, { nzDuration: 5000 });
    }

  }

}
