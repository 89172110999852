import { Directive, ElementRef, Input } from '@angular/core';
import * as jwt_decode from "jwt-decode";
import { extend as _extend } from 'lodash';

@Directive({
  selector: '[ket-permission]'
})
export class KetPermissionDirective{
  current_user: any;
  _option: any = {
      action: "delete"
  }
  constructor(
      private el?: ElementRef
  ) {
      try{
          let _token = JSON.parse(localStorage.getItem('access_token'));
          var user = jwt_decode(_token.access_token);
          this.current_user = user;

          // let user = JSON.parse(localStorage.getItem('testrole'));

          // if(!user){
          //     throw Error("a");
          // }
          // this.current_user = user;

      }catch(e){
          let _token = JSON.parse(localStorage.getItem('access_token'));
          var user = jwt_decode(_token.access_token);
          this.current_user = user;
      }
      // console.log('PermissionDirective CONSTRUCTOR');
  }
  @Input('ket-permission') permission: any;

  @Input('ket-permission-option') option: any;


  ngOnInit() {
      this._option = _extend({}, this._option, this.option);
      // // console.log(this.option);
      // console.log(this.permission);
      // console.log(this.current_user);
      if (!this.checkPermission(this.permission)){
          switch (this._option.action) {
              case 'delete':
                  this.el.nativeElement.remove();
                  break;
              case 'disabled':
                  this.el.nativeElement.disabled = true;
                  break;
              case 'disabled-pointerevent':
                  this.el.nativeElement.style = 'pointer-events: none;';
                  break;
              default:
                  this.el.nativeElement.remove();
                  break;
          }

      }
      // this.el.nativeElement.innerText = this.Service_Translate.translate(this.el.nativeElement.innerText, this.tmtTranslate);

  }

  checkPermission(permission:any[]){
    var check = permission.find(x => x == this.current_user.user_role)
    if(!check){
      return false
    }else{
      return true
    }
  }

}
