import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ProductsService } from "@cms/services/products.service";
import { cloneDeep } from 'lodash';
import { forEach } from '@angular/router/src/utils/collection';

interface ProductObject {
  id: number;
  feature_img: string;
  sku: string;
  title_lang1: string;
  property_info: string;
  property_info2: string;
}

@Component({
  selector: 'app-modal-addpropfrom-product',
  templateUrl: './modal-addpropfrom-product.component.html',
  styleUrls: ['./modal-addpropfrom-product.component.css']
})

export class ModalAddpropfromProductComponent implements OnInit {
  @Input() data: any;
  model: any = {
    lang: "lang1",
    status: "",
    search: "",
    cate_id: "",
    group: "",
    page: 1,
    perpage: 10,
    ids: "",
    orderby: "id",
    "sort": "desc"
  }
  product:any = [];
  message_duration = 2000;
  total:number = 0;

  selectedProducts: ProductObject[] = [];
  selectedAll: boolean = false;

  constructor(
    private modal: NzModalRef, 
    private modal_service: NzModalService, 
    private Service_Product: ProductsService, 
    private message: NzMessageService){ }

  ngOnInit() {
    // console.log(this.data)
    // console.log(this.data.allcategory);
    this.search();
  }

  search() {
    // this.loading = true;
    this.Service_Product.searchProduct(this.model).then((res: any) => {
      // console.log(res);
      // this.loading = false;
      this.product = res.data;
      this.total = res.count;
      this.checkDisble();
      this.checkDisableSelect();
      this.checkPreviousSelectAll();
    }).catch(data => {
      // this.loading = false;
      console.log(data.error.message);
    });
  }
  pagechage(page) {
    this.model.page = page;
    this.search();
  }
  
  // select(obj) {
  //   this.selectedproduct = obj;
  //   console.log(this.selectedproduct);
  //   console.log(this.product);
  // }

  ok(){
    if(this.selectedProducts.length === 0){
      this.message.error('โปรดเลือกสินค้า', { nzDuration: this.message_duration });
      return false;
    }
    if (this.data.currentproduct.prop_enable == 1){
      for (const i in this.selectedProducts) {
        if (this.checkProp1(this.selectedProducts[i])) continue;
        return false;
      }
      for (const i in this.selectedProducts) this.data.propertie_tmp.push(cloneDeep(this.selectedProducts[i]));
      this.modal.close();
      return false;
    } 
    if (this.data.currentproduct.prop_enable == 2){
      for (const i in this.selectedProducts) {
        if (this.checkProp2(this.selectedProducts[i])) continue;
        return false;
      }
      for (const i in this.selectedProducts) this.data.selectProp2.children.push(cloneDeep(this.selectedProducts[i]));
      
      this.modal.close();
      return false;
    }
    return false;
  }

  add(data){
    const chk = this.selectedProducts.find(x => x.id == data.id);
    if (!chk && !data.disable) {
      this.selectedProducts = [...this.selectedProducts, data];
      this.checkDisableSelect();
      this.checkPreviousSelectAll();
    }
  }

  remove(data: any) {
    this.selectedProducts = this.selectedProducts.filter(d => d.id !== data.id);
    this.checkDisableSelect();
    this.checkPreviousSelectAll();
  }

  selectAll(): void {
    if (this.selectedAll) {
      
      // check prop_enable
      for (let i in this.product){
        if(!this.product[i].disable){
          if(this.product[i].prop_enable == 1 || this.product[i].prop_enable == 2){
            const modal_service = this.modal_service.warning({
              nzTitle: 'อาจมีบางสินค้าต้องทำการย้ายคุณสมบัติสินค้า',
              nzContent: 'เนื่องจากมีบางสินค้า ถูกเพิ่มเป็นคุณสมบัติกับสินค้าอื่นแล้ว',
              nzOkText:'ตกลง',
            });
            break;
          }
        }
      }

      for (let i in this.product) {
        if (!this.product.selected) this.add(this.product[i]);
      }
    } else for (let i in this.product) this.remove(this.product[i]);
    return;
  }
  
  selectProducttoAdd(data){
    if(data.prop_enable !== 0){
      const modal_service = this.modal_service.warning({
        nzTitle: 'ย้ายคุณสมบัติสินค้า',
        nzContent: 'เนื่องจากสินค้านี้ถูกเพิ่มเป็นคุณสมบัติกับสินค้าอื่นแล้ว',
        nzOkText:'ตกลง',
      });      
    }
    this.add(data)
  }

  checkDisble() {
    if (this.data.currentproduct.prop_enable == 1) {
      for (const i in this.product) {
        const selected = this.data.propertie_tmp.find(x => x.id == this.product[i].id);
        selected ? this.product[i].disable = true : this.product[i].disable = false; 
      }
    } else if (this.data.currentproduct.prop_enable == 2) {
      for (const i in this.product) {
        const selected = (() => {
          console.log(this.data.propertie_tmp2)
          for (const j in this.data.propertie_tmp2) {
            const found = this.data.propertie_tmp2[j].children.find(x => x.id == this.product[i].id);
            if (found) return found;
          }
          return false;
        })();
        console.log(selected)
        selected ? this.product[i].disable = true : this.product[i].disable = false;
      }
    }
    return;
  }

  checkDisableSelect() {
    for (var i = 0; i < this.product.length; i++) {
      const check = this.selectedProducts.find(x => x.id == this.product[i].id);
      check ? this.product[i].selected = true : this.product[i].selected = false;
    }
  }

  checkPreviousSelectAll(): void {
    let count: number = 0;
    let countDisable: number = 0;
    for (let i in this.product) {
      count++;
      if (this.product[i].selected || this.product[i].disable) countDisable++;
    }
    this.selectedAll = count === countDisable ? true : false;
  }

  checkProp1(product: ProductObject) {
    console.log(product)
    if (!product.property_info || product.property_info.trim() == ""){
      this.message.error('โปรดกรอกชื่อคุณสมบัติ', { nzDuration: this.message_duration });
      return false;
    }
    let check = false;
    console.log(this.data.propertie_tmp)
    for (let i = 0; i < this.data.propertie_tmp.length;i++){
      if (this.data.propertie_tmp[i].id == this.product.id){
        check = true;
        this.message.error('สินค้านี้ได้ถูกเพิ่มลงในรายการคุณสมบัติแล้ว', { nzDuration: this.message_duration });
        break;
      }
    }
    if (!check) return true; 
    return false;
  }

  checkProp2(product: ProductObject) {
    if (!product.property_info2 || product.property_info2.trim() == ""){
      this.message.error('โปรดกรอกชื่อคุณสมบัติ', { nzDuration: this.message_duration });
      return false;
    }
    let check = false;
    for (let i = 0; i < this.data.propertie_tmp2.length; i++) {
      for (let j = 0; j < this.data.propertie_tmp2[i].children;j++){
        if (this.data.propertie_tmp2[i].children[j].id == product.id){
          check = true;
          this.message.error('สินค้านี้ได้ถูกเพิ่มลงในรายการคุณสมบัติแล้ว', { nzDuration: this.message_duration });
          break;
        }
      }
    }
    if (!check) return true;
    return false;
  }
}
