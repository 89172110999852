import { Component, OnInit, forwardRef } from '@angular/core';
import { KetSelectFontService } from './select-font.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { OnChangeType, OnTouchedType } from '@ketshopweb/ui/core/types/control-value-accessor';
@Component({
  selector: 'ket-select-font',
  templateUrl: './select-font.component.html',
  styleUrls: ['./select-font.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KetSelectFontComponent),
      multi: true
    }
  ]
})
export class KetSelectFontComponent implements OnInit, ControlValueAccessor {

  constructor(
    private service: KetSelectFontService
  ) { }

  onChange: OnChangeType = () => {};
  onTouched: OnTouchedType = () => {};
  value: string = '';
  fonts:any[] = []

  ngOnInit(): void {
    this.service.getFonts().then((res:any) =>{
      this.fonts = res.data;
    });
  }

  registerOnChange(fn: OnChangeType): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchedType): void {
    this.onTouched = fn;
  }

  writeValue(value:string){
    this.value = value || '';
  }

  onModelChange(){
    this.onChange(this.value);
  }

}