import { Component, OnInit,Input } from '@angular/core';
import { ProductsService } from '@cms/services/products.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { find,cloneDeep } from 'lodash';
import { NzNotificationService } from 'ng-zorro-antd/notification';
declare var alasql;
@Component({
  selector: 'app-modal-addproduct',
  templateUrl: './modal-addproduct.component.html',
  styleUrls: ['./modal-addproduct.component.css']
})
export class ModalAddproductComponent implements OnInit {
  @Input() data: any;
  constructor(
    private Service_Product: ProductsService, 
    private noti: NzNotificationService,
    private modal: NzModalRef
  ) { }
  model: any = {
    lang: "lang1",
    status: "",
    search: "",
    cate_id: "",
    group: "",
    page: 1,
    perpage: 25,
    ids: "",
    orderby: "buy",
    include_barcode: 1,
    "sort": "desc"
  }
  product:any = [];
  total:any = 0;
  selecteditem:any =[];
  allcategory:any=[];
  limitProduct: number = 0;
  isSelectQty: boolean = true;
  addSku:boolean = false;
  _sortBy: any[] = [
    { id: "3", name: "สินค้าล่าสุด" },
    { id: "2", name: "สินค้าเก่าสุด" },
    { id: "0", name: "สินค้าจาก ก - ฮ" },
    { id: "1", name: "สินค้าจาก ฮ - ก" },
    { id: "4", name: "ราคา ต่ำ - สูง" },
    { id: "5", name: "ราคา สูง - ต่ำ" },
    { id: "buy", name: "สินค้าขายดี" },
    { id: "7", name: "สินค้าแนะนำ" }
  ];
  async ngOnInit() {
    if (localStorage.getItem('orderby')) {
      this.model.orderby = localStorage.getItem('orderby')
    }
    console.log(this.data.details);  
    // alert(1);
    this.allcategory = await this.Service_Product.productMainCategory();
    this.allcategory.unshift({ id: "-1", lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory" });
    this.allcategory.unshift({ id: "", lang1: "All", lang2: "All", lang3: "All", lang4: "All" });
    
    this.search();
    this.selecteditem = cloneDeep(this.data.details);
    this.limitProduct = this.data.hasOwnProperty('limitProduct') ? this.data.limitProduct : 0;
    this.isSelectQty = this.data.hasOwnProperty('isSelectQty') ? this.data.isSelectQty : true;
    this.addSku = this.data.hasOwnProperty('addSku') ? this.data.addSku : false;
  }
  pagechage(page) { 
    this.model.page = page;
    this.search();
  }
  deleteSelected(i){
    this.selecteditem.splice(i,1);
    this.calculateMaxStock();
  }

  calculateMaxStock(){
    // maxstock
   let product_id_arr = alasql(`SELECT product_id FROM ? GROUP BY product_id`, [this.selecteditem]);

    for (let obj of product_id_arr){
      // let product_in_group = alasql(`SELECT * FROM ? where product_id = ?`, [this.selecteditem,obj.product_id]);
      let product_in_group = this.selecteditem.filter(x=>x.product_id == obj.product_id);
      console.log(product_in_group);
      for (let bar of product_in_group){
        let product_in_store = alasql(`SELECT SUM(unit_value * qty) as sum_stock FROM ? WHERE product_id = ? AND barcode <> ?`, [this.selecteditem, bar.product_id,bar.barcode]);
        let store_stock = product_in_store[0].sum_stock;
        let instock = bar.instock;
        let diff = instock - store_stock;
        let max = Math.floor(diff / bar.unit_value);
        bar.maxstock = max;
        console.log(bar.barcode + ' max:'+max);
      }
    }
  }

  qtyChange(){
    this.calculateMaxStock();
  }

  addProductSKU(data){
    let chk = find(this.selecteditem, function (o) { return o.id == data.id; });
    if (!chk) {
      data.qty = 1;
      if (!this.limitProduct) {
        this.selecteditem.push(data);
      } else {
        if (this.limitProduct === this.selecteditem.length) return;
        this.selecteditem.push(data);
      }
    } else {
      chk.qty++;
    }
  }

  addProduct(data, selected_barcode){
    console.log(data);
    var model = {
      product_id: data.id,
      price: selected_barcode.price,
      product_price: selected_barcode.price,
      barcode: selected_barcode.barcode,
      barcode_name: selected_barcode.name,
      unit_name: selected_barcode.unit_name,
      unit_value: selected_barcode.unit_value,
      feature_img: data.feature_img,
      barcode_img: selected_barcode.image,
      title_lang1: data.title_lang1,
      title_lang2: data.title_lang2,
      title_lang3: data.title_lang3,
      title_lang4: data.title_lang4,
      permalink_lang1: data.permalink_lang1,
      permalink_lang2: data.permalink_lang2,
      permalink_lang3: data.permalink_lang3,
      permalink_lang4: data.permalink_lang4,
      prop_enable: data.prop_enable,
      property_info: data.property_info,
      property_info2: data.property_info2,
      properties_desc: data.properties_desc,
      properties_desc2: data.properties_desc2,
      sku: data.sku,
      weight: selected_barcode.weight,
      qty: 1,
      instock: data.instock,
      size_height: selected_barcode.size_height || 0,
      size_length: selected_barcode.size_length || 0,
      size_width: selected_barcode.size_width || 0
    }
    
    let product_in_store = alasql(`SELECT SUM(unit_value * qty) as sum_stock FROM ? WHERE product_id = ?`, [this.selecteditem, model.product_id]);
    let instock = data.instock;
    let request_stock = model.qty * Number(model.unit_value);
    let store_stock = product_in_store[0].sum_stock;
    if (instock < (request_stock + store_stock)){
      this.noti.create(
        'error',
        'จำนวนสินค้าไม่เพียงพอ',
        ''
        // 'This is the content of the notification. This is the content of the notification. This is the content of the notification.'
      );
      return;
    }
    console.log(product_in_store[0].sum_stock);

    let chk = find(this.selecteditem, function (o) { return o.product_id == model.product_id && o.barcode == model.barcode; });
    if(!chk){
      model.qty = 1;
      if (!this.limitProduct) {
        this.selecteditem.push(model);
      } else {
        if (this.limitProduct === this.selecteditem.length) return;
        this.selecteditem.push(model);
      }     
    }else{
      chk.qty++;
    }

    this.calculateMaxStock();

  }
  search(){
    this.Service_Product.searchProduct(this.model).then((res: any) => {
      console.log(res);
      localStorage.setItem('orderby', this.model.orderby);
      this.product = res.data;
      this.total = res.count;
    }).catch(data => {
      console.log(data.error.message);
    });
  }
  ok(){
    this.modal.close(this.selecteditem);
  }

}
