import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '@cms/services/products.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { UtilService } from '@cms/services/util.service';
import { AuthenService } from '@cms/services/authen.service';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-modal-print-qr-product',
    templateUrl: './modal-print-qr-product.component.html',
    styleUrls: ['./modal-print-qr-product.component.css']
})
export class ModalPrintQRComponent implements OnInit {
    @Input() data: any;
    constructor(
        private Service_Product: ProductsService,
        private modal: NzModalRef,
        private Service_Util: UtilService,
        private _authen: AuthenService
    ) { }
    model: any = {
        lang: "lang1",
        status: "",
        search: "",
        cate_id: "",
        group: "",
        page: 1,
        perpage: 10,
        include_barcode: true,
        ids: "",
        orderby: "id",
        "sort": "desc",
        havebarcode: true
    }
    product: any = [];
    total: any = 0;
    selecteditem: any = [];
    allcategory: any = [];
    access_token: string;
    api_path: string;
    print_size: string;

    async ngOnInit() {
        this.api_path = environment.api_path;
        this.access_token = this._authen.getToken().access_token;
        this.allcategory = await this.Service_Product.productMainCategory();
        this.allcategory.unshift({ id: "-1", lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory" });
        this.allcategory.unshift({ id: "", lang1: "All", lang2: "All", lang3: "All", lang4: "All" });
        if (this.data.type == 'qr') {
            this.model.havebarcode = false;
        }
        this.print_size = this.data.size;
        this.search();
    }
    pagechage(page) {
        this.model.page = page;
        this.search();
    }
    deleteSelected(i) {
        this.selecteditem.splice(i, 1);
    }
    // addProduct(data) {

    //     let chk = find(this.selecteditem, function (o) { return o.id == data.id; });
    //     if (!chk) {
    //         data.qty = 1;
    //         this.selecteditem.push(data);
    //     } else {
    //         chk.qty++;
    //     }

    // }
    addProduct(obj) {
        this.selecteditem.push(obj);
    }


    checkItem(item) {
        return !this.selecteditem.find(el => el.product_id == item.product_id && el.barcode == item.barcode);
    }
    search() {
        this.Service_Product.searchProduct(this.model).then((res: any) => {
            console.log(res);
            this.product = res.data;
            this.total = res.count;
        }).catch(data => {
            console.log(data.error.message);
        });
    }
    ok() {

        console.log(this.data.type);
        let param = {
            access_token: this.access_token,
            _array: []
        };
        this.selecteditem.forEach(x => param._array.push({ key: 'barcode', value: x.barcode }));
        param._array.push({ key: 'type', value: 'multiple' })
        param._array.push({ key: 'print_size', value: this.print_size })
        console.log(param);
        if (this.data.type == 'qr') {
            this.openWindowWithPost(`${environment.api_path}/product/printQRWebsite`, "_blank", param);
            // $("form#print-qr-product").submit();
        } else if (this.data.type == 'barcode') {
            this.openWindowWithPost(`${environment.api_path}/product/printBarcodeWebsite`, "_blank", param);
            // $("form#print-barcode-product").submit();
        }
        // this.modal.close(this.selecteditem);

        // console.log(this.selecteditem);
        return true;
    }

    openWindowWithPost(url: string, name: string, params: any) {
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);
        form.setAttribute("target", name);
        for (var i in params) {
            if (params.hasOwnProperty(i)) {
                if (i == "_array") {
                    for (let _data of params['_array']) {
                        var input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = _data.key;
                        input.value = _data.value;
                        form.appendChild(input);
                    }
                } else {
                    var input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = i;
                    input.value = params[i];
                    form.appendChild(input);
                }
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

}
