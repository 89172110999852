import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KetModalAddEditSendingAddressComponent } from './modal-add-edit-sending-address.component';
import { KetTranslateModule } from '../translate';
import { FormsModule } from '@angular/forms';
import { KetTrimModule } from '../trim';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzFormModule } from 'ng-zorro-antd/form';
import { KetThailandAddressModule } from '../thailand-address';

@NgModule({
  declarations: [
    KetModalAddEditSendingAddressComponent
  ],
  entryComponents: [KetModalAddEditSendingAddressComponent],
  imports: [
    CommonModule,
    KetTranslateModule,
    FormsModule,
    KetTrimModule,
    NzInputModule,
    NzButtonModule,
    NzIconModule,
    NzSelectModule,
    NzInputNumberModule,
    NzFormModule,
    KetThailandAddressModule
  ],
  exports:[
    KetModalAddEditSendingAddressComponent
  ]
})
export class KetModalAddEditSendingAddressModule { }