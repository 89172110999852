export default {
  today: 'Danes',
  now: 'Zdaj',
  backToToday: 'Nazaj na danes',
  ok: 'V redu',
  clear: 'Počisti',
  month: 'Mesec',
  year: 'Leto',
  timeSelect: 'Izberi čas',
  dateSelect: 'Izberi datum',
  monthSelect: 'Izberi mesec',
  yearSelect: 'Izberi leto',
  decadeSelect: 'Izberi desetletje',
  yearFormat: 'YYYY',
  dateFormat: 'DD.MM.YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Predhodnji mesec (PageUp)',
  nextMonth: 'Naslednji mesec (PageDown)',
  previousYear: 'Prejšnje leto (Control + left)',
  nextYear: 'Naslednje leto (Control + right)',
  previousDecade: 'Prejšnje desetletje',
  nextDecade: 'Naslednje desetletje',
  previousCentury: 'Prejšnje stoletje',
  nextCentury: 'Naslednje stoletje',
};
