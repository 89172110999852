import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RenderStyleComponent } from './reder-style';
import { KetHasDataDomModule } from '@ketshopweb/ui/has-data-dom';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule } from '@angular/forms';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [
    RenderStyleComponent
  ],
  imports: [
    CommonModule,
    KetHasDataDomModule,
    NzGridModule,
    NzDrawerModule,
    NzTabsModule,
    NzInputModule,
    ColorPickerModule,
    FormsModule,
    NzInputNumberModule,
    NzSwitchModule,
    NzButtonModule
  ],
  exports: [
    RenderStyleComponent
  ]
})
export class RederStyleModule { }
