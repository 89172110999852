import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'dateFormat'
})
export class dateFormatPipe implements PipeTransform {

  constructor() {
  }

  transform(v: string) {
    var day: any = new Date(v).getDay();
    day = this.addZero(day);
    var month: any = new Date(v).getMonth();
    month = this.addZero(month);
    var FullYear = new Date(v).getFullYear();

    //console.log(day, month, FullYear);

    return `${day} ${this.monthString(month)} ${FullYear}`;
  }

  addZero(n: any) {
    if (n < 10) {
      n = `0${n}`;
    } else {
      n = `${n}`;
    }

    return n;
  }

  monthString(key: any) {
    var M = {
      "TH": {
        "01": "มกราคม",
        "02": "กุมภาพันธ์",
        "03": "มีนาคม",
        "04": "เมษายน",
        "05": "พฤษภาคม",
        "06": "มิถุนายน",
        "07": "กรกฎาคม",
        "08": "สิงหาคม",
        "09": "กันยายน",
        "10": "ตุลาคม",
        "11": "พฤศจิกายน",
        "12": "ธันวาคม"
      },
      "EN":{
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        "10": "October",
        "11": "November",
        "12": "December"
      }
    }

    return M["EN"][key];

  }

}
