import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UtilService } from '@cms/services/util.service';
import { CustomerService } from '@cms/services/customer.service';

@Component({
    selector: 'app-modal-orderlink-shipping-address',
    templateUrl: './modal-orderlink-shipping-address.component.html',
    styleUrls: ['./modal-orderlink-shipping-address.component.css']
})
export class ModalOrderLinkShippingAddress implements OnInit {
    @Input() data: any;
    step:number = 1;
    keyword:string = "";
    customer:any = [];
    message_duration:number = 4000;
    modelsearch: any = {
        "sort": "desc",
        "status": "",
        "search": '',
        "page": 1,
        "type": "guest",
        "perpage": 15
    }
    province:any = [];
    selectedcustomer = {
        id: false,
        name: "",
        fullname: "",
        fulladdress: "",
        lastname: "",
        address1: "",
        subdistrict: "",
        district: "",
        province: "",
        zipcode: "",
        tel: "",
        username: ""
    }

    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private Service_Util: UtilService,
        private Service_Customer: CustomerService
    ) { }

    async ngOnInit() {
        if(this.data.state == "edit"){
            this.selectCustomer(this.data.selectedcustomer);
        }
        this.Service_Util.getProvince().then((res: any[]) => {
            this.province = res
        });
        this.onSearch();
    }

    onSearch(){
        this.modelsearch.search = this.keyword;

        this.Service_Customer.search(this.modelsearch).then((res: any) => {
            console.log(res)
            this.customer = res.data
        }).catch((e) => {
            console.log(e.error.message)
        })

    }

    clearSelectCustomer(){
        this.keyword = "";
        this.selectedcustomer = {
            id: false,
            name: "",
            fullname: "",
            fulladdress: "",
            lastname: "",
            address1: "",
            subdistrict: "",
            district: "",
            province: "",
            zipcode: "",
            tel: "",
            username: ""
        }
    }

    selectCustomer(obj:any){
        this.selectedcustomer = obj;
        this.selectedcustomer.fullname = obj.name + " " + obj.lastname;
        this.selectedcustomer.fulladdress = obj.address1 + " " + obj.subdistrict + " " + obj.district + " "+ obj.province + " "+ obj.zipcode;
        try{
          if(obj.username == "-"){
            this.selectedcustomer.username = ""
          }
        }catch(err){}
        this.keyword = this.selectedcustomer.fullname;
    }

    next(){

        this.Service_Util.filterShippingAddress({ address: this.selectedcustomer.fulladdress }).then((data:any)=>{
            console.log(data);
            data = data.data;
            this.step = 2;
            this.modal.getContentComponent().modal.nzTitle = "ตรวจสอบที่อยู่จัดส่ง";
            this.selectedcustomer.address1 = data.address1;
            this.selectedcustomer.subdistrict = data.subdistrict;
            this.selectedcustomer.district = data.district;
            this.selectedcustomer.province = data.province;

        }).catch((data)=>{
            this.message.error(data.error.message, { nzDuration: this.message_duration });
        });
    }

    back(){
        this.step = 1;
        this.modal.getContentComponent().modal.nzTitle = "ที่อยู่จัดส่ง";
        // this.selectedcustomer.lastname = "";
        this.selectedcustomer.address1 = "";
        this.selectedcustomer.subdistrict = "";
        this.selectedcustomer.district = "";
        this.selectedcustomer.province = "";
        // this.selectedcustomer.zipcode = "";
        // this.selectedcustomer.tel = "";
    }

    async ok() {
        // this.modal.ti
        if (this.selectedcustomer.fullname == ""){
            this.message.error("โปรดกรอก ชื่อ - นามสกุล", { nzDuration: this.message_duration });
            return false;
        } else if (this.selectedcustomer.tel == "") {
            this.message.error("โปรดกรอก เบอร์โทรศัพท์", { nzDuration: this.message_duration });
             return false;
        } else if (this.selectedcustomer.address1 == "") {
            this.message.error("โปรดกรอก ที่อยู่", { nzDuration: this.message_duration });
             return false;
        } else if (this.selectedcustomer.subdistrict == "") {
            this.message.error("โปรดกรอก ตำบล/แขวง", { nzDuration: this.message_duration });
             return false;
        } else if (this.selectedcustomer.district == "") {
            this.message.error("โปรดกรอก อำเภอ/เขต", { nzDuration: this.message_duration });
             return false;
        } else if (this.selectedcustomer.province == "") {
            this.message.error("โปรดกรอก จังหวัด", { nzDuration: this.message_duration });
             return false;
        } else if (this.selectedcustomer.zipcode == "") {
            this.message.error("โปรดกรอก รหัสไปรษณีย์", { nzDuration: this.message_duration });
             return false;
        }

        let arr_fullname = this.selectedcustomer.fullname.trim().split(' ').filter(word => word.length > 0);
        if (arr_fullname.length == 1){
            this.selectedcustomer.name = arr_fullname[0];
            this.selectedcustomer.lastname = "-";
        } else if (arr_fullname.length > 1){
            this.selectedcustomer.name = arr_fullname[0];
            this.selectedcustomer.lastname = arr_fullname[1];
        }

        this.selectedcustomer.tel = this.selectedcustomer.tel.replace(/\D+/g, "");

        // console.log(this.selectedcustomer);
        this.modal.close(this.selectedcustomer);
        // console.log(this.modal.getContentComponent().modal.nzTitle);
        return false;
    }
}
