import { Injectable } from '@angular/core';
import { Etc } from '../util/etc';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private _etc:Etc,
    private _http:HttpClient,
    private _cookie:CookieService
    ) { }

  getLogs(path){
    let date = +new Date();
    return $.ajax({
      url: `${path}?d=${date}`,
      type: 'GET'
    });
    // return this._http.get(`${path}`).toPromise();
  }

  getAllLogs() {
    return this._http.get('/report/goaccessLog').toPromise();
  }

  warehouseSummary(data: any) {
    return this._http.post('/report/warehouseSummaryStatus', data).toPromise();
  }
  productDeadStock(data: any) {
    return this._http.post('/report/productDeadStock', data).toPromise();
  }
  productLowActivity(data: any) {
    return this._http.post('/report/productLowActivity', data).toPromise();
  }
  productHighActivity(data: any) {
    return this._http.post('/report/productHighActivity', data).toPromise();
  }
  orderReportSold(data: any) {
    return this._http.post('/report/orderReportSold', data).toPromise();
  }
  orderReportSummary(data: any) {
    return this._http.post('/report/orderReportSummary', data).toPromise();
  }
  topBestSeller(data: any) {
    return this._http.post('/report/topBestSeller', data).toPromise();
  }
  topBestSellerByCate (data: any) {
    return this._http.post('/report/topBestSellerByCate', data).toPromise();
  }
  orderChannel(data: any) {
    return this._http.post('/report/orderChannel', data).toPromise();
  }
  orderChannelValue(data: any){
    return this._http.post('/report/orderChannelValue', data).toPromise();
  }
  orderChannelByStatus(data: any) {
    return this._http.post('/report/orderChannelByStatus', data).toPromise();
  }
  orderReportBySale(data:any){
    return this._http.post('/report/orderReportBySale', data).toPromise();
  }
  orderBySale(data:any){
    return this._http.post('/report/orderBySale', data).toPromise();
  }
  topOrderProvince(data: any) {
    return this._http.post('/report/topOrderProvince', data).toPromise();
  }
  topProductPending(data: any) {
    return this._http.post('/report/topProductPending', data).toPromise();
  }
  saleReport(data:any){
    return this._http.post('/report/saleReport', data).toPromise();
  }
  saleReportProvince(data: any) {
    return this._http.post('/report/saleReportProvince', data).toPromise();
  }
  customerReportSummary(data:any){
    return this._http.post('/report/customerReportSummary', data).toPromise();
  }
  customerByChannel(data:any){
    return this._http.post('/report/customerByChannel', data).toPromise();
  }
  newCustomerReport(data:any){
    return this._http.post('/report/newCustomerReport', data).toPromise();
  }
  newCustomerChannel(data:any){
    return this._http.post('/report/newCustomerChannel', data).toPromise();
  }
  customerBuyAgainReport(data:any){
    return this._http.post('/report/customerBuyAgainReport', data).toPromise();
  }
  customerBuyAgainChannel(data:any){
    return this._http.post('/report/customerBuyAgainChannel', data).toPromise();
  }
  customerTopSpenderValue(data: any) {
    return this._http.post('/report/customerTopSpenderValue', data).toPromise();
  }
  customerTopSpenderQty(data: any){
    return this._http.post('/report/customerTopSpenderQty', data).toPromise();
  }
  customerProvince(data: any) {
    return this._http.post('/report/customerProvince', data).toPromise();
  }

  widgetAnalytic(data: any) {
    return this._http.post('/report/exportWidgetAnalytic', data).toPromise();
  }
}
