import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ProductsService } from '@cms/services/products.service';

@Component({
  selector: 'app-modal-product-in-category',
  templateUrl: './modal-product-in-category.component.html',
  styleUrls: ['./modal-product-in-category.component.css']
})
export class ModalProductInCategoryComponent implements OnInit {

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Product: ProductsService
  ) { }
  @Input() data: any;
  currenttab:number = 0;
  model: any = {
    lang: "lang1",
    status: "",
    search: "",
    cate_id: "",
    group: "",
    page: 1,
    perpage: 10,
    ids: "",
    orderby: "id",
    "sort": "desc"
  }

  model_add:any = {
    lang: "lang1",
    status: "",
    search: "",
    cate_id: "",
    group: "",
    page: 1,
    perpage: 10,
    ids: "",
    orderby: "id",
    "sort": "desc"
  }
  product_add:any = [];
  total_add:any = 0;
  selecteditem_add:any = [];

  product: any = [];
  total: any = 0;

  model_delete: any = {
    lang: "lang1",
    status: "",
    search: "",
    cate_id_nosub: "",
    group: "",
    page: 1,
    perpage: 10,
    ids: "",
    orderby: "id",
    "sort": "desc"
  }
  product_delete: any = [];
  total_delete: any = 0;
  selecteditem_delete: any = [];


  ngOnInit() {
    this.model.cate_id_nosub = this.data.id;
    this.model_delete.cate_id_nosub = this.data.id;
    this.model_add.cate_not_in = this.data.id;
    this.search();
    this.search_add();
    this.search_delete();
  }
  pagechage(page){
    this.model.page = page;
    this.search();
  }// tab1

  search(){
    this.Service_Product.searchProduct(this.model).then((res: any) => {
      console.log(res);
      this.product = res.data;
      this.total = res.count;
    }).catch(data => {
      console.log(data.error.message);
    });
  } // tab1


  pagechage_add(page) {
    this.model_add.page = page;
    this.search_add();
  }// tab2

  search_add() {
    this.Service_Product.searchProduct(this.model_add).then((res: any) => {
      console.log(res);
      this.product_add = res.data;
      this.total_add = res.count;
    }).catch(data => {
      console.log(data.error.message);
    });
  } // tab2

  addProductToCate(obj){
    this.selecteditem_add.push(obj);
  }
  deleteSelectedAdd(i){
    this.selecteditem_add.splice(i,1);
  }

  checkItemAdd(item){
    return !this.selecteditem_add.find(el => el.id == item.id);
  }
  ///////
  pagechage_delete(page) {
    this.model_delete.page = page;
    this.search_delete();
  }// tab2

  search_delete() {
    this.Service_Product.searchProduct(this.model_delete).then((res: any) => {
      console.log(res);
      this.product_delete = res.data;
      this.total_delete = res.count;
    }).catch(data => {
      console.log(data.error.message);
    });
  } // tab2

  addProductRemoveFromCate(obj) {
    this.selecteditem_delete.push(obj);
  }

  deleteSelectedRemove(i) {
    this.selecteditem_delete.splice(i, 1);
  }

  checkItemDelete(item) {
    return !this.selecteditem_delete.find(el => el.id == item.id);
  }


  ////////
  tabchange(e) {
    this.currenttab = e.index;
  }

  async ok(){
    console.log(this.selecteditem_add);
    console.log(this.selecteditem_delete);
    let _add = this.selecteditem_add.map((data)=>{ return data.id });
    let _remove = this.selecteditem_delete.map((data) => { return data.id });
 
    if (this.selecteditem_add.length == 0 && this.selecteditem_delete.length == 0 ){
      this.modal.close();
      return;
    }
    let rs = {
      cate_id: this.data.id,
      add: _add,
      remove: _remove
    }
    try{
      await this.Service_Product.updateProductToCategoryMultiple(rs);
      this.modal.close(true);
    } catch(e) {
      console.log(e);
      this.message.error(e.error.message, { nzDuration: 5000 });
    } 
    
    return false;
  }

}
