import { Component, OnInit,Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CustomerService } from '@cms/services/customer.service';
import { SettingService } from '@cms/services/setting.service';
@Component({
    selector: 'app-modal-event-popup',
    templateUrl: './modal-event-popup.component.html',
    styleUrls: ['./modal-event-popup.component.css']
})
export class ModalEventPopupComponent implements OnInit {
  @Input() data:any;
//   page_name: string = "member-profile";
  constructor(
    private message: NzMessageService,
    private modal: NzModalRef,
    private Service_Setting: SettingService
  ) { 
      
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Opera Mobile|Kindle|Windows Phone|PSP|AvantGo|Atomic Web Browser|Blazer|Chrome Mobile|Dolphin|Dolfin|Doris|GO Browser|Jasmine|MicroB|Mobile Firefox|Mobile Safari|Mobile Silk|Motorola Internet Browser|NetFront|NineSky|Nokia Web Browser|Obigo|Openwave Mobile Browser|Palm Pre web browser|Polaris|PS Vita browser|Puffin|QQbrowser|SEMC Browser|Skyfire|Tear|TeaShark|UC Browser|uZard Web|wOSBrowser|Yandex.Browser mobile/i.test(navigator.userAgent)) {
          this.isMobile = true;
      }

  }

  message_duration:number = 4000;
  isMobile:boolean = false;
  model:any={
    
  }
  _option:any={}
  async ngOnInit() {
    let pop_key = ['event_popup_mobile','event_popup_desktop'];
    var option:any = []
    try{
        option = await this.Service_Setting.searchOption({ pop_key: pop_key });
        for (let obj of option) {
            this._option[obj.pop_key] = obj;
        }

    }catch(err){}

  }

  async ok(){
    return true;
  }

}